import { Typography } from "antd";
import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <div className="section is-medium has-background-black">
      <div className="container">
        <div className="columns">
          <div className="column is-4">
            <Typography>
              <Typography.Title level={5} className="has-text-white">
                What we do
              </Typography.Title>
              <Typography.Paragraph className="has-text-grey-light">
                We're all about good haircuts, every time, at every location.
                Count on our team's expertise to deliver the style you're
                looking for, tailored to the unique vibe of each neighborhood
                and clientele.
              </Typography.Paragraph>
              <Typography.Paragraph className="has-text-grey-light">
                © 2024 Eddie's Edition -{" "}
                <Link className="has-text-grey-light" to={"/terms-and-privacy"}>
                  <small>Privacy & Terms</small>
                </Link>
              </Typography.Paragraph>
            </Typography>
          </div>
          <div className="column is-2" />
          <div className="column is-2">
            <Typography.Title level={5} className="has-text-white">
              Locations
            </Typography.Title>
            <Typography.Paragraph>
              <Link to="/appointment/dtla" className="has-text-grey-light">
                Arts District
              </Link>
              <br />
              <Link
                to="/appointment/mid-wilshire"
                className="has-text-grey-light"
              >
                Mid Wilshire
              </Link>
              <br />
              <Link to="/appointment/pasadena" className="has-text-grey-light">
                Pasadena
              </Link>
              <br />
              <Link
                to="/appointment/san-gabriel"
                className="has-text-grey-light"
              >
                San Gabriel
              </Link>
              <br />
            </Typography.Paragraph>
          </div>
          <div className="column is-2">
            <Typography.Title level={5} className="has-text-white">
              Sitemap
            </Typography.Title>
            <Typography.Paragraph>
              <Link to="/stylists" className="has-text-grey-light">
                Stylists
              </Link>
              <br />
              <Link to="/shop" className="has-text-grey-light">
                Shop
              </Link>
              <br />
              <Link to="/blog" className="has-text-grey-light">
                Blog
              </Link>
              <br />
              <Link to="/contact" className="has-text-grey-light">
                Contact
              </Link>
              <br />
              <Typography.Link
                className="has-text-grey-light"
                href={"https://www.instagram.com/eddies_edition"}
                target="_blank"
                rel="noreferrer"
              >
                Instagram
              </Typography.Link>
              <br />
            </Typography.Paragraph>
          </div>
          <div className="column is-2">
            <Typography.Title level={5} className="has-text-white">
              Business
            </Typography.Title>
            <Typography.Paragraph>
              <Link to="/about-us" className="has-text-grey-light">
                About us
              </Link>
              <br />
              <Link to="/careers" className="has-text-grey-light">
                We're Hiring
              </Link>
              <br />
              <Typography.Link
                className="has-text-grey-light"
                href={"https://biz.eddiesedition.com"}
                target="_blank"
                rel="noreferrer"
              >
                For Stylists
              </Typography.Link>
              <br />
              <Link to="/hair-model-wanted" className="has-text-grey-light">
                Hair Model Wanted
              </Link>
            </Typography.Paragraph>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
