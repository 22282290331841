import React from "react";
import AppContentWrapper from "../../components/AppContentWrapper";
import { Helmet } from "react-helmet";
import { Typography } from "antd";
const AboutUs = () => {
  return (
    <AppContentWrapper>
      <Helmet>
        <title>About us | Eddie's Edition</title>
        <meta name="description" />
      </Helmet>

      <Typography.Title level={3}>
        About us
      </Typography.Title>
      <Typography.Paragraph>
        At Eddie's Edition, we bridge the gap between the artistic flair of a
        hair salon and the meticulous technique of a barbershop. Our mission is
        to create a unique space that merges the best of both worlds: the
        creative vibes of a salon with the sharp, precise techniques of a
        barbershop.
        <br />
        <br />
        <Typography.Text strong>Our Team: A Blend of Expertise</Typography.Text>
        <br />
        <br />
        We proudly boast a diverse team of experienced barbers from traditional
        barbershops and creative hair stylists from top-tier salons. This fusion
        allows our staff to learn from each other, respecting their varied
        backgrounds while enhancing their skills. This collaborative environment
        not only fosters professional growth but also ensures that our
        apprentices – the rookies of hairstyling – are trained in these hybrid
        techniques, shaping the future of hair cutting with a blend of artistry
        and precision.
        <br />
        <br />
        <Typography.Text strong>
          Innovation in Service: The Eddie's Edition Scheduler
        </Typography.Text>
        <br />
        <br />
        Our commitment to excellence extends to our scheduling system, designed
        for simplicity and ease. We understand the value of your time, which is
        why our scheduler is intuitive, making booking and repeating
        appointments hassle-free. Our dedication to timely service underscores
        our belief that outstanding haircuts are the cornerstone of our business
        – a principle our customers deeply appreciate.
        <br />
        <br />
        <Typography.Text strong>A Fair and Flexible</Typography.Text>
        <br />
        <br />
        Workplace At the heart of Eddie's Edition is our belief in fairness and
        flexibility for our stylists and barbers. We offer adaptable working
        hours and fair compensation, fostering an environment where quality and
        creativity thrive. This approach not only benefits our team but also
        elevates the experience for our customers, ensuring they receive the
        best service from happy, motivated professionals.
        <br />
        <br />
        Welcome to Eddie's Edition – where every haircut is a masterpiece in the
        making.
      </Typography.Paragraph>
    </AppContentWrapper>
  );
};

export default AboutUs;
