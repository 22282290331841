import React from "react";
import { MetaTagWrapper } from "../../components/MetaTags";
import Banner from "./components/banner";
import Main from "./components/main";
import Footer from "./components/footer";

const Landing = () => {
  return (
    <>
      <MetaTagWrapper
        title="Good Haircuts Only | Eddie's Edition"
        description="We're all about good haircuts, every time, at every location. Count on our team's expertise to deliver the style you're looking for."
        type="website"
        url="https://www.eddiesedition.com"
        image="https://eddiesedition.s3-us-west-2.amazonaws.com/images/logos/logo.png"
      />
      <div id="Landing" className="content-wrapper">
        <Banner />
        <Main />
        <div className="columns is-gapless is-marginless">
          <div className="column">
            <div
              className="col-content"
              style={{
                backgroundImage:
                  "url(https://imagedelivery.net/ZrdAKG8zF6UYv_PggC8zbw/f07b00f3-b1fa-411f-f61e-1c50792c1600/public)",
              }}
            ></div>
          </div>
          <div className="column">
            <div
              className="col-content"
              style={{
                backgroundImage:
                  "url(https://imagedelivery.net/ZrdAKG8zF6UYv_PggC8zbw/9c028779-7008-41ee-51ff-7d7f6d2f8100/public)",
              }}
            ></div>
          </div>
          <div className="column">
            <div
              className="col-content"
              style={{
                backgroundImage:
                  "url(https://imagedelivery.net/ZrdAKG8zF6UYv_PggC8zbw/d556743d-5be9-43e6-b689-b1e7a1e7b800/public)",
              }}
            ></div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default Landing;
