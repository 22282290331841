import {
  IAppointmentHistoryJson,
  IAppointmentReviewJson,
  ICreateAppointmentArgs,
  ILocationJsonResult,
  ILocationTimeSlotsJson,
  IRescheduleAppointmentArgs,
  IUserJson,
  ILocationModel,
  IPhotosModel,
  IUserProfile,
  ICancelAppointmentArgs,
  IUpcomingAppointmentsJson,
  IServiceRequestArgs,
  IMessageArgs,
  IServiceModel,
  ICreatePaymentMethodArgs,
} from "./types";
import { DEFAULT_LOCATIONS, DEFAULT_STYLISTS } from "./consts";
import { PaymentMethod } from "@stripe/stripe-js";

export class EditionJson {
  static ENDPOINT =
    process.env.NODE_ENV === "development"
      ? "http://localhost:3000/dev"
      : "https://api.eddiesedition.com/pro";

  static fetchRest = async (url: string, data = {}) => {
    const res = await fetch(url, {
      headers: {
        Authorization: `Bearer ${process.env.REACT_APP_EDITION_TOKEN}`,
      },
      ...data,
    });
    // First, check if the response is ok (status in the range 200-299)
    if (!res.ok) {
      // If not, we throw an error with the status text (like "Bad Request") and status code
      // This error will be caught by the outer try/catch block
      const errorData = await res.text();
      throw new Error(
        errorData
        // `HTTP error! status: ${res.status}, message: ${errorData}`
      );
    }

    // If the response was ok, we proceed as usual
    const json = await res.json();
    return json.data;
  };

  static getLocation = async (
    pathId: string
  ): Promise<ILocationJsonResult | undefined> =>
    EditionJson.fetchRest(`${EditionJson.ENDPOINT}/locations/${pathId}`);

  static getLocations = async (query: string): Promise<ILocationModel[]> => {
    // return await EditionJson.fetchRest(`${EditionJson.ENDPOINT}/locations${query}`);
    return DEFAULT_LOCATIONS;
  };

  static getPhotos = async (): Promise<
    IPhotosModel & { profile: IUserProfile }[]
  > => EditionJson.fetchRest(`${EditionJson.ENDPOINT}/photos`);

  static getPhoto = async (photoId: string): Promise<any> =>
    EditionJson.fetchRest(`${EditionJson.ENDPOINT}/photos/${photoId}`);

  static getLocationOpeningsByServices = async (
    locationId: string,
    date: string,
    servicesCategories: {
      category: number;
      optionId?: string;
    }[]
  ): Promise<ILocationTimeSlotsJson[]> => {
    const queryArgs: { [key: string]: any } = {
      locationId,
      date,
      services: JSON.stringify(servicesCategories),
    };
    const query = new URLSearchParams(queryArgs).toString();
    return EditionJson.fetchRest(
      `${EditionJson.ENDPOINT}/locations/detail/openings?${query}`
    );
  };

  static getAppointment = async (
    appointmentId: string
  ): Promise<IAppointmentReviewJson | undefined> =>
    EditionJson.fetchRest(
      `${EditionJson.ENDPOINT}/appointments/review/${appointmentId}`
    );

  static getUser = async (pathId: string): Promise<IUserJson | undefined> =>
    await EditionJson.fetchRest(`${EditionJson.ENDPOINT}/users/${pathId}`);

  static getUsers = () => {
    return DEFAULT_STYLISTS;
  };

  static getOpeningsByDuration = async (
    pathId: string,
    date: string,
    duration: number
  ) => {
    const query = new URLSearchParams({
      pathId,
      date,
      duration,
    } as any).toString();
    return EditionJson.fetchRest(
      `${EditionJson.ENDPOINT}/users/openings?${query}`
    );
  };

  static getStylistsByServiceCode = async (
    locationId: string,
    serviceCategories: number[]
  ) => {
    const queryArgs: { [key: string]: any } = {
      locationId,
      serviceCategories: JSON.stringify(serviceCategories),
    };

    const query = new URLSearchParams(queryArgs).toString();
    return EditionJson.fetchRest(
      `${EditionJson.ENDPOINT}/locations/users?${query}`
    );
  };

  static getUserNextOpeningsByDuration = async (
    pathId: string,
    date: string,
    duration: number
  ) => {
    const query = new URLSearchParams({
      pathId,
      date,
      duration,
    } as any).toString();
    return EditionJson.fetchRest(
      `${EditionJson.ENDPOINT}/users/next-openings?${query}`
    );
  };

  static createAppointment = async (args: ICreateAppointmentArgs) => {
    // Copy the args object
    const queryArgs: { [key: string]: any } = { ...args };

    if (queryArgs.services) {
      queryArgs.services = JSON.stringify(queryArgs.services);
    }

    const query = new URLSearchParams(queryArgs).toString();

    return EditionJson.fetchRest(
      `${EditionJson.ENDPOINT}/appointments/create?${query}`,
      {
        method: "POST",
      }
    );
  };

  static rescheduleAppointment = async (args: IRescheduleAppointmentArgs) => {
    // Copy the args object
    const queryArgs: { [key: string]: any } = { ...args };

    if (queryArgs.services) {
      queryArgs.services = JSON.stringify(queryArgs.services);
    }

    const query = new URLSearchParams(queryArgs).toString();
    return EditionJson.fetchRest(
      `${EditionJson.ENDPOINT}/appointments/reschedule?${query}`,
      {
        method: "POST",
      }
    );
  };

  static cancelAppointmentWithCode = async ({
    appointmentId,
    code,
  }: ICancelAppointmentArgs) => {
    return EditionJson.fetchRest(
      `${EditionJson.ENDPOINT}/appointments/cancel/${appointmentId}/${code}`,
      {
        method: "POST",
      }
    );
  };

  static getAppointmentHistory = async (
    phoneNumber: string,
    userId: string
  ): Promise<IAppointmentHistoryJson> => {
    phoneNumber = removeChars(phoneNumber);
    const data: IAppointmentHistoryJson = await EditionJson.fetchRest(
      `${EditionJson.ENDPOINT}/appointments/lookup/${phoneNumber}?userId=${userId}`
    );

    // Filter out past appointments
    data.upcomingAppointments = data.upcomingAppointments.filter(
      (appointment) => {
        return new Date(appointment.dateTime) > new Date();
      }
    );

    return data;
  };

  static getUpcomingAppointments = async (
    phoneNumber: string
  ): Promise<IUpcomingAppointmentsJson[]> => {
    return EditionJson.fetchRest(
      `${EditionJson.ENDPOINT}/appointments/upcoming/${phoneNumber}`
    );
  };

  static sendOPTMessage = async (phoneNumber: string): Promise<void> => {
    phoneNumber = removeChars(phoneNumber);
    return EditionJson.fetchRest(
      `${EditionJson.ENDPOINT}/sms/send/opt-in/${phoneNumber}`,
      {
        method: "POST",
      }
    );
  };

  static sendVerificationCode = async (phoneNumber: string): Promise<void> => {
    phoneNumber = removeChars(phoneNumber);
    return EditionJson.fetchRest(
      `${EditionJson.ENDPOINT}/sms/send/verification/${phoneNumber}`,
      {
        method: "POST",
      }
    );
  };

  static sendVerificationCodeForCancel = async (
    appointmentId: string
  ): Promise<void> => {
    return EditionJson.fetchRest(
      `${EditionJson.ENDPOINT}/sms/send/appointment/${appointmentId}`,
      {
        method: "POST",
      }
    );
  };

  static verifyVerificationCode = async (
    phoneNumber: string,
    code: string
  ): Promise<boolean> => {
    const query = new URLSearchParams({
      phoneNumber,
      code,
    } as any).toString();
    return EditionJson.fetchRest(
      `${EditionJson.ENDPOINT}/verify-code?${query}`,
      {
        method: "POST",
      }
    );
  };

  static getPoints = async (
    phoneNumber: string
  ): Promise<{ points: number }> => {
    phoneNumber = removeChars(phoneNumber);
    return EditionJson.fetchRest(
      `${EditionJson.ENDPOINT}/points/${phoneNumber}`
    );
  };

  static sendServiceRequest = async (
    args: IServiceRequestArgs
  ): Promise<any> => {
    const query = new URLSearchParams(args as any).toString();
    return EditionJson.fetchRest(
      `${EditionJson.ENDPOINT}/requests/send?${query}`,
      {
        method: "POST",
      }
    );
  };

  static sendTextToStylist = async (args: IMessageArgs): Promise<any> => {
    const query = new URLSearchParams(args as any).toString();
    return EditionJson.fetchRest(
      `${EditionJson.ENDPOINT}/chats/text?${query}`,
      {
        method: "POST",
      }
    );
  };

  static sendMessageForAppointment = async (
    appointmentId: string,
    message: string
  ): Promise<any> => {
    const query = new URLSearchParams({ message } as any).toString();
    return EditionJson.fetchRest(
      `${EditionJson.ENDPOINT}/appointments/contact/${appointmentId}?${query}`,
      {
        method: "POST",
      }
    );
  };

  static createPaymentIntent = async (
    services: IServiceModel[]
  ): Promise<any> => {
    return EditionJson.fetchRest(
      `${
        EditionJson.ENDPOINT
      }/payments/create-payment-intent?services=${JSON.stringify(services)}`,
      {
        method: "POST",
      }
    );
  };

  static createPaymentMethod = async (
    args: ICreatePaymentMethodArgs
  ): Promise<any> => {
    const query = new URLSearchParams({ ...args }).toString();

    return EditionJson.fetchRest(
      `${EditionJson.ENDPOINT}/payments/create-payment-method?${query}`,
      {
        method: "POST",
      }
    );
  };
  /*
    get customer's payment method.
  */
  static getPaymentMethod = async (
    phoneNumber: string
  ): Promise<{ paymentMethod: PaymentMethod }> => {
    return EditionJson.fetchRest(
      `${EditionJson.ENDPOINT}/payments/get?phoneNumber=${phoneNumber}`
    );
  };

  static requestSpecialOptions = async (
    appointmentId: string,
    args: { hasExtraLength: boolean; hasNote: boolean; note: string }
  ): Promise<any> => {
    const params: Record<string, string> = {};
    params.appointmentId = appointmentId;
    params.hasExtraLength = args.hasExtraLength.toString();
    params.hasNote = args.hasNote.toString();
    params.note = args.note;

    return EditionJson.fetchRest(
      `${EditionJson.ENDPOINT}/appointments/request?${new URLSearchParams(
        params
      )}`,
      {
        method: "POST",
      }
    );
  };

  static createFeedback = async (values: {
    appointmentId: string;
    time: string;
    service: string;
    quality: string;
    note: string;
  }): Promise<any> => {
    const query = new URLSearchParams(values);
    return EditionJson.fetchRest(
      `${EditionJson.ENDPOINT}/appointments/feedback/create?${query}`,
      {
        method: "POST",
      }
    );
  };

  static getFeedback = async (
    appointmentId: string
  ): Promise<{
    stylistName: string;
    hasFeedback: boolean;
    askReview: boolean;
    links: {
      yelp: string;
      google: string;
    };
  }> =>
    EditionJson.fetchRest(
      `${EditionJson.ENDPOINT}/appointments/feedback/${appointmentId}`
    );

  static getStylistPhotos = async (userId: string): Promise<IPhotosModel[]> =>
    EditionJson.fetchRest(`${EditionJson.ENDPOINT}/users/photos/${userId}`);

  static getBlogArticles = async (): Promise<any> =>
    EditionJson.fetchRest(`${EditionJson.ENDPOINT}/blog`);

  static getBlogArticle = async (articlePath: string): Promise<any> =>
    EditionJson.fetchRest(`${EditionJson.ENDPOINT}/blog/${articlePath}`);
}

export const removeChars = (str: string) => {
  return str.replace(/[^0-9]/g, "");
};
