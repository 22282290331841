import React, { useEffect } from "react";
import {
  INewAppointmentArgs,
  IPathState,
  IAppointmentRouteState,
  RESCHEDULE_MAIN,
} from ".";
import { EditionJson } from "../../api";
import { Button, Divider, Space, Typography, message } from "antd";
import { PaymentMethod } from "@stripe/stripe-js";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { extractErrorMessage } from "../../helpers";

interface IProps {
  routeState: IAppointmentRouteState;
  onRouteChange: (path: IPathState, state: any) => void;
  onCreate: (args: INewAppointmentArgs) => void;
}
const SetupPayment = ({ routeState, onRouteChange, onCreate }: IProps) => {
  const stripe = useStripe();
  const elements = useElements();
  const [isLoading, setLoading] = React.useState(true);
  const [isSubmitting, setSubmitting] = React.useState(false);
  const [showCardForm, setShowCardForm] = React.useState(false);
  const [paymentMethod, setPaymentMethod] = React.useState<PaymentMethod>();

  const { upcomingAppointments, phoneNumber, client } = routeState;

  const handleRouting = async (paymentMethodId: string) => {
    if (upcomingAppointments.length > 0) {
      onRouteChange(RESCHEDULE_MAIN, {
        phoneNumber,
        upcomingAppointments,
        client,
        paymentMethodId,
      });
      return;
    }

    setSubmitting(true);
    onCreate({
      firstName: client ? client.firstName : "Unknown",
      lastName: client ? client.lastName : "Unknown",
      phoneNumber,
      paymentMethodId,
      errorCallback: () => {
        setSubmitting(false);
      },
    });
  };

  const handleAddCard = async () => {
    if (stripe && elements) {
      setSubmitting(true);
      const res = await stripe.createPaymentMethod({
        elements,
      });
      if (res.error) {
        message.error(extractErrorMessage(res.error.message));
        setSubmitting(false);
        return;
      } else {
        try {
          await EditionJson.createPaymentMethod({
            firstName: client.firstName,
            lastName: client.lastName,
            phoneNumber: phoneNumber,
            paymentId: res.paymentMethod.id,
          });
        } catch (e: any) {
          message.error(extractErrorMessage(e));
          setSubmitting(false);
          return;
        }
      }
      handleRouting(res.paymentMethod.id);
    } else {
      message.error("Can't find credit card information. Please try again.");
    }
  };

  useEffect(() => {
    const load = async () => {
      setLoading(true);
      try {
        const res = await EditionJson.getPaymentMethod(phoneNumber);
        setPaymentMethod(res.paymentMethod);
      } catch (error) {
        console.error(error);
      }
      setLoading(false);
    };

    load();
  }, []);
  if (isLoading) return <div>Loading...</div>;
  return (
    <div>
      <Typography.Paragraph>
        We require a credit card hold for some services. Cancellations within 3
        hours or no-shows will result in a charge of the full service fee.
      </Typography.Paragraph>
      {paymentMethod && !showCardForm ? (
        <>
          <Typography.Paragraph>
            We have a payment method on file for you. Please review the details
            below.
          </Typography.Paragraph>

          <Divider />
          <Typography.Paragraph>
            {paymentMethod &&
              paymentMethod.card &&
              `${paymentMethod.card.brand} card ending in ${paymentMethod.card.last4}`}
          </Typography.Paragraph>
          <Divider />
          <Space style={{ justifyContent: "space-between" }}>
            <Button
              loading={isSubmitting}
              onClick={() => handleRouting(paymentMethod.id)}
              type="primary"
            >
              Use saved card
            </Button>
            <Button onClick={() => setShowCardForm(true)}>
              Add a new card
            </Button>
          </Space>
        </>
      ) : (
        <>
          <Space direction="vertical" size="middle" style={{ width: "100%" }}>
            {/* <InputLabel label="Credit Card" id="creditCard" /> */}
            <div className="stripe-card-element-container">
              <CardElement />
            </div>
            <Button
              loading={isSubmitting}
              onClick={handleAddCard}
              type="primary"
            >
              Add Card
            </Button>
          </Space>
        </>
      )}
    </div>
  );
};

export default SetupPayment;
