export type ActionMap<M extends { [index: string]: any }> = {
  [Key in keyof M]: M[Key] extends undefined
    ? {
        type: Key;
      }
    : {
        type: Key;
        payload: M[Key];
      }
};

export enum EventActionTypes {
  OffMaker = "OFF_MARKER",
  OnMaker = "ON_MARKER",
  OffCard = "OFF_CARD",
  OnCard = "ON_CARD",
}

export enum PlaceActionTypes {
  onClick = "ON_CLICK_PLACE",
  onClose = "ON_CLOSE_PLACE",
}
