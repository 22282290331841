import React from "react";
import {
  ButtonBack,
  ButtonNext,
  CarouselProvider,
  Slide,
  Slider,
} from "pure-react-carousel";

import { Image } from "shopify-buy";
import "pure-react-carousel/dist/react-carousel.es.css";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
interface ProductSliderProps {
  images: Image[];
}
const ProductSlider = ({ images }: ProductSliderProps) => {
  return (
    <div className="is-relative">
      <CarouselProvider
        infinite={true}
        naturalSlideWidth={300}
        naturalSlideHeight={300}
        totalSlides={images.length}
        isIntrinsicHeight={true}
        visibleSlides={1}
      >
        <Slider>
          {images.map((img, index) => {
            return (
              <Slide key={index} index={index}>
                <div
                  style={{
                    backgroundSize: "contain",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center",
                    backgroundImage: `url(${img.src})`,
                    height: "300px",
                  }}
                />
              </Slide>
            );
          })}
        </Slider>
        {images.length > 1 && (
          <div className="slide-buttons">
            <ButtonBack className="slide-button is-left">
              <span className="icon">
                <LeftOutlined />
              </span>
            </ButtonBack>
            <ButtonNext className="slide-button is-right">
              <span className="icon">
                <RightOutlined />
              </span>
            </ButtonNext>
          </div>
        )}
      </CarouselProvider>
    </div>
  );
};

export default ProductSlider;
