import { Typography } from "antd";
import React, { useEffect, useState } from "react";
import DisplaySchedules from "../Salons/SalonView/components/DisplaySchedules";
import { defaultServices } from "../../api/consts";
import AppointmentResult from "./AppointmentResult";
import Level from "../../components/Level";
import DateSelector from "../components/DateSelector";
import { formatDate } from "../../helpers";

interface IProps {
  locationId: string;
  onReset: () => void;
}
const Openings = ({ locationId, onReset }: IProps) => {
  const [date, setDate] = useState(new Date());
  const [appointmentId, setAppointmentId] = useState<string | undefined>();
  const onAfterAppointmentSubmited = (appointmentId: string) => {
    setAppointmentId(appointmentId);
  };

  useEffect(() => {
    let timer: NodeJS.Timeout;

    const resetTimer = () => {
      if (timer) {
        clearTimeout(timer);
      }
      timer = setTimeout(() => {
        onReset();
      }, 1000 * 60 * 3); // 3 minutes
    };

    resetTimer(); // start the timer on component mount

    // Event listeners to detect user activity
    window.addEventListener("mousemove", resetTimer);
    window.addEventListener("keydown", resetTimer);
    window.addEventListener("scroll", resetTimer);
    window.addEventListener("touchstart", resetTimer);

    // Cleanup the event listeners and timer on component unmount
    return () => {
      window.removeEventListener("mousemove", resetTimer);
      window.removeEventListener("keydown", resetTimer);
      window.removeEventListener("scroll", resetTimer);
      window.removeEventListener("touchstart", resetTimer);
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [onReset]);

  if (appointmentId) {
    return <AppointmentResult onReset={onReset} />;
  }
  return (
    <>
      <Level
        left={
          <Typography.Title className="is-marginless">
            Openings
          </Typography.Title>
        }
        right={
          <DateSelector
            selectedDate={date}
            setDate={setDate}
            hasBorder={true}
            filterDays={[]}
            excludedDates={[]}
          />
        }
      />

      <Typography.Paragraph className="is-size-4">
        Please select a time slot to make an appointment.
      </Typography.Paragraph>
      <div style={{ overflowY: "scroll" }}>
        <DisplaySchedules
          locationId={locationId}
          services={[defaultServices[0]]}
          date={formatDate(date)}
          refreshCount={0}
          onAfterAppointmentSubmited={(appointmentId: string) => {
            onAfterAppointmentSubmited(appointmentId);
          }}
        />
      </div>
    </>
  );
};

export default Openings;
