import { useEffect, useState } from "react";
import { WENT_WRONG } from "../consts";
interface IDataLodaerResult {
  isLoading: boolean;
  data: any;
  error?: string;
}

export const useDataLoader = (fn: any, deps: any[]): IDataLodaerResult => {
  const [data, setData] = useState<any>();
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState<string | undefined>(undefined);

  useEffect(() => {
    async function fetch() {
      setLoading(true);
      try {
        const res = await fn();
        setData(res);
      } catch (e: any) {
        console.error(e);
        setError(e && e.message ? e.message : WENT_WRONG);
      }
      setLoading(false);
    }
    fetch();
  }, deps);
  return { error, isLoading, data };
};
