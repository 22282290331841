import React from "react";
import { IServiceRequestArgs } from "../../../../../api/types";
import { Button, Divider, Input, Space, Typography, message } from "antd";
import { EditionJson } from "../../../../../api";
import PhoneInputForUSOnly from "react-phone-number-input/input";
import InputLabel from "../../../../../components/InputLabel";
import { isValidPhoneNumber } from "libphonenumber-js";

const { TextArea } = Input;

interface IRequestFormProps {
  userId: string;
  userName: string;
  onClose: () => void;
}
const RequestForm = (props: IRequestFormProps) => {
  const { userId, userName, onClose } = props;
  const [values, setValues] = React.useState<IServiceRequestArgs>({
    firstName: "",
    lastName: "",
    phoneNumber: "",
    describeForCurrentHair: "",
    describeForRequest: "",
    userId: userId,
  });
  const [isLoading, setLoading] = React.useState(false);
  const [sent, setSent] = React.useState(false);
  const onSendRequest = async () => {
    if (!isValidPhoneNumber(values.phoneNumber)) {
      message.error("Invalid phone number");
      return;
    }
    setLoading(true);

    try {
      await EditionJson.sendServiceRequest(values);
      setSent(true);
      message.success("Request sent");
    } catch (e: any) {
      message.error(e.message);
    }
    setLoading(false);
  };
  const onChange = (e: React.ChangeEvent<any>) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
  };
  if (sent)
    return (
      <>
        <Typography.Title level={3}>Thank you</Typography.Title>
        <Typography.Paragraph>
          {`Your message has been forwarded to ${userName}. We'll get back to you
      shortly.`}
        </Typography.Paragraph>

        <Button onClick={onClose}>Close</Button>
      </>
    );
  return (
    <>
      <Space direction="vertical" style={{ width: "100%" }}>
        <div>
          <InputLabel
            label="Describe your current hair"
            id="describeForCurrentHair"
          />
          <TextArea
            id="describeForCurrentHair"
            name="describeForCurrentHair"
            value={values.describeForCurrentHair}
            rows={4}
            onChange={onChange}
            placeholder="Please provide details about your current hair, including color, length, condition, and any other relevant information that the stylist should be aware of:"
          />
        </div>
        <div>
          <InputLabel label="Describe your request" id="describeForRequest" />
          <TextArea
            id="describeForRequest"
            name="describeForRequest"
            placeholder="Please describe the service you're seeking. Specify the color, style, and any other specific preferences or requirements that the stylist should be aware of:"
            value={values.describeForRequest}
            onChange={onChange}
            rows={4}
          />
        </div>

        <div className="field">
          <InputLabel label="First Name" id="firstName" />
          <input
            id="firstName"
            name="firstName"
            className="input is-shadowless"
            value={values.firstName}
            onChange={onChange}
          />
        </div>
        <div>
          <InputLabel label="Last Name" id="lastName" />
          <input
            id="lastName"
            name="lastName"
            className="input is-shadowless"
            value={values.lastName}
            onChange={onChange}
          />
        </div>
        <div>
          <InputLabel label="Phone Number" id="phoneNumber" />
          <PhoneInputForUSOnly
            id="phoneNumber"
            className="input is-shadowless"
            value={values.phoneNumber}
            onChange={(value: any) =>
              onChange({
                target: {
                  name: "phoneNumber",
                  value: value,
                },
              } as any)
            }
            country="US"
            autoComplete="tel"
          />
        </div>
      </Space>
      <Divider />
      <Button
        disabled={
          isLoading ||
          !values.phoneNumber ||
          !values.firstName ||
          !values.lastName ||
          !values.describeForCurrentHair ||
          !values.describeForRequest
        }
        onClick={onSendRequest}
        loading={isLoading}
        type="primary"
      >
        Request
      </Button>
    </>
  );
};

export default RequestForm;
