import React from "react";

interface MenuBarProps {
  isSidebarActive: boolean;
  onClick: () => void;
}

const MobileMenuBarButton: React.FC<MenuBarProps> = ({
  isSidebarActive,
  onClick,
}) => {
  return (
    <button
      style={{height:"auto"}}
      className={`navbar-burger ${isSidebarActive && "is-active"}`}
      onClick={onClick}
    >
      <span aria-hidden="true" />
      <span aria-hidden="true" />
      <span aria-hidden="true" />
    </button>
  );
};

export default MobileMenuBarButton;
