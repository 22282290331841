import React from "react";

import { Button, Divider, Input, Space, Typography, message } from "antd";
import PhoneInputForUSOnly, {
  isValidPhoneNumber,
} from "react-phone-number-input/input";
import { EditionJson } from "../../../../api";
import InputLabel from "../../../../components/InputLabel";

const { TextArea } = Input;

interface IMessageFormProps {
  userId: string;
  stylistName: string;
  onClose: () => void;
}

const MessageForm = ({ userId, stylistName, onClose }: IMessageFormProps) => {
  const [values, setValues] = React.useState<any>({
    userId,
    firstName: "",
    lastName: "",
    phoneNumber: "",
    message: "",
  });

  const [isLoading, setLoading] = React.useState(false);
  const [sent, setSent] = React.useState(false);

  const onSendRequest = async () => {
    if (!isValidPhoneNumber(values.phoneNumber)) {
      message.error("Invalid phone number");
      return;
    }

    setLoading(true);

    try {
      await EditionJson.sendTextToStylist(values);
      setSent(true);
    } catch (e: any) {
      message.error(e.message);
    }
    setLoading(false);
  };
  const onChange = (e: React.ChangeEvent<any>) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
  };

  if (sent)
    return (
      <>
        <Typography.Title level={3}>Thank you</Typography.Title>
        <Typography.Paragraph>
          {`Your message has been forwarded to ${stylistName}. We'll get back to you
      shortly.`}
        </Typography.Paragraph>

        <Button onClick={onClose}>Close</Button>
      </>
    );
  return (
    <>
      <Space direction="vertical" style={{ width: "100%" }}>
        <div>
          <InputLabel id="message" label="Message" />
          <TextArea
            id="message"
            name="message"
            value={values.message}
            rows={4}
            onChange={onChange}
            placeholder="Hi!"
          />
        </div>

        <div>
          <InputLabel id="firstName" label="First Name" />
          <Input
            id="firstName"
            name="firstName"
            value={values.firstName}
            onChange={onChange}
          />
        </div>

        <div>
          <InputLabel id="lastName" label="Last Name" />
          <Input
            id="lastName"
            name="lastName"
            value={values.lastName}
            onChange={onChange}
          />
        </div>

        <div>
          <InputLabel id="phoneNumber" label="Phone Number" />
          <PhoneInputForUSOnly
            id="phoneNumber"
            className="input is-shadowless"
            value={values.phoneNumber}
            onChange={(value: any) =>
              onChange({
                target: {
                  name: "phoneNumber",
                  value: value,
                },
              } as any)
            }
            country="US"
            autoComplete="tel"
          />
        </div>
      </Space>
      <Divider />
      <Button
        disabled={
          isLoading ||
          !values.phoneNumber ||
          !values.firstName ||
          !values.lastName ||
          !values.message
        }
        onClick={onSendRequest}
        loading={isLoading}
        type="primary"
      >
        Send
      </Button>
    </>
  );
};

export default MessageForm;
