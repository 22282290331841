import React from "react";
import { Route, Routes } from "react-router-dom";

import AppContentWrapper from "../../components/AppContentWrapper";
import Products from "./scenes/Products";
import ProductView from "./scenes/ProductView";

export const Shop = () => {
  return (
    <AppContentWrapper>
      <Routes>
        <Route path="/" element={<Products />} />
        <Route path="product/:productId" element={<ProductView />} />
      </Routes>
    </AppContentWrapper>
  );
};

export default Shop;
