import React from "react";
import Helmet from "react-helmet";

interface IArticleMetaTagProps {
  image?: string;
  type: string;
  title: string;
  description: string;
  url: string;
  canonical?: boolean;
  scripts?: any[];
}
export const MetaTagWrapper = ({
  type,
  image,
  title,
  description,
  canonical,
  url,
  scripts = []
}: IArticleMetaTagProps) => {
  return (
    <Helmet script={scripts}>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta property="og:site_name" content="Eddie's Edition" />
      <meta property="og:url" content={url} />
      <meta property="og:type" content={type} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="twitter:title" content={title} />
      <meta property="twitter:description" content={description} />
      <meta property="twitter:card" content="summary" />
      {canonical && <link rel="canonical" href={url} />}
      {image && <meta property="og:image" content={image} />}
      {image && <meta name="twitter:image" content={image} />}
    </Helmet>
  );
};
