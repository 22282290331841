import React from "react";

const AppContentWrapper = (props: any) => {
  return (
    <div className="content-wrapper">
      <section className="section has-thin-top-padding">
        <div className="container is-max-desktop">{props.children}</div>
      </section>
    </div>
  );
};

export default AppContentWrapper;
