import React, { useState } from "react";
import { EditionJson } from "../../api";
import TroubleShootingForCode from "./components/TroubleShootingForCode";
import { Button, Divider, Input, Space, Typography, message } from "antd";
import Level from "../../components/Level";
import InputLabel from "../../components/InputLabel";
import { INewAppointmentArgs } from ".";
import { extractErrorMessage } from "../../helpers";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";

interface IProps {
  routeState: any;
  onCreate: (args: INewAppointmentArgs) => void;
}
const FirstClient = ({ routeState, onCreate }: IProps) => {
  const stripe = useStripe();
  const elements = useElements();

  const { phoneNumber, isCreditCardRequired } = routeState;
  const [firstName, setFirstName] = useState<string>();
  const [lastName, setLastName] = useState<string>();
  const [code, setCode] = useState<string>();
  const [isSubmitting, setSubmitting] = useState(false);
  const [isSending, setSending] = useState(false);
  const [showInstructure, setShowInstructure] = React.useState(false);
  // const inputRef = React.useRef<HTMLInputElement>(null);

  const handleSubmit = async () => {
    if (phoneNumber && firstName && lastName && code) {
      setSubmitting(true);

      const isVerified = await EditionJson.verifyVerificationCode(
        phoneNumber,
        code
      );

      if (isVerified) {
        if (stripe && elements && isCreditCardRequired) {
          const { error, paymentMethod } = await stripe.createPaymentMethod({
            elements,
          });
          if (error) {
            message.error(error.message);
            setSubmitting(false);
            return;
          } else {
            try {
              await EditionJson.createPaymentMethod({
                firstName,
                lastName,
                phoneNumber,
                paymentId: paymentMethod.id,
              });
            } catch (error: any) {
              message.error(error.message);
              setSubmitting(false);
              return;
            }
          }

          onCreate({
            firstName,
            lastName,
            phoneNumber,
            code,
            paymentMethodId: paymentMethod.id,
            errorCallback: () => {
              setSubmitting(false);
            },
          });
        } else {
          onCreate({
            firstName,
            lastName,
            phoneNumber,
            code,
            errorCallback: () => {
              setSubmitting(false);
            },
          });
        }
      } else {
        message.error("Invalid verification code");
        setSubmitting(false);
      }
    }
  };

  const onSendVerificationCode = async () => {
    setSending(true);
    try {
      await EditionJson.sendVerificationCode(phoneNumber);
      message.info("Sent");
    } catch (err: any) {
      message.error(extractErrorMessage(err));
    }
    setSending(false);
  };

  // React.useEffect(() => {
  //   if (inputRef.current) {
  //     inputRef.current.focus();
  //   }
  // }, [routeState]);

  if (showInstructure) {
    return <TroubleShootingForCode onClose={() => setShowInstructure(false)} />;
  }
  return (
    <>
      <Typography.Paragraph>
        We've sent a verification code to your mobile number. Please fill out
        the form with the code to continue.
      </Typography.Paragraph>

      <Space direction="vertical" style={{ width: "100%" }}>
        <div>
          <InputLabel id="firstName" label="First Name" />
          <Input
            id="firstName"
            placeholder="First Name"
            value={firstName}
            onChange={(e) => {
              setFirstName(e.target.value);
            }}
          />
        </div>
        <div>
          <InputLabel id="lastName" label="Last Name" />
          <Input
            id="lastName"
            placeholder="Last Name"
            value={lastName}
            onChange={(e) => {
              setLastName(e.target.value);
            }}
          />
        </div>
        {isCreditCardRequired && (
          <>
            <div>
              <InputLabel id="creditCard" label="Credit Card" />
              <div className="stripe-card-element-container">
                <CardElement />
              </div>
            </div>
            <div>
              <Typography.Text>
                <small>
                  By providing your card information, you authorize Eddie's
                  Edition to charge your card for future payments in accordance
                  with their terms. Please note that our cancellation policy
                  dictates a charge of the full service amount for no-shows or
                  cancellations made within 3 hours of the appointment.
                </small>
              </Typography.Text>
            </div>
          </>
        )}

        <div>
          <InputLabel id="code" label="Verification Code" />
          <Input
            id="code"
            placeholder="Code"
            value={code}
            onChange={(e) => {
              setCode(e.target.value);
            }}
          />
        </div>
        <div className="has-text-right">
          <Typography.Link
            type="secondary"
            onClick={() => setShowInstructure(true)}
          >
            <small>Having trouble receiving the code?</small>
          </Typography.Link>
        </div>
      </Space>
      <Divider />
      <Level
        isMobile
        left={
          <Button
            type="primary"
            loading={isSubmitting}
            disabled={isSubmitting || !firstName || !lastName || !code}
            onClick={handleSubmit}
          >
            Submit
          </Button>
        }
        right={
          <Button
            type="link"
            disabled={isSending}
            onClick={onSendVerificationCode}
          >
            Re-send
          </Button>
        }
      />
    </>
  );
};

export default FirstClient;
