import React from "react";
import GoogleMapReact from "google-map-react";
import { BrowseContext } from "../../contexts";
import { Spin } from "antd";

// @ts-ignore
const GoogleMap = ({ children, ...props }) => {
  const { state, dispatch } = React.useContext(BrowseContext);
  const { place } = state;
  return (
    <div style={{ width: "100%", height: "100%" }}>
      <GoogleMapReact
        // @ts-ignore
        center={place ? { lat: place.lat, lng: place.lng } : undefined}
        options={{
          fullscreenControlOptions: {
            position: 9,
          },
        }}
        bootstrapURLKeys={{
          key: process.env.REACT_APP_GOOGLE_API_KEY as string,
        }}
        {...props}
      >
        {children}
      </GoogleMapReact>
      {props.isLoading && (
        <div style={{ position: "absolute", right: 15, top: 15 }}>
          <Spin />
        </div>
      )}
    </div>
  );
};

export default GoogleMap;
