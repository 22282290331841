import React from "react";
import { Route, Routes } from "react-router-dom";

import PhotoList from "./List";
import PhotoPage from "./Page";

const Blog = () => {
  return (
    <Routes>
      <Route path={"/"} element={<PhotoList />} />
      <Route path={"/:path"} element={<PhotoPage />} />
    </Routes>
  );
};

export default Blog;
