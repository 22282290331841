import React, { createContext, useContext, useState } from "react";
import { DefaultServiceType } from "../api/consts";

const initialState = {
  isAppointmentModalActive: false,
  appointmentForm: undefined,
  date: new Date(),
};


export interface AppointmentForm {
  date: string;
  time: string;
  stylistName: string;
  locationName?: string;
  userId: string;
  scheduleId: string;
  services: ApppointmentServiceType[];
  onAfter?: (appointmentId: string) => void;
}

export interface ApppointmentServiceType extends DefaultServiceType {
  price: number;
  duration: number;
  up?: boolean;
  hasPayAdvance?: boolean;
  discount?: {
    title: string;
    description: string;
    discountAmount: number;
    startDate: string;
    endDate: string;
    hasDate: boolean;
  };
}

export interface AppState {
  isAppointmentModalActive: boolean;
  appointmentForm?: AppointmentForm;
  date: Date;
}

interface IAppContext {
  appointmentForm: any; // Use your AppointmentForm type here instead of any
  isAppointmentModalActive: boolean;
  date: Date;
  openAppointmentForm: (form: any) => void; // Use your AppointmentForm type
  closeAppointmentForm: () => void;
  setDate: (date: Date) => void;
}

export const AppContext = createContext({
  ...initialState,
  openAppointmentForm: () => {},
  closeAppointmentForm: () => {},
  setDate: () => {},
} as IAppContext);

export const AppContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [state, setState] = useState(initialState);

  const openAppointmentForm = (form: any) => {
    setState((prev) => ({
      ...prev,
      isAppointmentModalActive: true,
      appointmentForm: form,
    }));
  };

  const closeAppointmentForm = () => {
    setState((prev) => ({
      ...prev,
      isAppointmentModalActive: false,
      appointmentForm: undefined,
    }));
  };

  const setDate = (date: Date) => {
    setState((prev) => ({ ...prev, date }));
  };
  return (
    <AppContext.Provider
      value={{
        ...state,
        openAppointmentForm,
        closeAppointmentForm,
        setDate,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export const useApp = () => useContext(AppContext);
