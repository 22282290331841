import React from "react";
import Select, { components } from "react-select";
import {
  DefaultServiceType,
  defaultServiceObject,
} from "../../../../../api/consts";
import { Button, Typography } from "antd";
import { PlusCircleOutlined } from "@ant-design/icons";
interface SalonServicesProps {
  selectedServices: DefaultServiceType[];
  defaultServices: DefaultServiceType[];
  setServices: (services: DefaultServiceType[]) => void;
  setCombineServicesModalActive: () => void;
}
const CustomOption = (props: any) => (
  <components.Option {...props}>
    {props.value === 0 ? (
      <div style={{ display: "flex", alignItems: "center" }}>
        <PlusCircleOutlined />
        <span className="ml-1">{props.label}</span>
      </div>
    ) : (
      props.label
    )}
  </components.Option>
);
const customService = {
  title: "Combine Services",
  category: 0,
};

const SelectService = ({
  selectedServices,
  defaultServices,
  setServices,
  setCombineServicesModalActive,
}: SalonServicesProps) => {
  const options: any = [...defaultServices];
  if (defaultServices.length > 1) {
    options.push(customService);
  }
  return (
    <>
      <Typography.Title level={5}>Services</Typography.Title>
      {selectedServices.length > 1 ? (
        <>
          <Typography.Paragraph>
            <ul>
              {selectedServices.map((service, i) => (
                <li key={`service-${i}`}>{service.title}</li>
              ))}
            </ul>
          </Typography.Paragraph>
          <Button
            onClick={setCombineServicesModalActive}
            size="small"
            type="dashed"
          >
            Edit Services
          </Button>
        </>
      ) : (
        <>
          <Select
            options={options}
            value={selectedServices[0]}
            getOptionLabel={(option: any) => {
              // Uniform the title of the service
              const s = defaultServiceObject[option.category];
              if (s) {
                return s.title;
              } else {
                return option.title;
              }
            }}
            getOptionValue={(option: any) => option.category}
            onChange={(value: any) => {
              if (value.category === 0) {
                setCombineServicesModalActive();
              } else {
                setServices([value]);
              }
            }}
            components={{
              Option: CustomOption,
            }}
          />
        </>
      )}
    </>
  );
};

export default SelectService;
