import React from "react";
import { Link } from "react-router-dom";
import AppContentWrapper from "../../../components/AppContentWrapper";
import { MetaTagWrapper } from "../../../components/MetaTags";
import { EditionJson } from "../../../api";
import { Typography } from "antd";

const StylistList = () => {
  const stylists = EditionJson.getUsers();
  return (
    <>
      <MetaTagWrapper
        title="Stylists | Eddie's Edition"
        description="Meet our stylists at Eddie's Edition! Dive into their expertise, styles, and stories."
        type="website"
        image={
          "https://eddiesedition.s3-us-west-2.amazonaws.com/images/logos/logo.png"
        }
        url="https://www.eddiesedition.com/stylists"
        canonical={true}
      />
      <AppContentWrapper>
        <Typography.Title level={3}>Stylists</Typography.Title>
        <div className="columns is-multiline">
          {stylists.map((stylist) => (
            <div key={stylist.pathId} className="column is-2">
              <Link
                to={`/stylists/${stylist.pathId}`}
              >
                <div className="has-background-black">
                  <Typography style={{ overflowX: "hidden" }}>
                    <Typography.Title className="has-text-white mb-0" level={5}>
                      {stylist.name}
                    </Typography.Title>
                    <Typography.Text className="has-text-white">
                      <small>{stylist.locations}</small>
                    </Typography.Text>
                  </Typography>
                </div>
              </Link>
            </div>
          ))}
        </div>
      </AppContentWrapper>
    </>
  );
};
export default StylistList;
