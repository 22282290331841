import React, { useEffect, useState } from "react";
import { ProductVariant, Product } from "shopify-buy";
import NotFound from "../../../../components/NotFound";
import { MetaTagWrapper } from "../../../../components/MetaTags";
import ProductSlider from "./components/ProductSlider";
import { useParams } from "react-router-dom";
import { formatProductId } from "../../helpers";
import { WENT_WRONG } from "../../../../consts";

interface IProductDisplayProps {
  productId: string;
  product: Product;
}

const ProductDisplay = ({ productId, product }: IProductDisplayProps) => {

  const defaultSize = product.variants && product.variants[0].id.toString();
  const [size, setSize] = useState("");
  const [quantity, setQuantity] = useState(1);

  const changeSize = (sizeId: string, quantity: any) => {
    // openCart();
    // if (sizeId === "") {
    //   sizeId = defaultSize;
    //   const lineItemsToAdd = [
    //     { variantId: sizeId, quantity: parseInt(quantity, 10) },
    //   ];
    //   // @ts-ignore
    //   const checkoutId = checkoutState.id;
    //   addVariant(checkoutId, lineItemsToAdd);
    // } else {
    //   const lineItemsToAdd = [
    //     { variantId: sizeId, quantity: parseInt(quantity, 10) },
    //   ];
    //   // @ts-ignore
    //   const checkoutId = checkoutState.id;
    //   addVariant(checkoutId, lineItemsToAdd);
    // }
  };
  return (
    <div>
      <MetaTagWrapper
        title={`${product.title}`}
        description={product.description}
        type="website"
        url={"https://www.eddiesedition.com/shop/product/" + productId}
        canonical={true}
      />
      <div className="columns">
        <div className="column is-narrow">
          <div className="is-narrow-inner" style={{ width: "600px" }}>
            <ProductSlider images={product.images} />
          </div>
        </div>
        <div className="column">
          <h2 className="title is-4 is-spaced">{product.title}</h2>
          <div className="is-flex" style={{ alignItems: "center" }}>
            <div className="is-size-7" style={{ width: "80px" }}>
              PRICE
            </div>
            {/* @ts-ignore */}
            <p>${product.variants[0].price.amount}</p>
          </div>

          {product.variants && product.variants.length > 1 && (
            <div className="is-flex" style={{ alignItems: "center" }}>
              <div className="is-size-7" style={{ width: "80px" }}>
                OPTIONS{" "}
              </div>
              <select
                id="prodOptions"
                name={size}
                onChange={(e) => {
                  setSize(e.target.value);
                }}
              >
                {product.variants.map((item: ProductVariant, i: any) => {
                  return (
                    <option
                      value={item.id.toString()}
                      key={item.title + i}
                    >{`${item.title}`}</option>
                  );
                })}
              </select>
            </div>
          )}
          <div className="field has-addons mt-5 mb-5">
            <div className="control">
              <input
                className="input is-shadowless"
                type="number"
                min={1}
                value={quantity}
                onChange={(e) => {
                  setQuantity(e.target.value as any);
                }}
              />
            </div>
            <div className="control">
              <button
                onClick={(e) => changeSize(size, quantity)}
                className="button is-black"
              >
                Add to Cart
              </button>
            </div>
          </div>

          <div
            className="content"
            // @ts-ignore
            dangerouslySetInnerHTML={{ __html: product.descriptionHtml }}
          />
        </div>
      </div>
    </div>
  );
};

const ProductView = () => {
  const params = useParams();
  const id = params.productId;
  const [product, setProduct] = useState();
  const [isLoading, setLoading] = useState(true);
  const [error, setError] = useState<any>();

  // useEffect(() => {
  //   const load = async (productId: string) => {
  //     try {
  //       const res = await shopifyClient.product.fetch(
  //         formatProductId(productId)
  //       );
  //       setLoading(false);
  //       setProduct(res as any);
  //     } catch (e) {
  //       setError(e);
  //       console.error(e);
  //     }
  //     setLoading(false);
  //   };
  //   if (id) {
  //     load(id);
  //   }
  // }, [id]);
  if (!id) return <NotFound />;
  if (isLoading) return null;
  if (error) return <div>{WENT_WRONG}</div>;
  if (!product) return <NotFound />;

  return <ProductDisplay productId={id} product={product} />;
};

export default ProductView;
