import React, { useEffect } from "react";
import PageTitle from "../components/PageTitle";
import { Link } from "react-router-dom";
import { MetaTagWrapper } from "../../components/MetaTags";
import { EditionJson } from "../../api";
import { extractErrorMessage, stripHtml } from "../../helpers";
import { WENT_WRONG } from "../../consts";
import { Card, Typography, Image } from "antd";
import AppContentWrapper from "../../components/AppContentWrapper";
const { Meta } = Card;

const BlogList = () => {
  const [articles, setArticles] = React.useState<any[]>([]);
  const [error, setError] = React.useState("");
  useEffect(() => {
    (async () => {
      try {
        const res = await EditionJson.getBlogArticles();
        setArticles(res);
      } catch (error) {
        setError(extractErrorMessage(error));
      }
    })();
  }, []);
  if (error) {
    return <div>{WENT_WRONG}</div>;
  }
  return (
    <AppContentWrapper>
      <MetaTagWrapper
        title="Blog | Eddie's Edition"
        description="Never be uninspired when it comes to how to style and care for your haircut. Visit Eddie's Edition blog for hairstyle ideas, care tutorials and meaningful conversation."
        type="website"
        image="https://eddiesedition.s3-us-west-2.amazonaws.com/images/logos/logo.png"
        url="https://www.eddiesedition.com/blog"
        canonical={true}
      />
      <PageTitle title="Blog" />
      <Typography.Paragraph>
        Tips, Trends, and Inspiration for Hair.
      </Typography.Paragraph>
      <div className="columns is-multiline">
        {articles.map((article) => {
          return (
            <div key={article.path} className="column is-4">
              <Link to={"/blog/" + article.path}>
                <Card
                  hoverable
                  cover={
                    <Image
                      preview={false}
                      // preview={{ mask: <div></div> }}
                      src={article.coverImage}
                      alt=""
                      height="300px"
                    />
                  }
                >
                  <Meta
                    title={article.title}
                    description={
                      <Typography.Paragraph ellipsis={{ rows: 2 }}>
                        {stripHtml(article.content)}
                      </Typography.Paragraph>
                    }
                  />
                </Card>
              </Link>
            </div>
          );
        })}
      </div>
    </AppContentWrapper>
  );
};

export default BlogList;
