import React from "react";

const Terms = () => {
  return (
    <div>
      <h2>Eddie's Edition Terms of Service</h2>
      <h3>1. Terms</h3>
      <p>
        By accessing the website at https://www.eddiesedition.com, you are
        agreeing to be bound by these terms of service, all applicable laws and
        regulations, and agree that you are responsible for compliance with any
        applicable local laws. If you do not agree with any of these terms, you
        are prohibited from using or accessing this site. The materials
        contained in this website are protected by applicable copyright and
        trademark law.
      </p>
      <h3>2. Use License</h3>
      <ol type="a">
        <li>
          Permission is granted to temporarily download one copy of the
          materials (information or software) on Eddie's Edition's website for
          personal, non-commercial transitory viewing only. This is the grant of
          a license, not a transfer of title, and under this license you may
          not:
          <ol type="i">
            <li>modify or copy the materials;</li>
            <li>
              use the materials for any commercial purpose, or for any public
              display (commercial or non-commercial);
            </li>
            <li>
              attempt to decompile or reverse engineer any software contained on
              Eddie's Edition's website;
            </li>
            <li>
              remove any copyright or other proprietary notations from the
              materials; or
            </li>
            <li>
              transfer the materials to another person or "mirror" the materials
              on any other server.
            </li>
          </ol>
        </li>
        <li>
          This license shall automatically terminate if you violate any of these
          restrictions and may be terminated by Eddie's Edition at any time.
          Upon terminating your viewing of these materials or upon the
          termination of this license, you must destroy any downloaded materials
          in your possession whether in electronic or printed format.
        </li>
      </ol>
      <h3>3. Disclaimer</h3>
      <ol type="a">
        <li>
          The materials on Eddie's Edition's website are provided on an 'as is'
          basis. Eddie's Edition makes no warranties, expressed or implied, and
          hereby disclaims and negates all other warranties including, without
          limitation, implied warranties or conditions of merchantability,
          fitness for a particular purpose, or non-infringement of intellectual
          property or other violation of rights.
        </li>
        <li>
          Further, Eddie's Edition does not warrant or make any representations
          concerning the accuracy, likely results, or reliability of the use of
          the materials on its website or otherwise relating to such materials
          or on any sites linked to this site.
        </li>
      </ol>
      <h3>4. Limitations</h3>
      <p>
        In no event shall Eddie's Edition or its suppliers be liable for any
        damages (including, without limitation, damages for loss of data or
        profit, or due to business interruption) arising out of the use or
        inability to use the materials on Eddie's Edition's website, even if
        Eddie's Edition or a Eddie's Edition authorized representative has been
        notified orally or in writing of the possibility of such damage. Because
        some jurisdictions do not allow limitations on implied warranties, or
        limitations of liability for consequential or incidental damages, these
        limitations may not apply to you.
      </p>
      <h3>5. Accuracy of materials</h3>
      <p>
        The materials appearing on Eddie's Edition's website could include
        technical, typographical, or photographic errors. Eddie's Edition does
        not warrant that any of the materials on its website are accurate,
        complete or current. Eddie's Edition may make changes to the materials
        contained on its website at any time without notice. However Eddie's
        Edition does not make any commitment to update the materials.
      </p>
      <h3>6. Links</h3>
      <p>
        Eddie's Edition has not reviewed all of the sites linked to its website
        and is not responsible for the contents of any such linked site. The
        inclusion of any link does not imply endorsement by Eddie's Edition of
        the site. Use of any such linked website is at the user's own risk.
      </p>
      <h3>7. Modifications</h3>
      <p>
        Eddie's Edition may revise these terms of service for its website at any
        time without notice. By using this website you are agreeing to be bound
        by the then current version of these terms of service.
      </p>
      <h3>8. Governing Law</h3>
      <p>
        These terms and conditions are governed by and construed in accordance
        with the laws of California and you irrevocably submit to the exclusive
        jurisdiction of the courts in that State or location.
      </p>

      <h3>9. Your Profile Information and Account</h3>
      <p>
        Your User ID is the only way to log in and access your Account. You
        understand and agree that you are responsible for maintaining the
        confidentiality of your User ID. You agree to provide true, accurate,
        current, and complete information about yourself, or the entity you
        represent, and you agree not to misrepresent your Profile Information.
        Eddie's Edition is not responsible for any Profile Information that may
        be lost or irrecoverable through the use of the Site or Services. For as
        long as you remain the Account Owner and in compliance with this
        Agreement, you have the right to access and use the Services. This right
        is non-transferable, and you agree not to disclose your User ID to any
        Third Party. You are solely responsible for all activities that occur
        within your Account. If you become aware of any unauthorized use of your
        Account or Profile Information, you are responsible for notifying
        Eddie's Edition immediately. It is your responsibility to update or
        change any Account or Profile Information, as needed or desired. Billing
        information can be updated from within your Account, and may only be
        changed by the Account Owner. Eddie's Edition disclaims all liability
        where Account ownership is disputed, and will not participate or play
        any role in such disputes. Eddie's Edition will automatically charge the
        credit card on file; should the billing information be changed, the new
        credit card will be charged. If an updated credit card is under a
        different name, the owner of the updated card becomes the Account Owner.
        Eddie's Edition will not change your billing information for you, under
        any circumstances, and will not be held responsible if an unauthorized
        party accesses your Account or changes your billing details. Eddie's
        Edition does not see or store full credit card numbers at any time.
      </p>

      <h3>10. Fees and Renewals</h3>
      <p>
        You agree to pay all Subscription fees, including but not limited to:
        fees based on Add-Ons, traffic to your pages, and number of pages,
        popups, and sticky bars in your Account and all sub-accounts. Unless
        otherwise specified in a Subscription, such fees will be billed in
        advance for each month and are non-refundable. You agree that you may
        not be refunded or credited for partial months of service, or for
        periods in which your Account remains open but you do not use the
        Services. If you exceed any usage or fee thresholds set forth in your
        Subscription, we may charge you for such overages on your next invoice.
        We reserve the right to modify our billing rates at any time upon thirty
        (30) days written notice by posting these fee changes to the Site or by
        notifying you via email. Should you continue to use the Services after
        these changes go into effect, you will be responsible for paying the new
        Subscription/billing rate. By entering a credit card number, you agree
        that Eddie's Edition, and our Third Party service providers, may store
        your payment card information. You expressly authorize us to charge you,
        where applicable: (i) Subscription Fees, to be billed during a
        Subscription Period (ii) other fees for Services purchased, including,
        but not limited to, Add-Ons (iii) charges for use of the Services in
        excess of visitor, page, or other limitations, and (iv) taxes connected
        with your use of the Services. You agree to reimburse us, where
        applicable, for all collection costs and interest for any overdue
        amounts. If the payment card you provide expires and you do not update
        your payment card information or cancel your Subscription, you authorize
        us to suspend your Account until your billing details have been updated.
        After an Account suspension, you agree that we may re-attempt payment
        processing upon receiving updated billing details.
      </p>

      <h3>11. Term and Renewal</h3>
      <p>
        Your paid Subscription is valid for either a monthly or an annual
        Subscription Period. Your Free Account is valid until such time as you
        upgrade or request Account deletion (by contacting
        info@eddiesedition.com from the email address on file and requesting
        that we delete your Account). If you upgrade to a higher-level
        Subscription during the Subscription Period, you will have immediate
        access to those higher level features. You agree to pay Eddie's Edition
        the difference between your original Subscription fee and the cost of
        the upgrade. If you downgrade to a lower-level Subscription during the
        Subscription Period, you will receive a credit to your Account
        representing the difference between your original and your downgraded
        Subscription costs. Changes to Subscriptions will be reflected in your
        next invoice. No credits or refunds will be issued for downgrading from
        a paid subscription to a Free Account (cancelling). Your Subscription
        will automatically renew for an additional Subscription Period unless
        you cancel (see “Cancellation and Termination” below).
      </p>

      <h3>12. Cancellation and Termination</h3>
      <p>
        You may cancel your monthly Subscription at any time, either from within
        your Account or by sending an email to info@eddiesedition.com, from the
        email address affiliated with your Account. Should you contact Customer
        Support to request assistance with Subscription Cancellation or account
        deletion, Eddie's Edition reserves the right to verify your identity by
        asking for the last four (4) digits of the credit card on file, the type
        of card, the billing address, and additional security questions. To
        cancel over the phone, you must verify the last four (4) digits of the
        credit card on file, the type of card, and the billing address, as well
        as answer a series of security questions. Changes to annual
        Subscriptions, including Cancellations, go into effect at the end of the
        annual Subscription Period. No refunds or credits shall be issued for
        annual Subscription Cancellations or downgrades, or from switching from
        an annual to a monthly Subscription before the end of the annual
        Subscription Period. Cancellations must be completed at least one (1)
        day prior to the end of your then-current Subscription Period, whether
        monthly or annual; otherwise, billing for the next month or year will be
        processed automatically. Cancellations performed after billing are not
        entitled to refunds, in whole or in part. You are responsible for all
        charges accrued on your Account up to the time of Cancellation,
        including all fees within the Subscription Period in which you cancel.
        Upon Subscription Cancellation, you are placed on a Free Account, which
        Eddie's Edition shall delete within twelve (12) months of the date of
        Cancellation, unless you re-Subscribe before the end of the 12 month
        period, or make other arrangements with us to retain your Account and
        its affiliated Personal Data. Account deletion permanently removes all
        pages, popups, sticky bars, Content, and leads, as well as your email
        address and other Personal Data. Eddie's Edition is not responsible for
        any Content lost as a result of Account deletion. We reserve the right
        to change our data storage and processing capabilities and procedures at
        any time, without notice or liability, subject to applicable laws.
        Eddie's Edition reserves the right to terminate this Agreement at any
        time, for any reason, and at our sole discretion, without liability. A
        reason for such termination may be, but is not limited to, failure to
        comply with these Terms. Eddie's Edition reserves the right to modify,
        suspend, or discontinue the Site and/or Services, or any portion
        thereof, at any time and for any reason, with or without notice. The
        sections “Submission of Content”, “Intellectual Property Rights”,
        “Confidential Information”, “Third Party Sites and Content”,
        “Disclaimer, Exclusion, and Limitation of Liability”, “Indemnification”,
        “Waiver of Jury Trial and Class Action Rights”, and “Dispute Resolution”
        will survive any actual or purported termination or expiry of this
        Agreement and continue in full force and effect.
      </p>

      <h3>
        13. Acceptable Use and Conduct
        <p>
          Eddie's Edition, and our hosting and data storage provider, place
          certain limitations on what can be advertised, promoted, and depicted
          on pages built in, uploaded to, or hosted by Eddie's Edition. These
          limitations are detailed in our Acceptable Use Policy (AUP). When you
          agree to these Terms, you are also agreeing to our AUP.
        </p>
      </h3>
      <h3>14. Submission of Content</h3>
      <p>
        You are able to add and upload Content in order to create and edit
        landing pages, popups, and sticky bars using the Services. You agree
        that you are solely responsible for all Content you submit, provide, or
        upload, including any legal or other consequences for submitting,
        providing, or uploading it. You grant Eddie's Edition a non-exclusive
        license to use the Content you upload in order to provide the Services
        to you, including, but not limited to, troubleshooting and publishing
        your pages. This use may include, without limitation, the right to host,
        index, cache, store, or otherwise format your Content. You further agree
        that Eddie's Edition may modify, add, and delete any Content added to
        the Services without liability, and at our sole discretion. Reasons for
        Content modification or deletion shall include, but are not limited to:
        malicious or damaging software or corrupted code within the Content,
        violations of intellectual property law, failure to adhere to our AUP,
        and for troubleshooting purposes. You represent to Eddie's Edition that
        you own any Content you upload, submit, or otherwise add to your
        Account, or that you have obtained any necessary licenses, rights,
        consents, and permissions to such Content and activity. As such, you
        agree that none of the aforementioned Content will in any way violate
        the copyrights, privacy rights, publicity rights, trademark rights,
        contract rights or any other intellectual property rights or other
        rights of any person, business, organization, or entity. You agree that
        Eddie's Edition is not responsible for any violations of Third Party
        intellectual property rights in any Content that you submit, upload, or
        otherwise add to Eddie's Edition. You further agree to pay all
        royalties, fees, and any other monies owed relating to the use of such
        Content. Eddie's Edition may request access to your Content to use for
        marketing purposes. You are under no obligation to grant this request,
        and we will not use your Content in any marketing materials without your
        prior written permission.
      </p>
      <h3>15. Monitoring</h3>
      <p>
        You agree that we may Monitor the Site and Services, including without
        limitation, any pages created using our Services. We may, where
        reasonable or necessary, disclose information to satisfy our legal
        obligations, protect Eddie's Edition or its Customers, or operate or
        improve the Site or Services. We reserve the right to Monitor the
        security and preserve the integrity of our Site, resources, data,
        Materials, customer content, and intellectual property (yours, ours, and
        Third Parties’). By using the Services, you agree that we may, with or
        without prior notice or notification, for any reason, and at our sole
        discretion, immediately suspend your access to the Services or Site,
        remove and/or request that you remove pages, popups, sticky bars, and
        Content, and/or terminate this Agreement. Reasons for removing Content
        or pages, popups, or sticky bars, or requesting their removal, may
        include, but are not limited to, security threats, intellectual property
        violations, attempts (whether or not successful) to access unauthorized
        data or other Material or Content, or the presence of Content, pages,
        popups, or sticky bars that in any way violate these Terms (including
        the Privacy Policy and AUP) or place Eddie's Edition, our Customers,
        business, affiliates, partners, or licensors at risk, as determined
        solely by us. Suspension of use and access is not a breach of this
        Agreement by Eddie's Edition, and your Account/Content/pages may be
        reinstated when the threat or risk has been remedied, at our sole
        discretion. Eddie's Edition disclaims all liability for suspending the
        Site or Services, removing or requesting the removal of Content, pages,
        popups, or sticky bars, or terminating this Agreement, and you have no
        claim to any form of compensation or damages arising from such action.
      </p>
      <h3>16. Aggregate Data</h3>
      <p>
        You agree that Eddie's Edition may gather and publish anonymous,
        Aggregate Data, based on your, your visitors’, and Your Customers’ use
        of the Site or Services. This data will not incorporate any Personal
        Information. Where you choose to use or connect Third Party services
        with your Account, you agree that Eddie's Edition may share your lead
        data with these designated Third Parties, without liability. You are
        fully responsible for the Personal Data you choose to collect, store,
        and share, and you agree to abide by all applicable laws within your
        jurisdiction. Eddie's Edition does not sell the lead data you collect
        with Eddie's Edition to Third Parties.
      </p>
      <h3>17. Beta Test</h3>
      <p>
        From time to time and at our sole discretion, we may initiate Beta
        Tests. We may implement, run, suspend, or terminate a Beta Test at any
        time without notice or liability, and do not guarantee that a tested
        product, service, modification, upgrade, or other adjustment to the
        Site, Services, or means of providing the Services, will become part of
        the Services. We may deliver (and subsequently cease delivering) a Beta
        Test to all or any subset of Eddie's Edition Account holders at our
        discretion without prior permission at any time. If we decide to
        incorporate a tested product or service into the Eddie's Edition
        platform, we may adjust our prices accordingly. Eddie's Edition accepts
        no liability for any consequences resulting from, or related to, your
        participation in a Beta Test.
      </p>
      <h3>18. Trial Program</h3>
      <p>
        From time to time and at our sole discretion, we may offer you the
        opportunity to try a new product or service for free for a limited time.
        Before the end of the Trial Program, you must decide whether you would
        like to continue using the new feature, should we choose to offer it.
        Whether we introduce a Trial Program feature into the Services
        generally, and if so, whether we charge an additional fee to access the
        feature, is at our sole discretion. If we charge a fee for its use and
        you purchase the trialed product or service, we may add it to your
        monthly invoice to be billed automatically until you choose to remove
        the Add-On or Cancel your Subscription. If you do not purchase the
        product or service by the end of the Trial Program, you will not be
        billed for it (normal Subscription rates will apply), and that feature
        will no longer be available to you. You agree to comply with any
        additional terms, restrictions, or limitations connected to any Trial
        Program. You may not sign up for multiple Accounts in order to receive
        additional Trial Program benefits. We may terminate or suspend a Trial
        Program at any time without notice or liability, and in our sole
        discretion, and we reserve the right to adjust pricing relative to any
        service or product offered through a Trial Program. We are not
        responsible or liable for any adverse impact caused by or connected with
        a Trial Program.
      </p>
      <h3>19. Commercial Platform</h3>
      <p>
        You have the option of using Eddie's Edition as a commercial platform,
        including, but not limited to to, incorporating links to purchasing
        pages and embedding Third Party payment processing tools. Eddie's
        Edition does not offer payment processing tools as part of the Services,
        and will not perform payment processing for Your Customers on your
        behalf. These transactions are entirely between you, Your Customers, and
        any other applicable Third Party, and we are not in any way liable or
        responsible for them. Third Party payment services that you choose to
        link to, embed, or otherwise incorporate into your Account are governed
        solely by the Third Party’s terms of use and privacy policies. We are
        not responsible for actions taken by any Third Party service provider,
        or for changes to their terms or policies. When using Eddie's Edition to
        offer or sell products or services, you agree to comply with the
        following: (a) You may not offer or sell illegal or potentially illegal
        Customer Goods, including, but not limited to, those that are
        counterfeited, stolen, or fraudulent. Customer Goods offered or sold
        using the Site and/or Services must comply with all applicable laws,
        including, without limitation, export control laws. (b) You may not
        offer or sell any Customer Goods that violate our AUP. This includes,
        but is not limited to, products that depict or promote child
        pornography, are obscene or defamatory, or that violate privacy rights
        or intellectual property law. (c) You may not use the images or names of
        any Third Party (including notable personalities or celebrities) when
        offering or selling Customer Goods without first obtaining that Third
        Party’s permission. Eddie's Edition may remove, or request that you
        remove, any pages, popups, sticky bars, or Content that violate this
        section or the AUP.
      </p>
      <h3>Collection of Personal Information</h3>
      <p>
        You agree not to disclose verbally, electronically, in writing, or in
        any other manner any Confidential Information that you have acquired or
        learned during the term of this Agreement or following the expiration or
        termination of this Agreement.
      </p>
      <h3>Confidential Information</h3>
      <p>
        You agree not to disclose verbally, electronically, in writing, or in
        any other manner any Confidential Information that you have acquired or
        learned during the term of this Agreement or following the expiration or
        termination of this Agreement.
      </p>
      <h3>Third Party Sites and Content</h3>
      <p>
        You are responsible for knowing and adhering to your jurisdictional
        anti-spam laws. In jurisdictions where consent is required before
        sending email or electronic transactions to email accounts, you are
        responsible for obtaining that consent. Certain features of the Site or
        Services, when activated, may require that Eddie's Edition send an email
        to you, Your Customers, or any other Third Party on your behalf. At
        present, these include the following features: “Email Me New Leads,”
        “Download a Page”, “Upload an Eddie's Edition Page”, “Generate Leads
        CSV”, “Get Traffic Usage Report”, and “Invite New User”. (These may be
        modified at any time, with or without notice or notification, and we
        reserve the right to add features that include email notification
        functionality.) Because you must enable or engage these features in
        order to use them, you are responsible for any communications that
        follow. You agree that Eddie's Edition remains a disinterested third
        party to any such electronic communication completed using the Site
        and/or Services. You hereby agree to indemnify us and hold us harmless
        for any failure to have or obtain all necessary consents regarding the
        receipt of electronic communications, email, or commercial electronic
        messages. You acknowledge and agree that Eddie's Edition is not
        responsible or liable for any Third Party content linked to from the
        Site or Services. This includes but is not limited to accuracy,
        integrity, quality, usefulness, legality, safety, and intellectual
        property rights. The inclusion of such linked content in no way
        indicates endorsement by, or association with, Eddie's Edition. You
        agree that Eddie's Edition is not responsible or liable for any alleged
        or real damages or losses incurred through the use of Third Party
        content, goods, or services. Your use of Third Party content is solely
        at your own risk.
      </p>
      <h3>Disclaimer, Exclusion, and Limitation of Liability</h3>
      <p>
        Eddie's Edition MAKES NO REPRESENTATIONS OR WARRANTIES ABOUT THE
        SUITABILITY, RELIABILITY, AVAILABILITY, TIMELINESS, SECURITY, OR
        ACCURACY OF THE SITE, SERVICES, OR DATA MADE AVAILABLE FROM THE
        SERVICES. YOUR USE OF THE SITE OR SERVICES, AND ALL CONTENT, MATERIAL,
        AND THIRD PARTY SOFTWARE AND CONTENT ARE AT YOUR SOLE RESPONSIBILITY AND
        RISK. THE SITE AND SERVICES ARE PROVIDED ON AN “AS IS” AND “AS
        AVAILABLE” BASIS. Eddie's Edition DISCLAIMS ANY WARRANTY THAT THE SITE,
        THE SERVICES, OR ANY CONTENT, INCLUDING, WITHOUT LIMITATION, ANY THIRD
        PARTY SOFTWARE AND CONTENT, WILL MEET YOUR REQUIREMENTS OR BE
        UNINTERRUPTED, TIMELY, SECURE, OR ERROR-FREE, THAT DEFECTS WILL BE
        CORRECTED, OR THAT THE SITE, SERVICES, AND SERVERS ARE FREE OF VIRUSES
        OR OTHER HARMFUL COMPONENTS. YOU AGREE THAT FROM TIME TO TIME Eddie's
        Edition MAY REMOVE THE SITE OR CEASE PROVIDING THE SERVICES FOR
        INDEFINITE PERIODS OF TIME WITHOUT PRIOR NOTICE OR NOTIFICATION. YOUR
        ACCESS TO AND USE OF THE SITE AND SERVICES MAY BE INTERRUPTED OR
        SUSPENDED FROM TIME TO TIME FOR ANY REASON, INCLUDING, BUT NOT LIMITED
        TO, EQUIPMENT MALFUNCTIONS, PERIODIC UPDATING, MAINTENANCE OR REPAIR OF
        THE SITE OR SERVICES, OR OTHER ACTIONS THAT Eddie's Edition, IN ITS SOLE
        DISCRETION, MAY ELECT TO TAKE. YOU ACKNOWLEDGE AND AGREE THAT YOU ARE
        NOT ENTITLED TO DAMAGES OR ANY OTHER FORM OF COMPENSATION OR RELIEF WHEN
        OUTAGES, DELAYS, DOWNTIME, MALFUNCTIONS, SECURITY OR SYSTEM BREACHES, OR
        OTHER INTERRUPTIONS OF SERVICE OCCUR. Eddie's Edition MAKES NO GUARANTEE
        REGARDING: (A) THE NUMBER OF VISITORS, VIEWS, OR CONVERSIONS ON ANY OF
        YOUR PAGES, POPUPS, OR STICKY BARS; OR (B) THE COMPATIBILITY OF ANY
        SOFTWARE, HARDWARE, OR CONTENT WITH THE SITE OR SERVICES. YOU ARE NOT
        ENTITLED TO COMPENSATION, REFUNDS, CREDITS, DAMAGES OR ANY FORM OF
        RELIEF SHOULD THE SITE OR SERVICES NOT MEET YOUR EXPECTATIONS. YOU ARE
        SOLELY RESPONSIBLE FOR ENSURING THAT YOUR CONTENT IS COMPATIBLE WITH THE
        SITE AND/OR SERVICES. Eddie's Edition DISCLAIMS ANY LIABILITY OR
        RESPONSIBILITY FOR ANY UNAUTHORIZED USE OF YOUR CONTENT BY THIRD PARTIES
        OR OTHER USERS OF THE SITE OR SERVICES, AND IS NOT RESPONSIBLE FOR
        PROTECTING YOUR CONTENT. Eddie's Edition IS NOT RESPONSIBLE FOR THE
        ACTS, OMISSIONS, OR FAILURES OF ANY THIRD-PARTY CONTENT, SERVICE,
        NETWORK, OR SOFTWARE OR HARDWARE PROVIDER, INCLUDING BUT NOT LIMITED TO,
        INTERNET SERVICE PROVIDERS, HOSTING SERVICES USED BY Eddie's Edition,
        TELECOMMUNICATIONS PROVIDERS, CONTENT PROVIDED BY OTHER USERS, OR ANY
        SOFTWARE OR HARDWARE NOT PROVIDED BY Eddie's Edition. ANY MATERIAL
        DOWNLOADED OR OTHERWISE OBTAINED THROUGH THE USE OF THE SITE OR
        SERVICES, INCLUDING, BUT NOT LIMITED TO, LANDING PAGES, POPUPS, STICKY
        BARS, AND LEADS CSV FILES, IS DONE AT YOUR OWN DISCRETION AND RISK. YOU
        AGREE THAT YOU, SOLELY, ARE RESPONSIBLE FOR ANY DAMAGE TO YOUR COMPUTER
        SYSTEM OR OTHER DEVICE, AND/OR FOR ANY LOSS OF DATA THAT MAY RESULT FROM
        THE DOWNLOAD OF ANY SUCH MATERIAL. NO ADVICE OR INFORMATION, WHETHER
        COMMUNICATED ORALLY OR IN WRITING, FROM Eddie's Edition EMPLOYEES, OR
        VIA THE SITE OR SERVICES, SHALL CREATE ANY WARRANTY NOT EXPRESSLY STATED
        IN THIS AGREEMENT. THE SITE AND SERVICES ARE OFFERED AND CONTROLLED BY
        Eddie's Edition FROM ITS FACILITIES IN CANADA. Eddie's Edition MAKES NO
        REPRESENTATIONS THAT THE SITE OR SERVICES ARE APPROPRIATE OR AVAILABLE
        FOR USE IN OTHER LOCATIONS. THOSE WHO ACCESS OR USE THE SITE OR SERVICES
        FROM OTHER JURISDICTIONS DO SO AT THEIR OWN RISK AND ARE RESPONSIBLE FOR
        COMPLIANCE WITH LOCAL LAW. YOU AGREE THAT NEITHER Eddie's Edition NOR
        ITS OWNERS, OFFICERS, DIRECTORS, AFFILIATES, CONTRACTORS, LICENSORS,
        EMPLOYEES OR AGENTS, WILL BE HELD LIABLE TO YOU OR ANY THIRD PARTY
        ACTING ON YOUR BEHALF, FOR ANY TANGIBLE OR INTANGIBLE DAMAGES OR LOSSES
        ARISING FROM OR RELATING TO: THIS AGREEMENT, YOUR USE OF OR INABILITY TO
        USE THE SITE OR SERVICES, OR YOUR USE OF THIRD PARTY MATERIALS, CONTENT,
        OR SERVICES. Eddie's Edition WILL NOT BE HELD RESPONSIBLE FOR ANY
        DAMAGES, LIABILITIES, LOSSES, OR OTHER CONSEQUENCES THAT YOU MAY INCUR
        IN THE EVENT THAT THE SITE AND/OR SERVICES ARE MODIFIED, SUSPENDED OR
        DISCONTINUED. IN NO EVENT SHALL Eddie's Edition’S AND ITS AFFILIATES’,
        REPRESENTATIVES’, OFFICERS’, DIRECTORS’, STOCKHOLDERS’, EMPLOYEES’, OR
        AGENTS’ AGGREGATE LIABILITY FOR ALL CLAIMS, WHETHER IN CONTRACT, TORT,
        OR ANY OTHER THEORY OF LIABILITY, EXCEED THE COST OF CUSTOMER’S
        SUBSCRIPTION.
      </p>
      <h3>Indemnification</h3>
      <p>
        You agree to indemnify, defend, and hold harmless the Indemnified
        Parties, from and against any Third Party claim, demand, loss, damage,
        cost, or liability (including, reasonable attorneys’ fees) (collectively
        and individually, “Claims”) incurred by or made against the Indemnified
        Parties in connection with any Claims arising out of or relating to this
        Agreement, the Site or the Services, including but without limitation in
        relation to: (a) your use, non-use or misuse of, or connection to the
        Site, the Services, Consumer Goods and any Content, including without
        limitation your Profile Information and any third party Content forming
        part of the Site; (b) your violation or alleged violation of this
        Agreement; and (c) your violation of any rights, including intellectual
        property rights, of a third party and otherwise as set out herein.
        Eddie's Edition reserves the right, at your expense, to assume the
        exclusive defense and control of any matter for which you are required
        to indemnify Eddie's Edition and you agree to cooperate with Eddie's
        Edition’s defense of these Claims. You agree not to settle any matter
        without the prior written consent of Eddie's Edition. Eddie's Edition
        will use reasonable efforts to notify you of any such Claims upon
        becoming aware of it.
      </p>
      <h3>Waiver of Jury Trial and Class Action Rights</h3>
      <p>
        IN ANY DISPUTE ARISING OUT OF OR RELATED TO THE SITE, THE SERVICES, A
        SUBSCRIPTION AND/OR THIS AGREEMENT, YOU HEREBY EXPRESSLY GIVE UP: (I)
        YOUR RIGHT TO A TRIAL BY JURY; AND (II) YOUR RIGHT TO PARTICIPATE AS A
        MEMBER OF A CLASS OF CLAIMANTS, IN ANY LAWSUIT INCLUDING, WITHOUT
        LIMITATION, CLASS ACTION LAWSUITS.
      </p>
      <h3>Dispute Resolution</h3>
      <p>
        In any dispute between you and Eddie's Edition relating to this
        Agreement, the Site, or the Services, you agree that the dispute shall
        be governed exclusively by the laws of the Province of British Columbia,
        Canada, without regard to its conflict of law provisions. Except for any
        claim involving the ownership of intellectual property, all disputes
        arising out of or in connection with this Agreement will be referred to
        and resolved by arbitration. The appointing authority will be the
        British Columbia International Commercial Arbitration Centre. The case
        will be adjudicated by a single arbitrator and will be administered by
        the British Columbia International Commercial Arbitration Centre in
        accordance with its rules. The place of arbitration will be Vancouver,
        British Columbia, Canada, and the language of arbitration will be
        English. Any claim, proceeding, or action that arises under this
        Agreement involving the ownership of intellectual property shall submit
        to the exclusive jurisdiction of the courts in Vancouver, British
        Columbia. However, Eddie's Edition may seek and obtain injunctive relief
        in any jurisdiction. You agree that Eddie's Edition may enforce this
        Agreement through injunctive relief and other equitable remedies,
        without proof of monetary damages. You agree that you will not bring a
        claim under or relating to this Agreement more than twelve (12) months
        from when your claim first arose.
      </p>
      <h3>Severability</h3>
      <p>
        If any portion of this Agreement is deemed unlawful, void, or
        unenforceable by any arbitrator or court of competent jurisdiction, such
        decision will not invalidate the Agreement as a whole. Only that portion
        that is unlawful, void, or unenforceable will be stricken from this
        Agreement.
      </p>
      <h3>Waiver of Rights</h3>
      <p>
        You agree that if Eddie's Edition does not exercise or enforce a legal
        right or remedy contained in the Agreement or under applicable law, this
        is not a waiver of Eddie's Edition’s rights. Those rights or remedies
        will still be available to Eddie's Edition should we choose to exercise
        them.
      </p>
      <h3>Miscellaneous</h3>
      <p>
        The United Nations Convention on Contracts for the International Sale of
        Goods does not apply to this Agreement. This Agreement replaces and
        supersedes any other prior or contemporaneous agreement, representation,
        or discussion, oral or written, and may not be changed except in
        writing, and signed by us.
      </p>
      <h3>Contacting Eddie's Edition</h3>
      <p>
        You may contact Eddie's Edition by email at info@eddiesedition.com, by
        telephone at 1-213-800-1470, or by mail at Eddie's Edition Inc., 914 e
        union st, Pasadena, CA 91106
      </p>
    </div>
  );
};

export default Terms;
