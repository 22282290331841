import dayjs from "dayjs";
import { DATE_FORMAT, STANDARD_DATE_TIME_FORMAT, WENT_WRONG } from "../consts";
import { SERVICES_CODES_FOR_CONSULTATION } from "../api/consts";

export const convertDay = (index: number) => {
  switch (index) {
    case 0:
      return "Sun";
    case 1:
      return "Mon";
    case 2:
      return "Tue";
    case 3:
      return "Wed";
    case 4:
      return "Thur";
    case 5:
      return "Fri";
    case 6:
      return "Sat";
  }
};

export const sortTimes = (arrayOfTimes: string[]) => {
  return arrayOfTimes.sort((a, b) => {
    const aParts = getNumericParts(a);
    const bParts = getNumericParts(b);

    // Sorts by hour then minute
    return aParts[0] - bParts[0] || aParts[1] - bParts[1];
  });

  function getNumericParts(time: string) {
    // accounts formats of 9:15 AM and 09:15:30 but does not handle AM/PM in comparison
    return time
      .split(" ")[0]
      .split(":")
      .map((x) => +x);
  }
};

export const sortTimesInObj = (arrayOfTimes: any[]) => {
  return arrayOfTimes.sort((a, b) => {
    const aParts = getNumericParts(a.time);
    const bParts = getNumericParts(b.time);
    // Sorts by hour then minute
    return aParts[0] - bParts[0] || aParts[1] - bParts[1];
  });
  function getNumericParts(time: string) {
    // accounts formats of 9:15 AM and 09:15:30 but does not handle AM/PM in comparison
    return time
      .split(" ")[0]
      .split(":")
      .map((x) => +x);
  }
};

// Return (xxx) xxx-xxxx format
export function formatPhoneNumber(phoneNumberString: string) {
  var cleaned = ("" + phoneNumberString).replace(/\D/g, "");
  var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return ["(", match[2], ") ", match[3], "-", match[4]].join("");
  }
  return null;
}

// Remove the past time slots from given time slots
export function removePastTimeSlots(timeSlots: string[], date: string) {
  const now = dayjs();
  return timeSlots.filter((time) => {
    const timeMoment = dayjs(`${date} ${time}`);
    return timeMoment.isAfter(now);
  });
}

export const formatDatetime = (date: string, time: string) => {
  return dayjs(`${date} ${time}`).format(STANDARD_DATE_TIME_FORMAT);
};

/*

  * Converts a date in the format YYYY-MM-DD
*/
export const formatDate = (date: Date) => {
  return dayjs(date).format(DATE_FORMAT);
};

/*
 * Converts a time string in the format "H:mm" to h:mm A
 */
export const formatTime = (time: string) => {
  const [hours, minutes] = time.split(":").map(Number);

  // Create a dayjs object using today's date and the provided hours and minutes
  return dayjs()
    .hour(hours)
    .minute(minutes)
    .second(0)
    .millisecond(0)
    .format("h:mm A");
};

export const checkIfConsultationRequired = (serviceCode: number) => {
  return SERVICES_CODES_FOR_CONSULTATION.includes(serviceCode);
};

export const extractErrorMessage = (error: any) => {
  if (error && typeof error.message === "string") {
    try {
      const errorMessageJSON = JSON.parse(error.message);
      if (errorMessageJSON && errorMessageJSON.message) {
        return errorMessageJSON.message;
      }
    } catch (parsingError) {
      console.error("Error parsing the error message:", parsingError);
      return WENT_WRONG;
    }
  }

  // Default error message
  return WENT_WRONG;
};

/*
 * Returns an array of dates between the start and end dates
 */
export const getDatesBetween = (startDate: string, endDate: string) => {
  const dates = [];
  let currentDate = dayjs(startDate, "YYYY-MM-DD");
  const end = dayjs(endDate, "YYYY-MM-DD");

  while (currentDate.isBefore(end) || currentDate.isSame(end)) {
    dates.push(currentDate.toDate());
    currentDate = currentDate.add(1, "day");
  }

  return dates;
};

export function stripHtml(html: string) {
    // Create a new div element
    const doc = new DOMParser().parseFromString(html, 'text/html');
    // Return the text content, effectively stripping out the HTML
    return doc.body.textContent || "";
}