import React from "react";
import { Route, Routes } from "react-router-dom";

import BlogList from "./List";
import BlogPage from "./Page";

const Blog = () => {
  return (
    <Routes>
      <Route path={"/"} element={<BlogList />} />
      <Route path={"/:path"} element={<BlogPage />} />
    </Routes>
  );
};

export default Blog;
