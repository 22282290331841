import { Button, Form, message } from "antd";
import React from "react";
import TextArea from "antd/es/input/TextArea";
import { EditionJson } from "../../api";
import { extractErrorMessage } from "../../helpers";

interface IMessageStylistProps {
  stylistName: string;
  appointmentId: string;
  onClose: () => void;
}
const MessageStylist = ({ appointmentId, onClose }: IMessageStylistProps) => {
  const [val, setVal] = React.useState("");
  const [isLoading, setLoading] = React.useState(false);
  const onSendMessage = async () => {
    setLoading(true);
    try {
      await EditionJson.sendMessageForAppointment(appointmentId, val);
      message.success("Message sent");
      onClose();
    } catch (e: any) {
      message.error(extractErrorMessage(e));
    }
    setLoading(false);
  };
  return (
    <Form layout="vertical">
      <Form.Item label="Message to Stylist" name="message">
        <TextArea
          placeholder="Hello"
          name="message"
          value={val}
          onChange={(e) => setVal(e.target.value)}
          rows={4}
        />
      </Form.Item>
      <Button
        disabled={val.length === 0}
        onClick={onSendMessage}
        color="black"
        type="primary"
        loading={isLoading}
      >
        Send
      </Button>
    </Form>
  );
};

export default MessageStylist;
