import React from "react";
import { Link } from "react-router-dom";

const Banner = () => {
  return (
    <div
      style={{
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "none",
        backgroundImage:
          "url(https://imagedelivery.net/ZrdAKG8zF6UYv_PggC8zbw/44b4f039-54ab-4fdb-270d-6c290dc72700/public)",
      }}
      className={"hero is-dark is-large is-relative"}
    >
      <div className="hero-body">
        <div className="container has-text-centered">
          <h1 className="title has-text-white is-size-4-mobile is-spaced">
            Good Haircuts Only
          </h1>
          <div
            style={{
              margin: "auto",
              maxWidth: "550px",
              marginBottom: "1.5rem",
            }}
          >
            <div className="columns is-multiline">
              <div className="column is-3">
                <Link
                  to="/appointment/dtla"
                  className="button is-white is-fullwidth"
                >
                  Arts District
                </Link>
              </div>
              <div className="column is-3">
                <Link
                  to="/appointment/mid-wilshire"
                  className="button is-white is-fullwidth"
                >
                  Mid Wilshire
                </Link>
              </div>
              <div className="column is-3">
                <Link
                  to="/appointment/pasadena"
                  className="button is-white is-fullwidth"
                >
                  Pasadena
                </Link>
              </div>
              <div className="column is-3">
                <Link
                  to="/appointment/san-gabriel"
                  className="button is-white is-fullwidth"
                >
                  San Gabriel
                </Link>
              </div>
            </div>
          </div>
          <p>Book Your Appointment Now</p>
        </div>
      </div>
    </div>
  );
};

export default Banner;
