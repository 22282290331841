import { EventActionTypes, ActionMap } from "../../types";
import { PlaceAction } from "./place";

type Payload = {
  [EventActionTypes.OffMaker]: undefined;
  [EventActionTypes.OnMaker]: {
    id: string;
  };
  [EventActionTypes.OffCard]: undefined;
  [EventActionTypes.OnCard]: {
    id: string;
  };
};

export interface IEventInitialState {
  idOnMarker: string;
  idOnCard: string;
}

export type EventAction = ActionMap<Payload>[keyof ActionMap<Payload>];

export const eventReducer = (
  state: IEventInitialState,
  action: EventAction | PlaceAction
) => {
  switch (action.type) {
    case EventActionTypes.OffMaker:
      return {
        ...state,
        idOnMarker: "",
      };
    case EventActionTypes.OnMaker:
      return {
        ...state,
        idOnMarker: action.payload.id,
      };
    case EventActionTypes.OffCard:
      return {
        ...state,
        idOnCard: "",
      };
    case EventActionTypes.OnCard:
      return {
        ...state,
        idOnCard: action.payload.id,
      };
    default:
      return state;
  }
};
