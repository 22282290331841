import React from "react";
import { BrowserRouter } from "react-router-dom";
import Header from "./components/Header";
import AppRoutes from "./routes";
import NewAppointmentModal from "./scenes/NewAppointmentModal";
import { ConfigProvider } from "antd";
import { AppContextProvider } from "./hooks/useAppointment";

const App = () => {
  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: "#rgba(0, 0, 0, 1)",
          colorLink: "#rgba(0, 0, 0, 1)",
        },
        components: {
          Menu: {
            itemPaddingInline: 0,
            activeBarBorderWidth: 0,
            itemSelectedBg: "white",
          },
        },
      }}
    >
      <BrowserRouter>
        <AppContextProvider>
          <Header />
          <AppRoutes />
          <NewAppointmentModal />
        </AppContextProvider>
      </BrowserRouter>
    </ConfigProvider>
  );
};

export default App;
