import { Typography } from "antd";
import React from "react";

interface IDiscountCardProps {
  data: {
    title: string;
    description: string;
    discountAmount: number;
    startDate: string;
    endDate: string;
    hasDate: boolean;
  };
}
const DiscountCard = ({ data }: IDiscountCardProps) => {
  return (
    <Typography>
      <Typography.Title level={5}>{data.title}</Typography.Title>
      <Typography.Paragraph>{data.description}</Typography.Paragraph>
      <Typography.Paragraph>
        <Typography.Text strong>Discount: </Typography.Text> -$
        <Typography.Text>{data.discountAmount}</Typography.Text>
      </Typography.Paragraph>
      {data.hasDate && (
        <Typography.Paragraph>
          <Typography.Text strong>Date: </Typography.Text>
          <Typography.Text>{`${data.startDate} ~ ${data.endDate}`}</Typography.Text>
        </Typography.Paragraph>
      )}
    </Typography>
  );
};

export default DiscountCard;
