import React, { useState } from "react";
import { ChangeEventValue } from "google-map-react";
import { isMobile } from "react-device-detect";

import Marker from "./components/GoogleMap/components/marker";
import GoogleMap from "./components/GoogleMap";
import PlaceCard from "./components/PlaceCard";
import Filter from "./components/Filter";

import { BrowseProvider } from "./contexts";

import { DEFAULT_GEO } from "./consts";

import { MetaTagWrapper } from "../../../components/MetaTags";
import { ILocationModel } from "../../../models";
import { EditionJson } from "../../../api";
import { formatDate } from "../../../helpers";
import { WENT_WRONG } from "../../../consts";
import { useApp } from "../../../hooks/useAppointment";

const BrowseSalon = () => {
  const [places, setPlaces] = useState<ILocationModel[]>([]);
  const [isLoading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [isMapView, setMapView] = useState(false);
  const { date, setDate } = useApp();
  const dateString = formatDate(date);

  const onBoundChange = async ({ center, zoom, bounds }: ChangeEventValue) => {
    const query = `?sw=${bounds.sw.lat},${bounds.sw.lng}&se=${bounds.se.lat},${bounds.se.lng}&nw=${bounds.nw.lat},${bounds.nw.lng}&ne=${bounds.ne.lat},${bounds.ne.lng}`;
    setLoading(true);
    try {
      const res = await EditionJson.getLocations(query);
      setPlaces(res);
    } catch (e: any) {
      setError(e.message);
    }
    setLoading(false);
  };
  return (
    <BrowseProvider>
      <div id="Browse" className={isMapView ? "is-mapview" : "is-listview"}>
        <MetaTagWrapper
          title={`Locations | Eddie's Edition`}
          description={`Discover Eddie's Edition, located in the heart of Pasadena, San Gabriel, and Arts District LA.`}
          type="website"
          url={`https://www.eddiesedition.com/appointment`}
          canonical={true}
        />
        <Filter
          isMapView={isMapView}
          onToggle={() => setMapView(!isMapView)}
          date={date}
          setDate={setDate}
        />
        <div id="Map_Container">
          <GoogleMap
            defaultZoom={
              DEFAULT_GEO.los_angeles[isMobile ? "mobile" : "desktop"].zoom
            }
            defaultCenter={
              DEFAULT_GEO.los_angeles[isMobile ? "mobile" : "desktop"]
                .coordinates
            }
            yesIWantToUseGoogleMapApiInternals
            onChange={onBoundChange}
            resetBoundsOnResize={true}
            isLoading={isLoading}
            // onGoogleApiLoaded={({ map, maps }: any) => {
            //   apiIsLoaded(map, maps, places);
            // }}
          >
            {places.length > 0 &&
              places.map((place) => (
                <Marker
                  key={place.pathId}
                  lng={place.lng}
                  lat={place.lat}
                  place={place}
                />
              ))}
          </GoogleMap>
        </div>
        <div id="Content_Container">
          {error && (
            <div className="notification is-danger">{WENT_WRONG}</div>
          )}

          {places.map((place, i) => (
            <PlaceCard
              date={dateString}
              key={`place-${i}`}
              name={place.name}
              pathId={place.pathId}
              image={place.thumbnail}
              address={`${place.address}, ${place.city}, ${place.state} ${place.zipcode}`}
            />
          ))}
        </div>
      </div>
    </BrowseProvider>
  );
};

export default BrowseSalon;
