import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { IBlog } from "../../models";
import { EditionJson } from "../../api";
import { extractErrorMessage, stripHtml } from "../../helpers";
import { WENT_WRONG } from "../../consts";
import { helmetJsonLdProp } from "react-schemaorg";
import { Article } from "schema-dts";
import { MetaTagWrapper } from "../../components/MetaTags";
import { Typography } from "antd";

const BlogPage = () => {
  const { path } = useParams();
  const [article, setArticle] = useState<IBlog | null>(null);
  const [isLoading, setLoading] = useState(true);
  const [error, setError] = useState("");

  useEffect(() => {
    const fetchArticle = async (_path: string) => {
      try {
        const res = await EditionJson.getBlogArticle(_path);
        setArticle(res);
      } catch (error) {
        setError(extractErrorMessage(error));
        console.error(error);
      } finally {
        setLoading(false);
      }
    };

    if (path) {
      fetchArticle(path);
    } else {
      setLoading(false);
    }
  }, [path]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{WENT_WRONG}</div>;
  }

  if (!article) {
    return <div>Article not found.</div>;
  }

  const articleMetaData = helmetJsonLdProp<Article>({
    "@context": "https://schema.org",
    "@type": "Article",
    headline: article.title,
    description: article.content,
    image: article.coverImage,
    genre: "Beauty",
    publisher: {
      "@type": "Organization",
      name: "Eddie's Edition",
      logo: {
        "@type": "ImageObject",
        url: "https://eddiesedition.s3-us-west-2.amazonaws.com/images/logos/logo.png",
      },
    },
    mainEntityOfPage: {
      "@type": "WebPage",
      "@id": "https://www.eddiesedition.com/blog",
    },
  });

  return (
    <>
      <MetaTagWrapper
        type="article"
        description={stripHtml(article.content)}
        image={article.coverImage}
        title={article.title}
        canonical={true}
        url={`https://www.eddiesedition.com/blog/${article.path}`}
        scripts={[articleMetaData]}
      />
      <div className="content-wrapper">
        <section className="section has-thin-top-padding">
          <div className="columns">
            <div className="column is-half is-offset-one-quarter">
              <Typography.Title level={3}>{article.title}</Typography.Title>
              <div
                className="ql-editor"
                dangerouslySetInnerHTML={{ __html: article.content }}
              />
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default BlogPage;
