export interface Product {
  id: string;
  title: string;
  type: string;
  price: number;
  description: string;
  image: string;
  link: string;
}
export const products: Product[] = [
  {
    id: "milbon-wax-3",
    title: "Milbon molding wax 3",
    type: "styling",
    price: 28,
    description: "A wax that provides a firm hold and a natural finish.",
    image:
      "https://mfr.ibeauthentic.com/images/products/milbon/milbon_styling_moldingwax3_070121.jpg?preset=t600",
    link: "https://shop.saloninteractive.com/store/EddiesEdition/product/12973/16434",
  },
  {
    id: "milbon-wax-5",
    title: "Milbon molding wax 5",
    type: "styling",
    price: 28,
    description:
      "Exceptional spreadability means effortless application and enhanced control. A cutting-edge formulation that generates a breakthrough creamy texture, this Wax's light, reworkable finish allows for multiple styles throughout the day and still rinses clean.",
    image:
      "https://mfr.ibeauthentic.com/images/products/milbon/milbon_styling_moldingwax5_070121.jpg?preset=t600",
    link: "https://shop.saloninteractive.com/store/EddiesEdition/product/12974/16435",
  },
  {
    id: "milbon-wax-7",
    title: "Milbon molding wax 7",
    type: "styling",
    price: 28,
    description:
      "Exceptional spreadability means effortless application and enhanced control. A cutting-edge formulation that generates a breakthrough creamy texture, this Wax's light, reworkable finish allows for multiple styles throughout the day and still rinses clean.",
    image:
      "https://mfr.ibeauthentic.com/images/products/milbon/milbon_styling_moldingwax7_070121.jpg?preset=t600",
    link: "https://shop.saloninteractive.com/store/EddiesEdition/product/12975/16436",
  },
  {
    id: "milbon-paste-8",
    title: "Matte Texturizing Paste 8",
    type: "styling",
    price: 28,
    description:
      "Milbon Signature CREATIVE STYLE Matte Texturizing Paste 8 instantly creates a matte texture with a strong hold.",
    image:
      "https://mfr.ibeauthentic.com/images/products/milbon/milbonsigniturecreativestylemattetexturizingpasteeight2oz.jpg?preset=t600",
    link: "https://shop.saloninteractive.com/store/EddiesEdition/product/66804/70333",
  },
];
