import React from "react";
import AppContentWrapper from "../../components/AppContentWrapper";
import { MetaTagWrapper } from "../../components/MetaTags";
import { Link } from "react-router-dom";
import { Button, Divider, Typography } from "antd";
import { CalendarOutlined } from "@ant-design/icons";
const { Title, Paragraph, Text } = Typography;

const Services = () => {
  return (
    <>
      <AppContentWrapper>
        <MetaTagWrapper
          title="Services | Eddie's Edition"
          description="Where men's hair meets style - our team of experienced hair stylists
          brings a blend of barbershop and beauty salon techniques, each
          offering a unique take on the latest haircuts."
          type="website"
          url="https://www.eddiesedition.com/services"
        />

        <Typography>
          <Title level={3}>Services</Title>
          <Paragraph>
            <i>
              <small className="is-underlined">Prices vary by stylist</small>
            </i>
          </Paragraph>

          <Paragraph>
            <Text strong>Men's Haircut</Text> &middot; &middot; &middot; $45 +
            <br />
            Fade to the barbershop beat, styled with beauty salon flair.
          </Paragraph>

          <Paragraph>
            <Text strong>Women's Haircut</Text> &middot; &middot; &middot; $65 +
          </Paragraph>

          <Paragraph>
            <Text strong>Men's Perm</Text> &middot; &middot; &middot; $150 +
            <br />
            Expertly crafted using Korean techniques. Whether you seek subtle
            waves or pronounced curls, our service ensures a personalized touch,
            complete with a precision haircut.
            {/* <br />
            <Text italic>
              <Text>Specialists: </Text>
              <Link className="has-text-dark" to="/stylists/celine">
                Celine
              </Link>
              ,{" "}
              <Link className="has-text-dark" to="/stylists/jane">
                Jane
              </Link>
              ,{" "}
              <Link className="has-text-dark" to="/stylists/connie">
                Connie
              </Link>
              ,{" "}
              <Link className="has-text-dark" to="/stylists/gina">
                Gina
              </Link>
              ,{" "}
              <Link className="has-text-dark" to="/stylists/yumi">
                Yumi
              </Link>
              ,{" "}
              <Link className="has-text-dark" to="/stylists/don">
                Don
              </Link>
              ,{" "}
              <Link className="has-text-dark" to="/stylists/danny">
                Danny
              </Link>
            </Text> */}
          </Paragraph>

          <Paragraph>
            <Text strong>Color</Text>
            <br />
            From highlights to full-color transformations, our expert stylists
            will achieve your desired hue. Consultation required.
            {/* <br />
            <Text italic>
              Specialists:{" "}
              <Link className="has-text-dark" to="/stylists/kim">
                Kim
              </Link>
              ,{" "}
              <Link className="has-text-dark" to="/stylists/kristy">
                Kristy
              </Link>
              ,{" "}
              <Link className="has-text-dark" to="/stylists/don">
                Don
              </Link>
              ,{" "}
              <Link className="has-text-dark" to="/stylists/melvin">
                Melvin
              </Link> */}
            {/* </Text> */}
          </Paragraph>

          <Paragraph>
            <Text strong>Kid's Haircut</Text> &middot; &middot; &middot; $40 +
          </Paragraph>
        </Typography>
        <Divider />
        {/* <Button icon={<CalendarOutlined />}> */}
        <Link to={"/appointment"}>
          <Button icon={<CalendarOutlined />}> Make an Appointment </Button>
        </Link>
        {/* </Button> */}
      </AppContentWrapper>
    </>
  );
};

export default Services;
