import React from "react";
import { BrowseContext } from "../../../../contexts";
import { EventActionTypes, PlaceActionTypes } from "../../../../types";
import { ILocationModel } from "../../../../../../../models";
import { useNavigate } from "react-router-dom";

interface MarkerProps {
  lat: number;
  lng: number;
  place: ILocationModel;
}
const Marker = ({ place }: MarkerProps) => {
  const { state, dispatch } = React.useContext(BrowseContext);
  const navigate = useNavigate();
  const isHovered =
    place.pathId === state.events.idOnCard ||
    place.pathId === state.events.idOnMarker;

  const onHover = () => {
    dispatch({
      type: EventActionTypes.OnMaker,
      payload: { id: place.pathId },
    });
  };
  const offHover = () => {
    dispatch({
      type: EventActionTypes.OffMaker,
    });
  };
  return (
    <div
      onClick={() => {
        // dispatch({
        //   type: PlaceActionTypes.onClick,
        //   payload: place,
        // });
        navigate(`/appointment/${place.pathId}`)
      }}
      className={`map-pin ${isHovered ? "is-hovered" : ""}`}
      onMouseEnter={onHover}
      onMouseLeave={offHover}
    />
  );
};

export default Marker;
