import { Button, Typography } from "antd";
import React, { useEffect } from "react";

interface IProps {
  onReset: () => void;
}
const AppointmentResult = ({ onReset }: IProps) => {
  useEffect(() => {
    const timer = setTimeout(() => {
      onReset();
    }, 10000);

    // Cleanup function to clear the timer when component is unmounted
    return () => clearTimeout(timer);
  }, [onReset]);

  return (
    <>
      <Typography.Title level={4}>Thank you!</Typography.Title>
      <Typography.Paragraph className="is-size-4">
        At Eddie's Edition, we operate on an appointment basis, ensuring our
        stylists dedicate their time exclusively to you. If you need to cancel
        or reschedule, please do so at least 12 hours in advance. This allows us
        to manage our schedules effectively. Thank you for your understanding
        and cooperation.
      </Typography.Paragraph>
      <Button size="large" onClick={onReset} type="primary">
        All done!
      </Button>
    </>
  );
};

export default AppointmentResult;
