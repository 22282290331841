import { Button, Divider, List, Typography } from "antd";
import React from "react";
import Level from "../../../../../components/Level";
import { IServiceModel } from "../../../../../models";
import { IStylistService } from "../..";
import { ApppointmentServiceType } from "../../../../../hooks/useAppointment";

interface ICombineServicesProps {
  services: IServiceModel[];
  selectedServices: IStylistService[];
  onSubmit: (services: ApppointmentServiceType[]) => void;
}
const CombineServices = (props: ICombineServicesProps) => {
  const [selectedServices, setSelectedServices] = React.useState(
    props.selectedServices
  );
  const onAddService = (service: IStylistService) => {
    setSelectedServices([...selectedServices, service]);
  };
  const onRemoveService = (service: IStylistService) => {
    const indexToRemove = selectedServices.findIndex(
      (selectedService) => selectedService.category === service.category
    );

    if (indexToRemove !== -1) {
      const updatedServices = [...selectedServices];
      updatedServices.splice(indexToRemove, 1);
      setSelectedServices(updatedServices);
    }
  };

  let totalDuration = 0;
  let totalPrices = 0;

  selectedServices.forEach((service) => {
    totalDuration += service.duration;
    totalPrices += service.price;
  });
  return (
    <>
      <List
        itemLayout="horizontal"
        dataSource={props.services}
        renderItem={(item) => {
          let cnt = 0;
          selectedServices.forEach((service) => {
            if (service.category === item.category) cnt++;
          });
          return (
            <List.Item
              actions={[
                <Button onClick={() => onAddService(item)} size="small">
                  +
                </Button>,
                <Button onClick={() => onRemoveService(item)} size="small">
                  -
                </Button>,
              ]}
            >
              <List.Item.Meta
                title={
                  <div>
                    {item.title} - ${item.price}
                  </div>
                }
                description={item.description}
              />
              <div>{cnt}</div>
            </List.Item>
          );
        }}
      />
      <Divider />
      <Level
        isMobile
        left={<Typography.Title level={5}>Total</Typography.Title>}
        right={<Typography.Text>${totalPrices}</Typography.Text>}
      />
      <Divider />
      <Button
        disabled={selectedServices.length === 0}
        onClick={() => props.onSubmit(selectedServices)}
        type="primary"
        block
      >
        Find openings
      </Button>
    </>
  );
};

export default CombineServices;
