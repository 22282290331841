import React from "react";
import dayjs from "dayjs";
import CustomDateInput from "../../../components/CustomDateInput";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

interface DateSelectorProps {
  selectedDate: Date;
  setDate: (data: Date) => void;
  hasBorder?: boolean;
  filterDays: number[];
  excludedDates: Date[];
}

const DateSelector = ({
  selectedDate,
  setDate,
  hasBorder,
  filterDays,
  excludedDates,
}: DateSelectorProps) => {
  React.useEffect(() => {
    const datePickers = document.getElementsByClassName(
      "react-datepicker__input-container"
    );
    Array.from(datePickers).forEach((el) =>
      // @ts-ignore
      el.childNodes[0].setAttribute("readOnly", true)
    );
  }, []);
  const now = new Date();
  const dateMoment = dayjs(selectedDate);
  const ref = React.createRef<HTMLInputElement>();

  return (
    <DatePicker
      id="datepicker"
      autoFocus={false}
      selected={selectedDate}
      minDate={now}
      onChange={(date: Date) => {
        // setTime(undefined);
        setDate(date);
      }}
      excludeDates={excludedDates}
      customInput={
        // @ts-ignore
        <CustomDateInput
          ref={ref}
          onNext={() => {
            setDate(dateMoment.add(1, "day").toDate());
          }}
          onPrev={() => {
            setDate(dateMoment.subtract(1, "day").toDate());
          }}
          hasBorder={hasBorder}
        />
      }
      filterDate={(date: Date) => !filterDays.includes(date.getDay())}
    />
  );
};

export default DateSelector;
