import React, { ReactElement } from "react";
import {
  EventAction,
  eventReducer,
  IEventInitialState,
} from "./reducers/events";
import { PlaceAction, placeReducer, IPlaceState } from "./reducers/place";

type InitialState = {
  events: IEventInitialState;
  place: IPlaceState;
};
const initialState = {
  events: {
    idOnMarker: "",
    idOnCard: "",
  },
  place: undefined,
};

const BrowseContext = React.createContext<{
  state: InitialState;
  dispatch: React.Dispatch<EventAction | PlaceAction>;
}>({
  state: initialState,
  dispatch: () => null,
});

const mainReducer = (
  { events, place }: InitialState,
  action: EventAction | PlaceAction
) => ({
  events: eventReducer(events, action),
  place: placeReducer(place, action),
});

function BrowseProvider({ children }: { children: ReactElement }) {
  const [state, dispatch] = React.useReducer(mainReducer, initialState);

  return (
    <BrowseContext.Provider value={{ state, dispatch }}>
      {children}
    </BrowseContext.Provider>
  );
}

export { BrowseProvider, BrowseContext };
