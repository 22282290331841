import Icon from "@ant-design/icons";
import React from "react";

// Assuming this is your SVG path, which it probably isn't; replace with your own

// const IconFont = createFromIconfontCN({
//   scriptUrl: [YELP_SVG_PATH], // point to your Yelp SVG path
// });

const YelpIcon = (props: any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="14"
    height="14"
    viewBox="0 0 100 100"
    id="yelp"
  >
    <path d="M27.749 9.099c4.539-3.568 13.266-4.59 16.857-3.894 3.594.692 5.471 2.702 5.494 5.15l.207 31.21c.017 2.451-1.256 4.704-2.83 5.007-1.582.297-3.91-1.16-5.19-3.245L25.912 16.566c-1.27-2.088-3.83-3.009 1.837-7.467m-5.437 59.346l16.486-5.834c2.297-.814 4.355-3.082 4.586-5.051.223-1.969-1.447-4.307-3.712-5.203l-17.415-6.905c-2.267-.896-4.882 1.229-5.572 4.706 0 0-.414 12.109 0 14.615.419 2.506 3.33 4.491 5.627 3.672m28.756 2.916c.049-2.459-1.263-4.579-2.916-4.73-1.653-.152-4.277 1.258-5.844 3.129l-11.5 13.79c-1.566 1.874-.788 6.275 1.494 7.108l12.025 4.177c2.289.833 6.32-1.632 6.367-4.082l.374-19.392zm28.586-3.211l-14.42-4.146c-2.338-.672-4.991-.607-5.891.152-.898.752-.676 3.129.502 5.274l9.1 16.655c1.178 2.146 5.884 1.777 7.18-.304 0 0 5.484-8.092 6.494-11.126 1.009-3.038-.628-5.833-2.965-6.505m3.513-23.419c-.649-2.107-5.158-8.319-7.601-10.436-2.438-2.122-5.229-1.856-6.803.007l-9.293 11.07c-1.574 1.863-1.994 4.818-.945 6.555 1.05 1.737 3.849 2.722 6.224 2.188l16.074-2.98c2.902-.696 3.004-4.297 2.344-6.404"></path>
  </svg>
);

const YelpIconWrapper = () => {
  return <Icon component={YelpIcon}  />;
}

export default YelpIconWrapper;
