import React from "react";
import { Link } from "react-router-dom";
import { Avatar, Button, Modal, Space, Tag, Typography } from "antd";
import { COMB_LOGO_SRC } from "../../../../../../../consts";
import {
  InfoCircleOutlined,
  TagOutlined,
} from "@ant-design/icons";
import MiniProfile from "./MiniProfile";
import { IServiceForOpening } from "../../../../../../../api/types";
import DiscountCard from "./DiscountCard";

export interface IStylistCardProps {
  bio?: string;
  stylistId: string;
  name: string;
  profileImage?: string;
  services?: IServiceForOpening[];
  onVacation?: boolean;
  isFullyBooked?: boolean;
}

const StylistCard = (props: IStylistCardProps) => {
  // Filter out duplicate categories and sort by price
  const {
    name,
    stylistId,
    profileImage,
    services = [],
    onVacation,
    isFullyBooked,
  } = props;
  const [details, setDetails] = React.useState<IStylistCardProps | undefined>();
  const [discount, setDiscount] = React.useState<
    | {
        title: string;
        description: string;
        discountAmount: number;
        startDate: string;
        endDate: string;
        hasDate: boolean;
      }
    | undefined
  >();
  const uniqueServices = services
    .reduce((unique: any, service) => {
      if (!unique.find((item: any) => item.category === service.category)) {
        unique.push(service);
      }
      return unique;
    }, [])
    .sort((a: any, b: any) => a.price - b.price);

  return (
    <Space style={{ width: "100%", justifyContent: "space-between" }}>
      <Space>
        <Link to={`/stylists/${stylistId}`}>
          <Avatar src={profileImage ? profileImage : COMB_LOGO_SRC} />
        </Link>
        <div>
          <Link
            className="is-size-7 has-text-grey-dark has-text-weight-bold"
            to={`/stylists/${stylistId}`}
          >
            {name}
          </Link>
          {onVacation ? (
            <>
              <p className="content is-small">
                <Typography.Text className="is-size-7 has-text-grey">
                  on vacation
                </Typography.Text>
              </p>
            </>
          ) : (
            <>
              {services.length > 0 && (
                <div
                  className="content is-small"
                  style={{ marginBottom: "6px" }}
                >
                  {isFullyBooked ? (
                    <p className="is-italic">All Booked</p>
                  ) : (
                    uniqueServices.map((service: any) => (
                      <>
                        <div key={service._id}>
                          {service.title} &middot;&middot;&middot;{" "}
                          <span
                            style={{
                              textDecoration: service.discount
                                ? "line-through"
                                : "none",
                            }}
                          >
                            ${service.price}
                          </span>
                          {service.discount && (
                            <>
                              {"  "}
                              <span className="has-text-danger">
                                $
                                {service.price -
                                  service.discount.discountAmount}
                              </span>
                            </>
                          )}
                        </div>
                        {service.discount && (
                          <Space
                            size="small"
                            className="is-italic"
                            onClick={() => setDiscount(service.discount)}
                          >
                            <TagOutlined />
                            {service.discount.title
                              ? service.discount.title
                              : "Discount"}
                          </Space>
                        )}
                      </>
                    ))
                  )}
                </div>
              )}
            </>
          )}
        </div>
      </Space>
      <Space>
        <Button
          shape="circle"
          size="small"
          icon={<InfoCircleOutlined />}
          onClick={() => setDetails(props)}
        />
      </Space>
      {details && (
        <Modal
          centered
          open={true}
          onCancel={() => setDetails(undefined)}
          width={1000}
          footer={[
            <Button onClick={() => setDetails(undefined)}>Close</Button>,
          ]}
        >
          <MiniProfile {...details} />
        </Modal>
      )}
      {discount && (
        <Modal
          open={true}
          onCancel={() => setDiscount(undefined)}
          footer={[
            <Button size="small" onClick={() => setDiscount(undefined)}>
              Close
            </Button>,
          ]}
        >
          <DiscountCard data={discount} />
        </Modal>
      )}
    </Space>
  );
};

export default StylistCard;
