import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { EditionJson } from "../../api";
import { isDesktop } from "react-device-detect";
import LookupPhoneNumber from "./LookupPhoneNumber";
import RescheduleMain from "./RescheduleMain";
import FirstClient from "./FirstClient";
import RescheduleList from "./RescheduleList";
import { extractErrorMessage } from "../../helpers";
import { Drawer, Modal, message } from "antd";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import SetupPayment from "./SetupPayment";
import { useApp } from "../../hooks/useAppointment";
import { ICreateAppointmentArgs } from "../../api/types";

export interface INewAppointmentArgs {
  firstName: string;
  lastName: string;
  phoneNumber: string;
  code?: string;
  paymentMethodId?: string;
  errorCallback: () => void;
}

export interface IRescheduleArgs {
  previousAppointmentId: string;
  paymentMethodId?: string;
  errorCallback: () => void;
}

export const LOOKUP_PHONE_NUMBER = "lookup";
export const SETUP_PAYMENT = "setup-payment";
export const FIRST_TIME_CLIENT = "first-time-client";
export const RESCHEDULE_MAIN = "reschedule-main";
export const RESCHEDULE_LIST = "reschedule-list";

export type IPathState =
  | typeof LOOKUP_PHONE_NUMBER
  | typeof FIRST_TIME_CLIENT
  | typeof SETUP_PAYMENT
  | typeof RESCHEDULE_MAIN
  | typeof RESCHEDULE_LIST;

export type IAppointmentRouteState = {
  upcomingAppointments: any[];
  phoneNumber: string;
  client: {
    firstName: string;
    lastName: string;
  };
  paymentMethodId?: string;
};

const initialRoute = {
  path: LOOKUP_PHONE_NUMBER as IPathState,
  state: {
    upcomingAppointments: [],
    phoneNumber: "",
    client: {
      firstName: "Unknown",
      lastName: "Unknown",
      isBlocked: false,
    },
  },
};

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY as string);

const NewAppointmentModal = () => {
  let navigate = useNavigate();
  const { isAppointmentModalActive, appointmentForm, closeAppointmentForm } =
    useApp();
  console.log(appointmentForm);
  const [route, setRoute] = useState<{
    path: IPathState;
    state: IAppointmentRouteState;
  }>(initialRoute);

  const onRouteChange = (path: IPathState, state: any) => {
    setRoute({
      path,
      state: {
        ...route.state,
        ...state,
      },
    });
  };

  const onCreate = async (args: INewAppointmentArgs) => {
    if (appointmentForm) {
      try {
        const { userId, scheduleId, services, time, date, onAfter } =
          appointmentForm;

        const obj: ICreateAppointmentArgs = {
          userId,
          scheduleId,
          time,
          date,
          firstName: args.firstName,
          lastName: args.lastName,
          code: args.code,
          phoneNumber: args.phoneNumber,
          services: services.map((item: any) => {
            return {
              category: item.category,
              ...(item.option && { option: item.option.id }),
              ...(item.discount && {
                discount: {
                  title: item.discount.title,
                  discountAmount: item.discount.discountAmount,
                },
              }),
            };
          }),
          paymentMethodId: args.paymentMethodId,
        };
        const res = await EditionJson.createAppointment(obj);
        if (onAfter) {
          onAfter(res.appointmentId);
        } else {
          navigate(`/appointment/review/${res.appointmentId}`);
        }
        closeAppointmentForm();
        setRoute(initialRoute);
      } catch (e: any) {
        message.error(extractErrorMessage(e));
        args.errorCallback();
      }
    }
  };

  const onReschedule = async (args: IRescheduleArgs) => {
    if (appointmentForm) {
      try {
        const { userId, scheduleId, time, date, services, onAfter } =
          appointmentForm;

        const res = await EditionJson.rescheduleAppointment({
          rescheduleAppointmentId: args.previousAppointmentId,
          userId,
          scheduleId,
          time,
          date,
          services: services.map((item: any) => ({
            category: item.category,
            ...(item.option && { option: item.option.id }),
          })),
          paymentMethodId: args.paymentMethodId,
        });
        if (onAfter) {
          onAfter(res.appointmentId);
        } else {
          navigate(`/appointment/review/${res.appointmentId}`);
        }
        closeAppointmentForm();
        setRoute(initialRoute);
      } catch (e: any) {
        message.error(extractErrorMessage(e));
        args.errorCallback();
      }
    }
  };

  // Define a map of components
  const componentMap: Record<
    IPathState,
    React.FC<{
      routeState: any;
      onCreate: typeof onCreate;
      onReschedule: typeof onReschedule;
      onRouteChange: typeof onRouteChange;
    }>
  > = {
    [LOOKUP_PHONE_NUMBER]: LookupPhoneNumber,
    [SETUP_PAYMENT]: SetupPayment,
    [FIRST_TIME_CLIENT]: FirstClient,
    [RESCHEDULE_MAIN]: RescheduleMain,
    [RESCHEDULE_LIST]: RescheduleList,
  };

  useEffect(() => {
    setRoute(initialRoute);
  }, [isAppointmentModalActive]);

  const CurrentComponent = componentMap[route?.path];

  if (isDesktop) {
    return (
      <Modal
        title="Appointment"
        open={isAppointmentModalActive}
        maskClosable={false}
        onCancel={closeAppointmentForm}
        footer={null}
        style={{ top: 20 }}
        destroyOnClose={true}
      >
        <Elements
          options={{
            appearance: {
              theme: "stripe" as any,
            },
            mode: "setup",
            currency: "usd",
            paymentMethodTypes: ["card"],
          }}
          stripe={stripePromise}
        >
          {React.createElement(CurrentComponent, {
            routeState: route.state,
            onCreate,
            onReschedule,
            onRouteChange,
          })}
        </Elements>
      </Modal>
    );
  }
  return (
    <Drawer
      title="Appointment"
      contentWrapperStyle={{ height: "100%" }}
      maskStyle={{ background: "transparent" }}
      autoFocus={false}
      placement={"bottom"}
      destroyOnClose={true}
      open={isAppointmentModalActive}
      onClose={closeAppointmentForm}
      children={
        <Elements
          options={{
            appearance: {
              theme: "stripe" as any,
            },
            mode: "setup",
            currency: "usd",
            paymentMethodTypes: ["card"],
          }}
          stripe={stripePromise}
        >
          {React.createElement(CurrentComponent, {
            routeState: route.state,
            onCreate,
            onReschedule,
            onRouteChange,
          })}
        </Elements>
      }
    />
  );
};

export default NewAppointmentModal;
