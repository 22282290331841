import { Typography } from "antd";
import React from "react";
interface IInputLabelProps {
  id: string;
  label: string;
}
const InputLabel = ({ id, label }: IInputLabelProps) => {
  return (
    <div className="mb-1">
      <label htmlFor={id}>
        <Typography.Text strong>{label}</Typography.Text>
      </label>
    </div>
  );
};

export default InputLabel;
