import { Button, Typography } from "antd";
import { Link } from "react-router-dom";
import { ILocationJsonResult } from "../../../../../api/types";
import { EnvironmentOutlined } from "@ant-design/icons";
const LocationInfo = ({ data }: { data: ILocationJsonResult }) => {
  const { location, members } = data;

  return (
    <>
      <Typography.Paragraph>
        <Typography.Title level={5}>
          Parking
          {location.parking && (
            <>
              {" "}
              <Button
                href={location.parking.map}
                target="_blank"
                rel="noreferrer"
                size="small"
                shape="circle"
                icon={<EnvironmentOutlined />}
              ></Button>
            </>
          )}
        </Typography.Title>
        <Typography.Text>{location.parking?.description}</Typography.Text>
      </Typography.Paragraph>
      <div className="is-hidden-mobile">
        <Typography.Paragraph>
          <Typography.Title level={5}>Stylists</Typography.Title>
          {members && members.length > 0 && (
            <ul>
              {members.map((member: any) => {
                return (
                  <li key={member.profile.pathId}>
                    <Link to={`/stylists/${member.profile.pathId}`}>
                      <Typography.Text>{member.profile.name}</Typography.Text>
                    </Link>
                  </li>
                );
              })}
            </ul>
          )}
        </Typography.Paragraph>
      </div>
      {/* <div className="is-hidden-mobile">
        <Typography.Title level={5}>Links</Typography.Title>
        <SalonExternalLinks links={location.links} />
      </div> */}
    </>
  );
};

export default LocationInfo;
