import React, { useEffect, useState } from "react";
import TimeButton from "../../../../components/TimeButton";
import {
  checkIfConsultationRequired,
  removePastTimeSlots,
} from "../../../../../helpers";
import { EditionJson } from "../../../../../api";
import { IUserJson, IUserTimeSlotsJson } from "../../../../../api/types";
import { Alert, Button, Col, Divider, Row, Space, Typography } from "antd";
import { WENT_WRONG } from "../../../../../consts";
import DisplayNextOpenings from "../DIsplayNextOpenings";
import SpecialServiceMessage from "../SpecialServiceMessage";
import _ from "underscore";
import { IStylistService } from "../..";
import { RedoOutlined } from "@ant-design/icons";
import { ApppointmentServiceType, useApp } from "../../../../../hooks/useAppointment";

interface IProps {
  date: string;
  stylist: IUserJson;
  refreshCount: number;
  services: IStylistService[];
  isTodayOnVacation?: boolean;
  onRefresh?: () => void;
}

export const StylistOpenings = ({
  date,
  stylist,
  services,
  refreshCount,
  isTodayOnVacation,
  onRefresh,
}: IProps) => {
  const { openAppointmentForm } = useApp();
  const [isLoading, setLoading] = useState(true);
  const [schedules, setSchedules] = useState<IUserTimeSlotsJson[]>([]);
  const userPathId = stylist.profile.pathId;

  const onTimeSlotClick = (
    userId: string,
    time: string,
    stylistName: string,
    scheduleId: string,
    locationName: string
  ) => {
    const combinedServices: ApppointmentServiceType[] = [];
    services.forEach((service) => {
      combinedServices.push({
        title: service.title,
        description: service.description,
        up: service.up,
        price: service.price,
        duration: service.duration,
        hasPayAdvance: service.hasPayAdvance,
        category: service.category,
        option: service.option,
      });
    });
    openAppointmentForm({
      userId,
      stylistName,
      locationName,
      scheduleId,
      services,
      time,
      date,
    });
  };

  let duration = 0;
  services.forEach((service) => {
    duration += service.duration;
  });

  useEffect(() => {
    const load = async () => {
      setLoading(true);
      try {
        const _res = await EditionJson.getOpeningsByDuration(
          userPathId,
          date,
          duration
        );
        setLoading(false);
        setSchedules(_res);
      } catch (error) {
        setLoading(false);
        console.error(error);
      }
    };
    load();
  }, [userPathId, date, refreshCount, services]);

  if (isLoading)
    return (
      <Typography.Paragraph>
        Just a moment, we're gathering available time slots for you...
      </Typography.Paragraph>
    );

  if (!schedules)
    return <Typography.Paragraph>{WENT_WRONG}</Typography.Paragraph>;

  let openingCount = 0;

  const schedulesWithoutPastTimeSlots = schedules.map((schedule) => {
    schedule.openings = removePastTimeSlots(schedule.openings, date);
    openingCount = schedule.openings.length + openingCount;
    return schedule;
  });

  let isConsultationRequired = false;

  services.forEach((service) => {
    isConsultationRequired = checkIfConsultationRequired(service.category);
  });

  if (isConsultationRequired)
    return (
      <SpecialServiceMessage selectedServices={services} stylist={stylist} />
    );

  return (
    <Space direction="vertical" size="middle" style={{ width: "100%" }}>
      {isTodayOnVacation ? (
        <Alert type="success" message="On vacation" />
      ) : (
        schedulesWithoutPastTimeSlots.map((item, i) => {
          const { schedule, openings } = item;
          if (!openings || openings.length === 0) {
            return null;
          } else {
            openingCount++;
            return (
              <Space direction="vertical" style={{ width: "100%" }} key={i}>
                {schedules.length > 1 && (
                  <Typography.Text strong>{schedule.title}</Typography.Text>
                )}
                <Row gutter={[5, 5]}>
                  {openings.map((time: string, i: number) => {
                    return (
                      <Col key={`${time}-${i}`} span={8}>
                        <TimeButton
                          onClick={() => {
                            onTimeSlotClick(
                              stylist._id,
                              time,
                              stylist.profile.name,
                              schedule._id,
                              schedule.title
                            );
                          }}
                          time={time}
                          isSelected={false}
                        />
                      </Col>
                    );
                  })}
                </Row>
              </Space>
            );
          }
        })
      )}
      {openingCount === 0 && (
        <DisplayNextOpenings
          stylist={stylist}
          date={date}
          duration={duration}
          services={services}
        />
      )}
      {onRefresh && (
        <div className="has-text-right">
          <Divider/>
          <Button
            icon={<RedoOutlined />}
            onClick={onRefresh}
            type="dashed"
            size="small"
          >
            Refresh Openings
          </Button>
        </div>
      )}
    </Space>
  );
};

export default StylistOpenings;
