import React from "react";
import { IServiceModel } from "../../../../../models";
import { Space, Typography } from "antd";
interface StylistMenuProps {
  services: any[];
}
const DisplayStylistServices = ({ services }: StylistMenuProps) => {
  return (
    <Space direction="vertical" style={{ width: "100%" }}>
      {services.map((item, i) => {
        return (
          <div key={`service-${i}`}>
            <Typography.Text>
              {item.title}&nbsp;&middot; &middot; &middot;{" "}
              <span
                style={{
                  textDecoration: item.discount ? "line-through" : "none",
                }}
              >
                ${item.price}
                {item.up && "+"}
              </span>
              {item.discount && (
                <>
                  {"  "}
                  <Typography.Text type="danger">
                    ${item.price - item.discount.discountAmount}
                  </Typography.Text>
                </>
              )}
            </Typography.Text>

            {item.description && (
              <>
                <br />
                <Typography.Text type="secondary" italic>
                  {item.description}
                </Typography.Text>
              </>
            )}
          </div>
        );
      })}
    </Space>
  );
};

export default DisplayStylistServices;
