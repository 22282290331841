import React, { useEffect, useState } from "react";
import dayjs from "dayjs";
import Helmet from "react-helmet";
import ICalendarLink from "react-icalendar-link";
import { Button, Checkbox, Divider, Space, Typography, message } from "antd";

import PageLoading from "../../../components/PageLoading";
import NotFound from "../../../components/NotFound";
import AppContentWrapper from "../../../components/AppContentWrapper";
import { useParams } from "react-router-dom";
import {
  IAppointmentReviewJson,
  IRequestSpectionOptionsArgs,
} from "../../../api/types";
import { EditionJson } from "../../../api";
import DisplayParking from "../SalonView/components/DisplayParking";
import { STANDARD_DATE_TIME_FORMAT } from "../../../consts";
import TextArea from "antd/es/input/TextArea";
import { extractErrorMessage } from "../../../helpers";

const { Title, Paragraph, Text } = Typography;

const AppointmentReview = () => {
  const { appointmentId } = useParams<{ appointmentId: string }>();
  const [data, setData] = useState<IAppointmentReviewJson | undefined>();
  const [isLoading, setLoading] = useState(true);
  const [isSubmitting, setSubmitting] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [options, setOptions] = useState<IRequestSpectionOptionsArgs>({
    hasExtraLength: false,
    hasNote: false,
    note: "",
  });

  useEffect(() => {
    const load = async (_appointmentId: string) => {
      setLoading(true);
      try {
        const _res = await EditionJson.getAppointment(_appointmentId);
        console.log(_res);
        setData(_res);
      } catch (error) {
        console.error(error);
      }
      setLoading(false);
    };

    if (appointmentId) {
      load(appointmentId);
    }
  }, [appointmentId]);

  const onOptionChange = (type: string, value: any) => {
    setOptions({
      ...options,
      [type]: value,
    });
  };

  const onRequestSubmit = async () => {
    if (appointmentId) {
      setSubmitting(true);
      try {
        await EditionJson.requestSpecialOptions(appointmentId, options);
        // message.success("Request submitted");
        setSubmitted(true);
      } catch (error) {
        message.error(extractErrorMessage(error));
      }
      setSubmitting(false);
    }
  };

  if (isLoading) return <PageLoading />;
  if (!data) return <NotFound />;

  const datetime = dayjs(data.datetime);
  const event = {
    title: `${data.serviceName} appointment`,
    description: `Eddie's Edition appointment with ${data.stylistName} for ${data.serviceName}`,
    startTime: datetime.toISOString(),
    endTime: datetime
      .add(data.serviceDuration ? data.serviceDuration : 45, "minutes")
      .toISOString(),
    location: data.address,
    attendees: [],
    url: "https://www.eddiesedition.com/appointment/review/" + appointmentId,
  };

  return (
    <AppContentWrapper>
      <Helmet>
        <meta name="prerender-status-code" content="404" />
        <meta name="robots" content="noindex" />
      </Helmet>
      {data.isCanceled && (
        <div className="notification is-danger">
          This appointment has been canceled.
        </div>
      )}
      <Typography>
        <Title level={4}>Thank you!</Title>
        <Paragraph>
          Thank you for scheduling your appointment with us! We operate on an
          appointment basis to ensure our stylists dedicate their time
          exclusively to you. If you need to cancel or reschedule, please do so
          at least 6 hours in advance. We look forward to seeing you!
        </Paragraph>
        <Button type="dashed">
          {/*@ts-ignore*/}
          <ICalendarLink filename="edition-appointment.ics" event={event}>
            Add to Calendar
          </ICalendarLink>
        </Button>
        <Divider />
        <Title level={5}>Add-ons</Title>
        {submitted ? (
          <Paragraph className="has-text-success">
            Your request submitted!
          </Paragraph>
        ) : (
          <Space direction="vertical">
            <Text>
              Is there anything else you would like to add to your appointment?
            </Text>
            <Checkbox
              checked={options.hasExtraLength}
              onChange={() =>
                onOptionChange("hasExtraLength", !options.hasExtraLength)
              }
            >
              Add extra length (Note: This requires additional service time)
            </Checkbox>

            <Checkbox
              checked={options.hasNote}
              onChange={() => onOptionChange("hasNote", !options.hasNote)}
            >
              Note to {data.stylistName}
              {options.hasNote && (
                <TextArea
                  onChange={(e) => onOptionChange("note", e.target.value)}
                />
              )}
            </Checkbox>
            <Button
              loading={isSubmitting}
              onClick={onRequestSubmit}
              type="dashed"
            >
              Save
            </Button>
          </Space>
        )}

        <Divider />

        <Paragraph>
          <Text strong>Stylist</Text>
          <br /> <Text>{data.stylistName}</Text>
        </Paragraph>
        <Paragraph>
          <Text strong>Service</Text>
          <br /> <Text>{`${data.serviceName} - $${data.servicePrice}`}</Text>
          {data.serviceDescription && (
            <>
              <br /> <Text italic>{data.serviceDescription}</Text>
            </>
          )}
        </Paragraph>
        <Paragraph>
          <Text strong>At</Text>
          <br /> <Text>{datetime.format(STANDARD_DATE_TIME_FORMAT)}</Text>
        </Paragraph>
        <Paragraph>
          <Text strong>Address</Text>
          <br /> <Text>{data.where}</Text>
        </Paragraph>
        {data.parking && (
          <Paragraph>
            <Text strong>Parking</Text>
            <br /> <Text>{data.parking.description}</Text>
            <br />
            <DisplayParking data={data.parking} />
          </Paragraph>
        )}
      </Typography>
    </AppContentWrapper>
  );
};

export default AppointmentReview;
