import React from "react";
import { NavLink } from "react-router-dom";
// import { useShopify } from "../../hooks";

interface AppMenuProps {
  closeSidebar: () => void;
}

export const appRoutes = [
  {
    label: "Appointment",
    path: "/appointment",
  },
  {
    label: "Stylists",
    path: "/stylists",
  },
  {
    label: "Services",
    path: "/services",
  },
  {
    label: "Photos",
    path: "/photos",
  },
  {
    label: "Shop",
    path: "/shop",
  },
  {
    label: "Blog",
    path: "/blog",
  },
  {
    label: "Contact",
    path: "/contact",
    isMobileOnly: true,
  },
  {
    label: "We are hiring",
    path: "/careers",
    isMobileOnly: true,
  },
];

const AppMenu = ({ closeSidebar }: AppMenuProps) => {
  // const { cartCount, openCart } = useShopify();
  // function handleClick(e: any) {
  //   e.preventDefault();
  //   openCart();
  // }
  return (
    <>
      {appRoutes.map((link) => {
        return (
          <NavLink
            key={link.path}
            to={link.path}
            className={`navbar-item ${
              link.isMobileOnly ? "is-hidden-tablet" : ""
            }`}
            // activeClassName={"is-active"}
            onClick={closeSidebar}
          >
            {link.label}
          </NavLink>
        );
      })}
      {/* {cartCount ? (
        <a onClick={handleClick} className="navbar-item">
          Cart
        </a>
      ) : <></>} */}
    </>
  );
};

export default AppMenu;
