import React from "react";
import dayjs from "dayjs";
import { CalendarOutlined, LeftOutlined, RightOutlined } from "@ant-design/icons";
interface IProps {
  value: string;
  onClick: () => void;
  onPrev: () => void;
  onNext: () => void;
  hasBorder?: boolean;
}
const customDateInputStyle = {
  display: "flex",
  backgroundColor: "hsl(0, 0%, 100%)",
  borderColor: "hsl(0, 0%, 80%)",
  borderRadius: "4px",
  borderStyle: "solid",
  borderWidth: "1px",
};
const buttonStyle = {
  height: "36px",
};

const CustomDateInput = React.forwardRef((props: IProps, ref: any) => {
  const { value, onClick, onPrev, onNext }: IProps = props;
  let label = value;
  const today = dayjs().startOf("day");
  const selectedDay = dayjs(value, "l");
  switch (today.diff(selectedDay, "days")) {
    case 0:
      label = "Today";
      break;
    case 1:
      label = "Yesterday";
      break;
    case -1:
      label = "Tomorrow";
      break;
  }
  return (
    <div style={customDateInputStyle}>
      <button
        style={buttonStyle}
        disabled={label === "Today"}
        className="button is-white"
        onClick={onPrev}
      >
        <LeftOutlined />
      </button>
      <button
        style={buttonStyle}
        className="button is-white is-borderless is-fullwidth"
        onClick={onClick}
      >
        <span className="icon is-left has-text-dark">
          <CalendarOutlined />
        </span>
        <span>&nbsp;&nbsp;{label}</span>
      </button>
      <button style={buttonStyle} className="button is-white" onClick={onNext}>
        <RightOutlined />
      </button>
    </div>
  );
});

export default CustomDateInput;
