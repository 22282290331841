import React from "react";
import PageTitle from "../../../../components/PageTitle";
import ViewSwitch from "../ViewSwitch";
import { Typography } from "antd";

interface FilterProps {
  date: Date;
  setDate: (date: Date) => void;
  isMapView: boolean;
  onToggle: () => void;
}

const Filter = ({ date, setDate, isMapView, onToggle }: FilterProps) => {
  return (
    <div id="Filters" className="has-background-white">
      <div className="level is-mobile" style={{ minHeight: "42px" }}>
        <div className="level-left">
          <div className="level-item">
            <div style={{ paddingLeft: "1.25rem" }}>
              <Typography.Title className="is-marginless" level={4}>Locations</Typography.Title>
            </div>
          </div>
        </div>
        <div className="level-right is-hidden-desktop">
          <div className="level-item">
            <ViewSwitch isMapView={isMapView} onToggle={onToggle} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Filter;
