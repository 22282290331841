import React, { useRef } from "react";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import { isValidPhoneNumber } from "libphonenumber-js";
import { Button, Divider, Typography, message } from "antd";
import Level from "../../components/Level";

import { EditionJson } from "../../api";

import PhoneInputForUSOnly from "react-phone-number-input/input";
import AppointmentModalReview from "./components/AppointmentReview";

import {
  FIRST_TIME_CLIENT,
  INewAppointmentArgs,
  IPathState,
  RESCHEDULE_MAIN,
  SETUP_PAYMENT,
} from "./index";
import InputLabel from "../../components/InputLabel";
import { extractErrorMessage } from "../../helpers";
import { IAppointmentHistoryJson } from "../../api/types";
import { ApppointmentServiceType, useApp } from "../../hooks/useAppointment";

const checkIfCreditCardRequired = (services: ApppointmentServiceType[]) => {
  return services.some((service) => service.hasPayAdvance);
};

interface IProps {
  onCreate: (args: INewAppointmentArgs) => void;
  onRouteChange: (path: IPathState, state: any) => void;
}
const LookupPhoneNumber = (props: IProps) => {
  const { appointmentForm } = useApp();
  const isCreditCardRequired = appointmentForm
    ? checkIfCreditCardRequired(appointmentForm.services)
    : false;

  const [isInternational, setInternational] = React.useState(false);
  const [phoneNumber, setPhoneNumber] = React.useState<string>();
  const [isSubmitting, setSubmitting] = React.useState(false);

  const inputRef = useRef<HTMLInputElement>();

  const handleSubmit = async () => {
    if (!phoneNumber) return;

    if (!isValidPhoneNumber(phoneNumber)) {
      message.error("Invalid phone number");
      return;
    }

    setSubmitting(true);

    try {
      const res = await EditionJson.getAppointmentHistory(
        phoneNumber,
        appointmentForm?.userId as string
      );
      handleRoutingBasedOnResponse(res);
    } catch (e) {
      message.error(extractErrorMessage(e));
      setSubmitting(false);
    }
  };

  const handleRoutingBasedOnResponse = async (
    phoneNumberHistory: IAppointmentHistoryJson
  ) => {
    if (!phoneNumber) return;
    const { upcomingAppointments, client, isNewClient } = phoneNumberHistory;

    if (isNewClient) {
      props.onRouteChange(FIRST_TIME_CLIENT, {
        phoneNumber,
        isCreditCardRequired,
      });
      return;
    }

    if ((client && client.blocked) || isCreditCardRequired) {
      props.onRouteChange(SETUP_PAYMENT, {
        phoneNumber,
        upcomingAppointments,
        client,
      });
      return;
    }

    if (phoneNumberHistory.upcomingAppointments.length > 0) {
      props.onRouteChange(RESCHEDULE_MAIN, {
        phoneNumber,
        upcomingAppointments,
        client,
      });
      return;
    }

    props.onCreate({
      firstName: client ? client.firstName : "Unknown",
      lastName: client ? client.lastName : "Unknown",
      phoneNumber,
      errorCallback: () => {
        setSubmitting(false);
      },
    });
  };

  return (
    <>
      <AppointmentModalReview />
      <InputLabel id="phoneNumber" label="Phone Number:" />
      <div>
        {isInternational ? (
          <PhoneInput
            id="phoneNumber"
            defaultCountry="US"
            value={phoneNumber}
            onChange={(value: any) => setPhoneNumber(value)}
          />
        ) : (
          <PhoneInputForUSOnly
            ref={inputRef}
            id="phoneNumber"
            className="input is-shadowless"
            value={phoneNumber}
            country="US"
            onChange={(value: any) => setPhoneNumber(value)}
          />
        )}
      </div>
      <Divider />
      <Level
        isMobile
        left={
          <Button
            onClick={handleSubmit}
            disabled={!phoneNumber || isSubmitting}
            loading={isSubmitting}
            type="primary"
          >
            Submit
          </Button>
        }
        right={
          <Typography.Link
            onClick={() => setInternational(true)}
            className="has-text-dark is-size-7 has-text-right"
          >
            International Number?
          </Typography.Link>
        }
      />
    </>
  );
};

export default LookupPhoneNumber;
