import { Typography } from "antd";
import { ILocationJsonResult } from "../../../../../api/types";

const LocationAddress = ({ data }: { data: ILocationJsonResult }) => {
  const { location } = data;
  const address = `${location.address}, ${location.city}, ${location.state} ${location.zipcode}`;

  return (
    <Typography.Link
      href={location.map}
      target="_blank"
      rel="noreferrer"
      className="is-size-7"
    >
      {address}
    </Typography.Link>
  );
};

export default LocationAddress;
