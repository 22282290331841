import { Modal } from "antd";
import React, { ReactElement } from "react";
interface IModalCardProps {
  title: string;
  onClose: () => void;
  children: ReactElement;
}
const ModalCard = ({
  title,
  onClose,
  children,
}: IModalCardProps) => {
  return (
    <Modal
      maskClosable={false}
      title={title}
      onCancel={onClose}
      footer={null}
      style={{ top: 20 }}
      open={true}
    >
      {children}
    </Modal>
  );
};

export default ModalCard;
