import { Typography } from "antd";
import React from "react";

const Main = () => {
  return (
    <section className="section is-medium">
      <div className="container has-text-centered">
        <Typography.Title level={3} className="is-hidden-mobile">
          Only the Best Haircuts Provided
        </Typography.Title>
        <Typography.Paragraph>
          At Eddie's Edition, we live by one simple rule - Good Haircuts Only!
          Our expert stylists ensure every cut leaves you looking and feeling
          your absolute best.
        </Typography.Paragraph>

        <div className="block">
          <div className="divider div-transparent div-dot" />
        </div>
        <div className="columns">
          <div className="column">
            <div>
              <Typography.Title level={4}>Quality</Typography.Title>
              <Typography.Paragraph>
                Experts in haircuts with precise scissor & clipper techniques
                for consistently fresh looks
              </Typography.Paragraph>
            </div>
          </div>
          <div className="column">
            <div>
              <Typography.Title level={4}>No Wait Times</Typography.Title>
              <Typography.Paragraph>
                Book effortlessly with our real-time scheduler. No overbooking,
                always on time, focused on delivering top service.
              </Typography.Paragraph>
            </div>
          </div>
          <div className="column">
            <div>
              <Typography.Title level={4}>Consistent</Typography.Title>
              <Typography.Paragraph>
                We provide consistent quality service by using a tried and
                tested blueprint system for all of our customers.
              </Typography.Paragraph>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Main;
