import { Button, Checkbox, Divider, List, Modal, Typography } from "antd";
import React from "react";
import { EXTRA_OPTIONS } from "../../../../../api/consts";

interface IExtraOptionsProps {
  serviceCategory: number;
  onSubmit: (op: {
    id: string;
    title: string;
    description: string;
    serviceCategory: number;
  }) => void;
  onClose: () => void;
}
const regularOption = {
  id: "",
  title: "Regular",
  description: "",
};
const ExtraOptions = ({
  serviceCategory,
  onSubmit,
  onClose,
}: IExtraOptionsProps) => {
  const [selectedOption, setSelectedOption] = React.useState<{
    id: string;
    title: string;
    description: string;
  }>(regularOption);

  const onSelect = (op: { id: string; title: string; description: string }) => {
    setSelectedOption(op);
  };

  const handleSubmit = () => {
    onSubmit({
      ...selectedOption,
      serviceCategory,
    });
    onClose();
  };

  const options = EXTRA_OPTIONS[serviceCategory]
    ? EXTRA_OPTIONS[serviceCategory].slice() // Use slice to create a copy if it's not meant to modify the original
    : [];

  options.unshift(regularOption);

  return (
    <Modal
      open={true}
      onCancel={onClose}
      footer={[
        <Button
          type="primary"
          key="submit"
          onClick={handleSubmit}
          disabled={!selectedOption?.id}
        >
          Find Openings
        </Button>,
        <Button key="close" onClick={onClose}>
          Close
        </Button>,
      ]}
    >
      <Typography.Paragraph>
        If you want to find a more specific option, please choose one from the
        list. We will find stylists who are skilled in that area.
      </Typography.Paragraph>
      <List
        itemLayout="horizontal"
        dataSource={options}
        renderItem={(item) => {
          return (
            <List.Item>
              <List.Item.Meta
                avatar={
                  <Checkbox
                    checked={
                      selectedOption && selectedOption.id === item.id
                        ? true
                        : false
                    }
                    onClick={() => onSelect(item)}
                  />
                }
                title={item.title}
                description={item.description}
              />
            </List.Item>
          );
        }}
      />
      <Divider />
    </Modal>
  );
};

export default ExtraOptions;
