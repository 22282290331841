import React from "react";

import {
  CarouselProvider,
  Slider,
  Slide,
  ButtonBack,
  ButtonNext,
} from "pure-react-carousel";
import "pure-react-carousel/dist/react-carousel.es.css";
import { Image } from "antd";
import { convertToCloudFareUrl } from "../../../api/cloudfare";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";

const DisplaySlider = ({
  images,
  slides,
}: {
  images: any[];
  slides: number;
}) => {

  return (
    <div id="DisplaySlider" className="is-relative">
      <Image.PreviewGroup
        preview={{
          toolbarRender: (el, info) => {
            return null;
          },
        }}
      >
        <CarouselProvider
          naturalSlideWidth={100}
          naturalSlideHeight={100}
          totalSlides={images.length}
          isIntrinsicHeight={true}
          visibleSlides={slides}
        >
          <Slider>
            {images.map((image, index) => {
              const src = convertToCloudFareUrl(image.id);
              return (
                <Slide
                  key={index}
                  index={index}
                  // onClick={() => (hasLightbox ? openLightbox(index) : false)}
                >
                  <Image
                    width={"100%"}
                    height={180}
                    src={src}
                    preview={{
                      mask: <div />,
                    }}
                  />
                </Slide>
              );
            })}
          </Slider>
          {slides < images.length && (
            <div className="slide-buttons">
              <ButtonBack className="slide-button is-left">
                <span className="icon">
                  <LeftOutlined />
                </span>
              </ButtonBack>
              <ButtonNext className="slide-button is-right">
                <span className="icon">
                  <RightOutlined />
                </span>
              </ButtonNext>
            </div>
          )}
        </CarouselProvider>
      </Image.PreviewGroup>
    </div>
  );
};

export default DisplaySlider;
