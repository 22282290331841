import React from "react";
import { ILocationModel, IScheduleModel } from "../../../../../models";
import { Link } from "react-router-dom";
import { Typography } from "antd";
interface IProps {
  schedules: IEnhancedSchedule[];
}
interface IEnhancedSchedule extends IScheduleModel {
  location?: ILocationModel;
}
const DisplayLocations = ({ schedules }: IProps) => {
  schedules = schedules.filter(({ locationId }) => !!locationId);
  if (schedules.length === 0) return null;
  return (
    <Typography.Text>
      {schedules.map((schedule, index) => {
        if (!schedule.location) return null;
        return (
          <Link
            className="has-text-dark"
            to={`/appointment/${schedule.location.pathId}`}
            key={index}
          >{`${index !== 0 ? ", " : ""} ${schedule.location.name}`}</Link>
        );
      })}
    </Typography.Text>
  );
};

export default DisplayLocations;
