import { EnvironmentOutlined, UnorderedListOutlined } from "@ant-design/icons";
import React from "react";
interface ViewSwitchProps {
  isMapView: boolean;
  onToggle: () => void;
}

const ViewSwitch = ({ isMapView, onToggle }: ViewSwitchProps) => {
  const label = isMapView ? "List View" : "Map View";
  return (
    <div>
      <button
        onClick={onToggle}
        className={"button is-white is-fullwidth is-radiusless"}
      >
        <span className="icon">
          {isMapView ? (
            <UnorderedListOutlined className="has-text-info" />
          ) : (
            <EnvironmentOutlined className="has-text-danger" />
          )}
        </span>
        <span>{label}</span>
      </button>
    </div>
  );
};

export default ViewSwitch;
