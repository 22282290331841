import React from "react";
import Select, { components } from "react-select";
import { Button, Typography } from "antd";
import { PlusCircleOutlined } from "@ant-design/icons";
import { IServiceModel } from "../../../../../models";
import { IStylistService } from "../..";
interface ServicesProps {
  selectedServices: IStylistService[];
  defaultServices: IServiceModel[];
  setServices: (services: IStylistService[]) => void;
  setCombineServicesModalActive: () => void;
}
const CustomOption = (props: any) => {
  const value: IServiceModel = props.value;
  return (
    <components.Option {...props}>
      {value.category === 0 ? (
        <div style={{ display: "flex", alignItems: "center" }}>
          <PlusCircleOutlined />
          <span className="ml-1">{value.title}</span>
        </div>
      ) : (
        value.title
      )}
    </components.Option>
  );
};

const customService = {
  title: "Combine Services",
  category: 0,
};

const SelectService = ({
  selectedServices,
  defaultServices,
  setServices,
  setCombineServicesModalActive,
}: ServicesProps) => {
  const options: any = [...defaultServices];
  if (defaultServices.length > 1) {
    options.push(customService);
  }
  return (
    <>
      {selectedServices.length > 1 ? (
        <>
          <Typography.Title level={5}>Services</Typography.Title>
          <Typography.Paragraph>
            <ul>
              {selectedServices.map((service, i) => (
                <li key={`service-${i}`}>{service.title}</li>
              ))}
            </ul>
          </Typography.Paragraph>
          <Button
            onClick={setCombineServicesModalActive}
            size="small"
            type="dashed"
          >
            Edit Services
          </Button>
        </>
      ) : (
        <>
          <Typography.Title level={5}>Service</Typography.Title>
          <Select
            options={options.map((option: any) => {
              return { label: option.title, value: option };
            })}
            value={
              selectedServices[0]
                ? {
                    label: selectedServices[0].title,
                    value: selectedServices[0],
                  }
                : null
            }
            onChange={(option) => {
              if (option) {
                const { value } = option;
                if (value.category === 0) {
                  setCombineServicesModalActive();
                } else {
                  setServices([
                    {
                      title: value.title,
                      description: value.description,
                      category: value.category,
                      duration: value.duration,
                      price: value.price,
                      up: value.up,
                      options: value.options,
                    },
                  ]);
                }
              }
            }}
            components={{
              Option: CustomOption,
            }}
          />
        </>
      )}
    </>
  );
};

export default SelectService;
