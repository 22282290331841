import React, { useEffect, useState } from "react";
import { Checkbox, Divider, Space, Typography } from "antd";
import { useParams } from "react-router-dom";

import LocationInfo from "./components/DisplaySalonInfo";
import Calendar from "../../components/DateSelector";
import SalonSchedules from "./components/DisplaySchedules";
import SEO from "./components/SEO";
import PageLoading from "../../../components/PageLoading";
import NotFound from "../../../components/NotFound";

import {
  DefaultServiceType,
  EXTRA_OPTIONS,
  defaultServices,
} from "../../../api/consts";
import { EditionJson } from "../../../api";
import { ILocationJsonResult } from "../../../api/types";
import SelectService from "./components/SelectService";
import ModalCard from "../../../components/ModalCard";
import CombineServices from "./components/CombineServices";
import { getServicesByCategories } from "../../../api/helpers";
import { formatDate } from "../../../helpers";
import SalonBanner from "./components/SalonBanner";
import LocationAddress from "./components/DisplayAddress";
import ExtraOptions from "./components/ExtraOptions";
import { useApp } from "../../../hooks/useAppointment";

const SalonView = ({ data }: { data: ILocationJsonResult }) => {
  const { location } = data;
  const { date, setDate } = useApp();
  const [showOptions, setShowOptions] = useState(false);
  const locationServices = location.services
    ? getServicesByCategories(location.services)
    : defaultServices;
  const [selectedServices, setSelectedServices] = React.useState<
    DefaultServiceType[]
  >([locationServices[0]]);

  const [isCustomServiceModalActive, setCustomServiceModalActive] =
    useState(false);
  const [refreshCount, setRefreshCount] = React.useState(0);

  const onCombineServices = (services: DefaultServiceType[]) => {
    setSelectedServices(services);
    setCustomServiceModalActive(false);
  };

  const onServiceOptionClick = () => {
    setShowOptions(showOptions ? false : true);
  };

  /*
  This handles only one option at a time
  */
  const handleExtraOptionSelect = (op?: {
    serviceCategory: number;
    id: string;
    title: string;
  }) => {
    const val = selectedServices[0];
    if (op) {
      setSelectedServices([{ ...val, option: { id: op.id, title: op.title } }]);
      setShowOptions(false);
    } else {
      const { option, ...rest } = val; // Destructure to separate `option` from the rest of `val`
      setSelectedServices([{ ...rest }]); // Set the selected service without the `option` property
    }
  };
  return (
    <div className="content-wrapper">
      <SEO salon={location} />
      <section className="section has-thin-top-padding pb-0">
        <div className="container is-max-desktop">
          <Typography.Paragraph>
            <Typography.Title level={4} className="is-marginless">
              {location.name}
            </Typography.Title>
            <LocationAddress data={data} />
          </Typography.Paragraph>
        </div>
      </section>
      {location.banners && location.banners.length > 0 && (
        <SalonBanner banners={location.banners} />
      )}
      <div className="section">
        <div className="container is-max-desktop">
          <div className="columns">
            <div className="column pt-0 pb-0">
              <LocationInfo data={data} />
            </div>
            <div className="column is-narrow pt-0">
              <div className="is-narrow-inner" style={{ width: "370px" }}>
                <Space
                  direction="vertical"
                  style={{ width: "100%" }}
                  size="middle"
                >
                  <div>
                    <Typography.Title level={5}>Appointment</Typography.Title>
                    <Calendar
                      selectedDate={date}
                      setDate={setDate}
                      hasBorder={true}
                      filterDays={[]}
                      excludedDates={[]}
                    />
                  </div>
                  <div>
                    <SelectService
                      selectedServices={selectedServices}
                      defaultServices={locationServices}
                      setServices={setSelectedServices}
                      setCombineServicesModalActive={() =>
                        setCustomServiceModalActive(true)
                      }
                    />
                  </div>
                  {selectedServices[0] && selectedServices[0].option ? (
                    <Checkbox
                      checked={true}
                      onChange={() => handleExtraOptionSelect()}
                    >
                      <Typography.Text strong>Option:</Typography.Text>{" "}
                      <Typography.Text>
                        {selectedServices[0].option.title}
                      </Typography.Text>
                    </Checkbox>
                  ) : (
                    <>
                      {EXTRA_OPTIONS[selectedServices[0].category] && (
                        <div>
                          <Checkbox
                            checked={showOptions}
                            onChange={onServiceOptionClick}
                          >
                            Do you need more specific options?
                          </Checkbox>
                        </div>
                      )}
                    </>
                  )}
                </Space>
                <Divider />
                <SalonSchedules
                  locationName={location.name}
                  locationId={location._id}
                  services={selectedServices}
                  date={formatDate(date)}
                  refreshCount={refreshCount}
                  onRefresh={() => setRefreshCount(refreshCount + 1)}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      {isCustomServiceModalActive && (
        <ModalCard
          title="Build Services"
          onClose={() => setCustomServiceModalActive(false)}
        >
          <CombineServices
            defaultServices={locationServices}
            selectedServices={selectedServices}
            onSubmit={onCombineServices}
          />
        </ModalCard>
      )}

      {showOptions && (
        <ExtraOptions
          serviceCategory={selectedServices[0].category}
          onSubmit={handleExtraOptionSelect}
          onClose={() => setShowOptions(false)}
        />
      )}
    </div>
  );
};

/* eslint import/no-anonymous-default-export: [2, {"allowArrowFunction": true}] */
export default ({ pathId }: { pathId?: string }) => {
  const [data, setData] = useState<ILocationJsonResult | undefined>();
  const [isLoaded, setLoaded] = useState(false);
  const params = useParams();
  let id = pathId ? pathId : params.pathId;

  // Manual redirect for koreatown until seo settled
  if (id === "koreatown") {
    id = "mid-wilshire";
  }

  useEffect(() => {
    const loadLocation = async (_pathId: string) => {
      try {
        const result = await EditionJson.getLocation(_pathId);
        setLoaded(true);
        setData(result);
      } catch (error) {
        console.error(error);
        setLoaded(true);
      }
    };
    loadLocation(id as string);
  }, [params]);

  if (!isLoaded) return <PageLoading />;
  if (!data) return <NotFound />;
  return <SalonView data={data} />;
};
