import { Typography } from "antd";
import React from "react";
import { ParallaxBanner } from "react-scroll-parallax";
import { BannerLayer } from "react-scroll-parallax/dist/components/ParallaxBanner/types";

interface ISalonBannerProps {
  banners: string[];
}
const SalonBanner = ({ banners }: ISalonBannerProps) => {
  const locationImage: BannerLayer = {
    shouldAlwaysCompleteAnimation: true,
    scale: [1, 1.2],
    children: (
      <div className="columns is-gapless">
        {banners.map((banner, index) => {
          return (
            <div
              className={
                index + 1 === banners.length
                  ? "column is-hidden-mobile"
                  : "column"
              }
              key={index}
            >
              <div
                className="parallax-banner-img"
                style={{
                  backgroundImage: `url(${banner})`,
                }}
              ></div>
            </div>
          );
        })}
      </div>
    ),
  };
  return (
    <div className="container is-max-desktop">
      <ParallaxBanner
        layers={[locationImage]}
        className="parallax-banner-container"
      ></ParallaxBanner>
    </div>
  );
};

export default SalonBanner;
