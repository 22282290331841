import React, { useEffect, useState } from "react";
import { EditionJson } from "../../api";
import AppContentWrapper from "../../components/AppContentWrapper";
import { Link } from "react-router-dom";
import PageLoading from "../../components/PageLoading";
import PageLoadingError from "../../components/PageLoadingError";
import { MetaTagWrapper } from "../../components/MetaTags";
import { Col, Row, Typography, Image } from "antd";
import { convertToCloudFareUrl } from "../../api/cloudfare";
import { helmetJsonLdProp } from "react-schemaorg";
import { CreativeWork } from "schema-dts";

const Photos = () => {
  const [photos, setPhotos] = useState<any[]>([]);
  const [isLoading, setLoading] = useState(true);
  const [error, setError] = useState("");

  useEffect(() => {
    const loadPhotos = async () => {
      try {
        const res = await EditionJson.getPhotos();
        setLoading(false);
        setPhotos(res);
      } catch (error: any) {
        setLoading(false);
        setError(error.message);
      }
    };
    loadPhotos();
  }, []);

  const onChangePreview = (photoId: string[]) => {
    window.history.replaceState(null, "", `/photos/${photoId}`);
  };

  const onClosePreview = () => {
    window.history.replaceState(null, "", `/photos`);
  };

  if (isLoading) return <PageLoading />;
  if (error) return <PageLoadingError />;

  const articleMetaData = helmetJsonLdProp<CreativeWork>({
    "@context": "https://schema.org",
    "@type": "CollectionPage",
    name: "Photo Gallery",
    description: "Hair style gallery by Eddie's Edition Team",
    genre: "Beauty",
    publisher: {
      "@type": "Organization",
      name: "Eddie's Edition",
      logo: {
        "@type": "ImageObject",
        url: "https://eddiesedition.s3-us-west-2.amazonaws.com/images/logos/logo.png",
      },
    },
    mainEntityOfPage: {
      "@type": "WebPage",
      "@id": "https://www.eddiesedition.com/photos",
    },
  });

  return (
    <>
      <MetaTagWrapper
        title="Work Photos | Eddie's Edition"
        description="Striking Work Photos by Eddie's Edition Team"
        type="website"
        url="https://www.eddiesedition.com/photos"
        scripts={[articleMetaData]}
      />
      {/* <SRLWrapper elements={lightBoxImages} options={options} /> */}
      <AppContentWrapper>
        <Typography.Title level={3}>Photos</Typography.Title>
        <Typography.Paragraph>
          Discover Team Edition: Striking Work Photos by Our Talented Team.
        </Typography.Paragraph>

        <Image.PreviewGroup
          preview={{
            toolbarRender: (el, info) => {
              const obj = photos[info.current];
              return (
                <Link to={`/stylists/${obj.profile.pathId}`}>
                  <Typography.Text strong className="has-text-white">
                    by {obj.profile.name}
                  </Typography.Text>
                </Link>
              );
            },
            onChange: (current) => {
              onChangePreview(photos[current]._id);
            },
            onVisibleChange: (visible) => {
              if (!visible) {
                onClosePreview();
              }
            },
          }}
        >
          <Row gutter={[20, 20]}>
            {photos.map((photo: any, index) => {
              const img = convertToCloudFareUrl(photo.id);
              return (
                <Col key={img} xs={24} sm={6}>
                  <Link
                    to={`/photos/${photo._id}`}
                    onClick={(e) => {
                      e.preventDefault();
                      onChangePreview(photo._id);
                    }}
                  >
                    <Image
                      width={"100%"}
                      height={280}
                      src={img}
                      preview={{ mask: <div></div> }}
                      // onClick={() => console.log(photo._id)}
                    />
                  </Link>
                  <div className="has-text-right">
                    by{" "}
                    <Link to={`/stylists/${photo.profile.pathId}`}>
                      <Typography.Text strong>
                        {photo.profile.name}
                      </Typography.Text>
                    </Link>
                  </div>
                </Col>
              );
            })}
          </Row>
        </Image.PreviewGroup>
      </AppContentWrapper>
    </>
  );
};

export default Photos;
