import React from "react";
import { MetaTagWrapper } from "../../../../../components/MetaTags";
import { helmetJsonLdProp } from "react-schemaorg";
import { HairSalon } from "schema-dts";
import { ILocationModel } from "../../../../../models";

const SEO = ({ salon }: { salon: ILocationModel }) => {
  const title = `${salon.name} | Eddie's Edition`;
  const shareUrl = `https://www.eddiesedition.com/appointment/${salon.pathId}`;
  return (
    <MetaTagWrapper
      title={title}
      description={
        salon.description
          ? salon.description
          : `Address: ${salon.address}, ${salon.city}, ${salon.state} ${salon.zipcode}. Use our realtime online scheduler for your haircut appointment.`
      }
      type="website"
      url={shareUrl}
      canonical={true}
      scripts={[
        helmetJsonLdProp<HairSalon>({
          "@context": "https://schema.org",
          "@type": "HairSalon",
          name: title,
          logo: "https://eddiesedition.s3-us-west-2.amazonaws.com/images/logos/logo.png",
          address: {
            "@type": "PostalAddress",
            streetAddress: salon.address,
            postalCode: salon.zipcode,
            addressLocality: salon.city,
            addressRegion: salon.state,
          },
          mainEntityOfPage: "https://www.eddiesedition.com/appointment",
          slogan: "Good Haircuts Only",
          branchCode: salon.pathId,
          priceRange: "$$",
          url: `https://www.eddiesedition.com/appointment/${salon.pathId}`,
        }),
      ]}
    />
  );
};

export default SEO;
