import { Button, Divider, List, Space, Typography } from "antd";
import React from "react";
import { DefaultServiceType } from "../../../../../api/consts";

interface ICombineServicesProps {
  defaultServices: DefaultServiceType[];
  selectedServices: DefaultServiceType[];
  onSubmit: (services: DefaultServiceType[]) => void;
}
const CombineServices = (props: ICombineServicesProps) => {
  const [selectedServices, setSelectedServices] = React.useState(
    props.selectedServices
  );
  const onAddService = (service: DefaultServiceType) => {
    setSelectedServices([...selectedServices, service]);
  };
  const onRemoveService = (service: DefaultServiceType) => {
    const indexToRemove = selectedServices.findIndex(
      (selectedService) => selectedService.category === service.category
    );

    if (indexToRemove !== -1) {
      const updatedServices = [...selectedServices];
      updatedServices.splice(indexToRemove, 1);
      setSelectedServices(updatedServices);
    }
  };

  return (
    <>
      <Typography.Paragraph>
        Please be aware that service prices vary based on the stylist. You'll
        see the exact price in the next step.
      </Typography.Paragraph>
      <List
        itemLayout="horizontal"
        dataSource={props.defaultServices}
        renderItem={(item) => {
          let cnt = 0;
          selectedServices.forEach((service) => {
            if (service.category === item.category) cnt++;
          });
          return (
            <List.Item>
              <List.Item.Meta
                title={item.title}
                description={item.description}
              />
              <div>
                <Typography.Paragraph className="has-text-centered" strong>
                  {cnt}
                </Typography.Paragraph>
                <Space>
                  <Button onClick={() => onAddService(item)} size="small">
                    +
                  </Button>
                  <Button onClick={() => onRemoveService(item)} size="small">
                    -
                  </Button>
                </Space>
              </div>
            </List.Item>
          );
        }}
      />
      <Divider />
      <Button
        disabled={selectedServices.length === 0}
        onClick={() => props.onSubmit(selectedServices)}
        type="primary"
        block
      >
        Find openings
      </Button>
    </>
  );
};

export default CombineServices;
