import React, { useEffect, useState } from "react";
import StylistCard from "./components/StylistCard";
import TimeButton from "../../../../components/TimeButton";
import NoTimeMessage from "./components/NoTimeMessage";
import {
  checkIfConsultationRequired,
  removePastTimeSlots,
} from "../../../../../helpers";
import {
  ILocationTimeSlotsJson,
  IServiceForOpening,
} from "../../../../../api/types";
import { EditionJson } from "../../../../../api";
import { Button, Col, Divider, Row, Space, Typography } from "antd";
import SpecialServiceMessage from "../SpecialServiceMessage";
import { convertToCloudFareUrl } from "../../../../../api/cloudfare";
import { DefaultServiceType } from "../../../../../api/consts";
import _ from "underscore";
import { RedoOutlined } from "@ant-design/icons";
import {
  ApppointmentServiceType,
  useApp,
} from "../../../../../hooks/useAppointment";

interface IProps {
  date: string;
  locationName?: string;
  locationId: string;
  services: DefaultServiceType[];
  refreshCount: number;
  onAfterAppointmentSubmited?: (appointmentId: string) => void;
  onRefresh?: () => void;
}

const DisplaySchedules = ({
  date,
  locationName,
  locationId,
  services,
  refreshCount,
  onAfterAppointmentSubmited,
  onRefresh,
}: IProps) => {
  const { openAppointmentForm } = useApp();
  const [isLoading, setLoading] = useState(true);
  const [stylists, setStylists] = useState<ILocationTimeSlotsJson[]>([]);

  const onTimeSlotClick = (
    userId: string,
    time: string,
    stylistName: string,
    scheduleId: string,
    stylistServices: IServiceForOpening[]
  ) => {
    const combinedServices: ApppointmentServiceType[] = [];
    services.forEach((service) => {
      const stylistService = _.find(stylistServices, {
        category: service.category,
      });
      if (stylistService) {
        combinedServices.push({
          title: stylistService.title,
          description: stylistService.description,
          up: stylistService.up,
          price: stylistService.price,
          duration: stylistService.duration,
          hasPayAdvance: stylistService.hasPayAdvance,
          discount: stylistService.discount,
          category: service.category,
          option: service.option,
        });
      }
    });
    openAppointmentForm({
      userId,
      stylistName,
      locationName,
      scheduleId,
      services: combinedServices,
      time,
      date,
      onAfter: onAfterAppointmentSubmited,
    });
  };
  useEffect(() => {
    let isMounted = true; // Flag to track mounting status

    (async () => {
      setLoading(true);
      setStylists([]);
      try {
        const result = await EditionJson.getLocationOpeningsByServices(
          locationId,
          date,
          services.map((service) => ({
            category: service.category,
            option: service.option?.id,
          }))
        );
        console.log(result);
        if (isMounted) {
          setStylists(result);
        }
      } catch (e) {
        console.error(e);
      }
      if (isMounted) {
        setLoading(false);
      }
    })();

    return () => {
      isMounted = false; // Set flag to false when the component unmounts
    };
  }, [locationId, date, services, refreshCount]);

  if (isLoading) {
    return (
      <Typography.Paragraph>
        Just a moment, we're gathering available time slots for you...
      </Typography.Paragraph>
    );
  }

  // Remove past time slots from openings
  let openingCount = 0;

  stylists.forEach((stylist) => {
    stylist.openings = removePastTimeSlots(stylist.openings, date);
    openingCount = stylist.openings.length + openingCount;
    return stylist;
  });

  let isConsultationRequired = false;

  services.forEach((service) => {
    isConsultationRequired = checkIfConsultationRequired(service.category);
  });

  if (isConsultationRequired)
    return (
      <SpecialServiceMessage
        locationId={locationId}
        serviceCategories={services.map((service) => service.category)}
      />
    );

  return (
    <Space direction="vertical" style={{ width: "100%" }} size="middle">
      {stylists.map(
        ({ userId, profile, openings, schedule, services, onVacation }, i) => {
          const { name, pathId, photoId, bio } = profile;
          return (
            <Space key={i} direction="vertical" style={{ width: "100%" }}>
              <StylistCard
                bio={bio}
                profileImage={convertToCloudFareUrl(photoId)}
                stylistId={pathId}
                name={name}
                services={services}
                onVacation={onVacation}
                isFullyBooked={openings.length === 0}
              />
              {!onVacation && (
                <Row gutter={[5, 5]}>
                  {openings.map((time: string, i) => {
                    return (
                      <Col key={`${time}-${i}`} span={6}>
                        <TimeButton
                          onClick={() => {
                            onTimeSlotClick(
                              userId,
                              time,
                              profile.name,
                              schedule._id,
                              services
                            );
                          }}
                          time={time}
                          isSelected={false}
                        />
                      </Col>
                    );
                  })}
                </Row>
              )}
            </Space>
          );
        }
      )}
      {openingCount === 0 && !isConsultationRequired && <NoTimeMessage />}
      {onRefresh && (
        <div className="has-text-right">
          <Divider />
          <Button
            onClick={onRefresh}
            type="dashed"
            size="small"
            icon={<RedoOutlined />}
          >
            Refresh Openings
          </Button>
        </div>
      )}
    </Space>
  );
};

export default DisplaySchedules;
