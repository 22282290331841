import { DEFAULT_LOCATIONS, defaultServices } from "./consts";

export function getLocationIdByPathId(pathId: string) {
  const location = DEFAULT_LOCATIONS.find(
    (location) => location.pathId === pathId
  );
  return location ? location._id : null;
}

export const getServicesByCategories = (categories: number[]) => {
  return defaultServices.filter((service) =>
    categories.includes(service.category)
  );
};
