import React from "react";
import { ILocationParking } from "../../../../../models";
import { Typography } from "antd";
const DisplayParking = ({ data }: { data: ILocationParking }) => {
  return (
    <Typography.Link
      type="secondary"
      href={data.map}
      target="_blank"
      rel="noreferrer"
    >
      [map]
    </Typography.Link>
  );
};

export default DisplayParking;
