import React, { useState } from "react";
import TimeButton from "../../../../components/TimeButton";
import { EditionJson } from "../../../../../api";
import {  IUserJson } from "../../../../../api/types";
import { Col, Row, Space, Typography } from "antd";
import dayjs from "dayjs";
import { DATE_FORMAT } from "../../../../../consts";
import { ApppointmentServiceType, useApp } from "../../../../../hooks/useAppointment";

interface IProps {
  stylist: IUserJson;
  date: string;
  services: ApppointmentServiceType[];
  duration: number;
}

const DisplayNextOpenings = ({ stylist, date, duration, services }: IProps) => {
  const { openAppointmentForm } = useApp();
  const [isLoading, setLoading] = useState(false);
  const [data, setData] = useState<object[]>([]);

  const onSearch = async () => {
    // Adjust the date if it's today
    let _date = date;
    if (dayjs(date).isSame(dayjs(), "day")) {
      _date = dayjs(date).add(1, "days").format(DATE_FORMAT);
    }

    setLoading(true);
    try {
      const res = await EditionJson.getUserNextOpeningsByDuration(
        stylist.profile.pathId,
        _date,
        duration
      );
      setData(res);
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  };

  if (isLoading) {
    return (
      <Typography.Paragraph>
        Just a moment, we're gathering available time slots for you...
      </Typography.Paragraph>
    );
  }

  return (
    <>
      {data.length > 0 ? (
        <Space direction="vertical" size="middle" style={{ width: "100%" }}>
          {data.map((item: any, i: number) => {
            return (
              <Space direction="vertical" style={{ width: "100%" }} key={i}>
                <Typography.Text strong>
                  {item.date} ({item.schedule.location.name})
                </Typography.Text>
                <Row gutter={[5, 5]}>
                  {item.openings.map((time: string, i: number) => {
                    return (
                      <Col key={`${time}-${i}`} span={8}>
                        <TimeButton
                          onClick={() => {
                            openAppointmentForm({
                              userId: stylist._id,
                              stylistName: stylist.profile.name,
                              scheduleId: item.schedule._id,
                              locationName: item.schedule.location.name,
                              services,
                              time,
                              date: item.date,
                            });
                          }}
                          time={time}
                          isSelected={false}
                        />
                      </Col>
                    );
                  })}
                </Row>
              </Space>
            );
          })}
        </Space>
      ) : (
        <div>
          <Typography.Paragraph>
            No openings on selected days. Please choose different days.
          </Typography.Paragraph>
          <button
            className="button is-small is-info is-light"
            onClick={onSearch}
          >
            Next availability
          </button>
        </div>
      )}
    </>
  );
};

export default DisplayNextOpenings;
