export const DEFAULT_GEO = {
  los_angeles: {
    desktop: {
      zoom: 12,
      // coordinates: [34.094408990393376, -118.1452861631164],
      coordinates: [34.08218274593314, -118.20880087258905],
    },
    mobile: {
      zoom: 11,
      // coordinates: [34.12366794610277, -118.11069097750834],
      coordinates: [34.0759891866787, -118.21012314789942],
    },
  },
};
