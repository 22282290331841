import React from "react";
interface ILevelProps {
  isMobile?: boolean;
  left?: any;
  right?: any;
  className?: string;
}
const Level = ({ isMobile, left, right, className }: ILevelProps) => {
  return (
    <div
      className={`level is-marginless ${isMobile ? "is-mobile" : ""} ${
        className ? className : ""
      }`}
    >
      <div className="level-left">
        <div className="level-item">{left}</div>
      </div>
      <div className="level-right">
        <div className="level-item">{right}</div>
      </div>
    </div>
  );
};

export default Level;
