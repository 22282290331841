import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import MobileMenuBarButton from "./components/MobileMenuBarButton";
import AppMenu, { appRoutes } from "../AppMenu";
import { LOGO_SRC } from "../../consts";
import { Drawer, Menu } from "antd";

const Header = () => {
  const navigate = useNavigate();
  const [isSidebarActive, setSidebarActive] = useState(false);
  const onLinkClick = (v: any) => {
    setSidebarActive(false);
    navigate(v.key);
  };
  return (
    <nav className={"navbar is-fixed-top is-white"}>
      <div className="container">
        <div className="navbar-brand">
          <Link
            to="/"
            onClick={() => setSidebarActive(false)}
            className="navbar-item"
          >
            <img
              style={{ maxHeight: "2.5rem" }}
              alt="Eddie's Edition"
              src={LOGO_SRC}
            />
          </Link>
          <MobileMenuBarButton
            isSidebarActive={isSidebarActive}
            onClick={() => {
              if (!isSidebarActive) {
                setSidebarActive(true);
              } else {
                setSidebarActive(false);
              }
            }}
          />
        </div>
        <div className="navbar-menu">
          <div className="navbar-end">
            <AppMenu closeSidebar={() => setSidebarActive(false)} />
          </div>
        </div>
      </div>

      <Drawer
        key="sidebar"
        width={200}
        placement="left"
        closeIcon={null}
        open={isSidebarActive}
        onClose={() => setSidebarActive(false)}
        zIndex={2}
        bodyStyle={{ padding: 0 }}
      >
        <div className="content-wrapper">
          <Menu
            theme={"light"}
            onClick={onLinkClick}
            mode="inline"
            items={appRoutes.map((link, i) => {
              return {
                key: link.path,
                label: link.label,
              };
            })}
          />
        </div>
      </Drawer>
    </nav>
  );
};

export default Header;
