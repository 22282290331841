import React from "react";
import { Link } from "react-router-dom";
import { BrowseContext } from "../../contexts";
import { EventActionTypes } from "../../types";
import { Avatar, Space, Typography } from "antd";
import { EnvironmentOutlined } from "@ant-design/icons";

interface PlaceCardProps {
  date: string;
  name: string;
  pathId: string;
  address: string;
  image?: string;
}
const PlaceCard = ({ name, pathId, address, image }: PlaceCardProps) => {
  const { state, dispatch } = React.useContext(BrowseContext);

  const onHover = () => {
    dispatch({
      type: EventActionTypes.OnCard,
      payload: { id: pathId },
    });
  };
  const offHover = () => {
    dispatch({
      type: EventActionTypes.OffCard,
    });
  };
  const isHovered = pathId === state.events.idOnMarker;
  return (
    <div
      id={`place-${pathId}`}
      onMouseEnter={onHover}
      onMouseLeave={offHover}
      className={`place-card ${isHovered ? "is-hovered" : ""}`}
    >
      <Space>
        <Link to={`/appointment/${pathId}`}>
          <Avatar shape="square" src={image} size={64} />
        </Link>

        <Link to={`/appointment/${pathId}`}>
          <Typography>
            <Typography.Title level={5}>
              {isHovered && (
                <span className="icon has-text-danger">
                  <EnvironmentOutlined />
                </span>
              )}
              <span>{name}</span>
            </Typography.Title>

            <Typography.Text>
              <small>{address}</small>
            </Typography.Text>
          </Typography>
        </Link>
      </Space>
    </div>
  );
};

export default PlaceCard;
