import { Typography } from "antd";
import React from "react";

const NoTimeMessage = () => {
  return (
    <Typography.Text>
      Apologies, we're fully booked at this location for the chosen dates.
      Please consider exploring other dates or locations.
    </Typography.Text>
  );
};

export default NoTimeMessage;
