import React from "react";
import { formatDatetime } from "../../../helpers";
import { Typography } from "antd";
import { useApp } from "../../../hooks/useAppointment";

const { Paragraph, Text } = Typography;

const AppointmentReview = () => {
  const { appointmentForm } = useApp();
  if (!appointmentForm) return null;
  const datetime = formatDatetime(appointmentForm.date, appointmentForm.time);
  return (
    <>
      <Typography>
        <Paragraph>
          <Text strong>Stylist:</Text>{" "}
          <Text>{appointmentForm.stylistName}</Text>
        </Paragraph>
        <Paragraph>
          <Text strong>Service:</Text>

          {appointmentForm.services.map((service: any, i: any) => {
            return (
              <div key={`service-${i}`}>
                <Text>
                  {service.title} &middot;&middot;&middot;{" "}
                  <span
                    style={{
                      textDecoration: service.discount
                        ? "line-through"
                        : "none",
                    }}
                  >
                    ${service.price}
                    {service.up ? "+" : ""}
                  </span>
                  {service.discount && (
                    <>
                      {"  "}
                      <span className="has-text-danger">
                        ${service.price - service.discount.discountAmount}
                      </span>
                    </>
                  )}
                </Text>
                {service.description && (
                  <>
                    <br />
                    <Text type="secondary" italic>
                      <small>{service.description}</small>
                    </Text>
                  </>
                )}
              </div>
            );
          })}
        </Paragraph>
        {}
        <Paragraph>
          <Text strong>At:</Text> <Text>{datetime}</Text>
        </Paragraph>
        {appointmentForm.locationName && (
          <Paragraph>
            <Text strong>Location: </Text>{" "}
            <Text>{appointmentForm.locationName}</Text>
          </Paragraph>
        )}
      </Typography>
    </>
  );
};

export default AppointmentReview;
