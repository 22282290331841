import React, { useEffect, useState } from "react";
import {
  FrownOutlined,
  GoogleOutlined,
  MehOutlined,
  SmileOutlined,
} from "@ant-design/icons";
import { Button, Divider, Rate, Space, Typography, Input, message } from "antd";
import AppContentWrapper from "../../components/AppContentWrapper";
import { useParams } from "react-router-dom";
import { EditionJson } from "../../api";
import PageLoading from "../../components/PageLoading";
import NotFound from "../../components/NotFound";
import { extractErrorMessage } from "../../helpers";
import YelpIcon from "../../components/YelpIcon";
const { Paragraph } = Typography;
const { TextArea } = Input;

const customIcons: Record<number, React.ReactNode> = {
  1: <FrownOutlined rev={undefined} />,
  2: <FrownOutlined rev={undefined} />,
  3: <MehOutlined rev={undefined} />,
  4: <SmileOutlined rev={undefined} />,
  5: <SmileOutlined rev={undefined} />,
};

const AppointmentFeedback = () => {
  const { appointmentId } = useParams<{ appointmentId: string }>();
  const [hasFeedback, setFeedback] = useState(false);
  const [stylistName, setStylistName] = useState("");
  const [isLoading, setLoading] = useState(true);
  const [isSubmitting, setSubmitting] = useState(false);
  const [error, setError] = useState<string | undefined>(undefined);
  const [askReview, setAskReview] = useState(false);
  const [links, setLinks] = useState<
    { yelp: string; google: string } | undefined
  >();
  const [value, setValue] = useState({
    note: "",
    time: 0,
    quality: 0,
    service: 0,
  });

  useEffect(() => {
    const load = async (_appointmentId: string) => {
      setLoading(true);
      try {
        const _res = await EditionJson.getFeedback(_appointmentId);
        setStylistName(_res.stylistName);
        setFeedback(_res.hasFeedback);
        setLinks(_res.links);
        if (_res.askReview) {
          setAskReview(true);
        }
      } catch (error) {
        console.error(error);
        setError(extractErrorMessage(error));
      }
      setLoading(false);
    };

    if (appointmentId) {
      load(appointmentId);
    }
  }, [appointmentId]);

  const onSubmit = async () => {
    if (appointmentId) {
      setSubmitting(true);
      try {
        const totalRates = value.quality + value.service + value.time;

        await EditionJson.createFeedback({
          appointmentId,
          note: value.note,
          quality: value.quality.toString(),
          service: value.service.toString(),
          time: value.time.toString(),
        });

        if (totalRates >= 12) {
          setAskReview(true);
        }
        setFeedback(true);
      } catch (error) {
        message.error(extractErrorMessage(error));
      }
      setSubmitting(false);
    }
  };

  if (isLoading) return <PageLoading />;
  if (error) return <NotFound />;

  return (
    <AppContentWrapper>
      <Typography.Title level={4}>Thanks for choosing us!</Typography.Title>
      {hasFeedback ? (
        <>
          {askReview ? (
            <>
              <Paragraph>
                Thank you for your positive feedback! If you've enjoyed our
                services, we'd greatly appreciate it if you could also leave us
                a review on Yelp or Google. As a token of our gratitude, we'll
                gift you our styling paste on your next visit.
              </Paragraph>
              <Space>
                {links && links.yelp && (
                  <Button
                    icon={<YelpIcon />}
                    target="_blank"
                    href={links.yelp}
                    rel="noreferrer"
                    type="dashed"
                  >
                    Yelp
                  </Button>
                )}
                {links && links.google && (
                  <Button
                    icon={<GoogleOutlined />}
                    target="_blank"
                    href={links.google}
                    rel="noreferrer"
                    type="dashed"
                  >
                    Google
                  </Button>
                )}
              </Space>
            </>
          ) : (
            <Typography.Text>
              Your feedback has been submitted. Thank you! We look forward to
              seeing you again.
            </Typography.Text>
          )}
        </>
      ) : (
        <>
          <Paragraph>
            We'd love to hear about your experience with {stylistName}. Your
            insights will help us improve. Please take a moment to share your
            thoughts.
          </Paragraph>
          <Space direction="vertical" style={{ width: "100%" }}>
            <div>
              <Typography.Title level={5}>Wait Time</Typography.Title>
              <Rate
                style={{ color: "#000" }}
                value={value.time}
                //@ts-ignore
                character={({ index }: { index: number }) =>
                  customIcons[index + 1]
                }
                onChange={(time: any) => {
                  setValue({ ...value, time });
                }}
              />
            </div>
            <div>
              <Typography.Title level={5}>Haircut Quality</Typography.Title>
              <Rate
                style={{ color: "#000" }}
                value={value.quality}
                //@ts-ignore
                character={({ index }: { index: number }) =>
                  customIcons[index + 1]
                }
                onChange={(quality: any) => {
                  setValue({ ...value, quality });
                }}
              />
            </div>
            <div>
              <Typography.Title level={5}>Customer Service</Typography.Title>
              <Rate
                style={{ color: "#000" }}
                value={value.service}
                //@ts-ignore
                character={({ index }: { index: number }) =>
                  customIcons[index + 1]
                }
                onChange={(service: any) => {
                  setValue({ ...value, service });
                }}
              />
            </div>
            <div>
              <Typography.Title level={5}>Note</Typography.Title>
              <TextArea
                value={value.note}
                onChange={(e) => {
                  setValue({ ...value, note: e.target.value });
                }}
              />
            </div>
          </Space>
          <Divider />
          <Button loading={isSubmitting} type="primary" onClick={onSubmit}>
            Submit
          </Button>
        </>
      )}
    </AppContentWrapper>
  );
};

export default AppointmentFeedback;
