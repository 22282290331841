import React from "react";
import { COMB_LOGO_SRC } from "../../consts";
const PageLoading = () => {
  return (
    <div
      style={{ height: "100vh"}}
      className="is-flex is-align-items-center is-justify-content-center"
    >
      <img alt="E/E Logo" className="rotate" width="45" src={COMB_LOGO_SRC} />
    </div>
  );
};

export default PageLoading;
