import { ActionMap, PlaceActionTypes } from "../../types";
import { EventAction } from "./events";
import {ILocationModel} from "../../../../../models";

type Payload = {
  [PlaceActionTypes.onClick]: ILocationModel;
  [PlaceActionTypes.onClose]: undefined;
};

export type IPlaceState = ILocationModel | undefined;

export type PlaceAction = ActionMap<Payload>[keyof ActionMap<Payload>];

export const placeReducer = (
  state: IPlaceState,
  action: PlaceAction | EventAction
) => {
  switch (action.type) {
    case PlaceActionTypes.onClick:
      return {
        ...action.payload,
      };
    case PlaceActionTypes.onClose:
      return undefined;
    default:
      return state;
  }
};
