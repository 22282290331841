import React from "react";
import Helmet from "react-helmet";
import PageTitle from "../components/PageTitle";
import AppContentWrapper from "../../components/AppContentWrapper";

const Careers = () => {
  return (
    <>
      <AppContentWrapper>
        <Helmet>
          <title>Jobs | Eddie's Edition</title>
          <meta
            name="description"
            content="Join the team at Eddie's Edition! We're on the hunt for men's haircut specialists passionate about their craft. Enjoy a supportive work environment, growth opportunities, and continuous training. Apply now or email your resume to join our dedicated crew."
          />
        </Helmet>

        <PageTitle title="Join our team" />
        <p className="subtitle is-6">
          We are looking for men's haircut specialists. We provides a great work
          environment and opportunities for growth to all our staff.
          <br /> In addition, We provide in-house training and continuing
          education opportunities. Fill out the application below or email your
          resume to info@eddiesedition.com
        </p>
        <a
          className="button is-black"
          href={
            "https://docs.google.com/forms/d/1R8PmEUvLRuf5QBwxEJXcQCH5nHSl888Hmv0imjRiGu8"
          }
          target="_blank"
          rel="noreferrer"
        >
          Apply now
        </a>
      </AppContentWrapper>
    </>
  );
};

export default Careers;
