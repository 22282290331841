import React from "react";
import { Route, Routes } from "react-router-dom";

import Landing from "./scenes/Landing";
import StylistList from "./scenes/Stylists/StylistList";
import StylistView from "./scenes/Stylists/StylistView";
import Salons from "./scenes/Salons/Salons";
import SalonView from "./scenes/Salons/SalonView";
import AppointmentReview from "./scenes/Salons/AppointmentReview";
import Photos from "./scenes/Photos";
import Shop from "./scenes/Shop";
import Services from "./scenes/Services";
import ContactUs from "./scenes/Contact";
import Careers from "./scenes/Careers";
import Blog from "./scenes/Blog";
import Promotions from "./scenes/Promotions";
import Terms from "./scenes/Terms";
import NotFound from "./components/NotFound";
import ManageAppointments from "./scenes/ManageAppointments";
import Receptions from "./scenes/Receptions";
import AppointmentFeedback from "./scenes/Feedback";
import HairModel from "./scenes/HairModel";
import AboutUs from "./scenes/AboutUs";

const AppRoutes = () => {
  return (
    <>
      <Routes>
        <Route path={"/"} element={<Landing />} />
        <Route path={"/stylists"} element={<StylistList />} />
        <Route path={"/stylists/:pathId"} element={<StylistView />} />
        <Route path={"/appointment"} element={<Salons />} />
        <Route path={"/appointment/:pathId"} element={<SalonView />} />
        <Route
          path={"/appointment/review/:appointmentId"}
          element={<AppointmentReview />}
        />
        <Route
          path={"/appointment/manage/:phoneNumber"}
          element={<ManageAppointments />}
        />
        <Route
          path={"/appointment/feedback/:appointmentId"}
          element={<AppointmentFeedback />}
        />
        <Route path={"/photos/*"} element={<Photos />} />
        <Route path={"/services"} element={<Services />} />
        <Route path={"/shop/*"} element={<Shop />} />
        <Route path={"/blog/*"} element={<Blog />} />
        <Route path={"/terms-and-privacy"} element={<Terms />} />
        {/* <Route path={"/loyalty-program"} element={<LoyaltyProgram />} /> */}
        <Route path={"/about-us"} element={<AboutUs />} />
        <Route path={"/contact"} element={<ContactUs />} />
        <Route path={"/careers"} element={<Careers />} />
        <Route path={"/hair-model-wanted"} element={<HairModel />} />
        <Route path={"/events"} element={<Promotions />} />
        <Route path={"/receptions/:locationPathId"} element={<Receptions />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </>
  );
};

export default AppRoutes;
