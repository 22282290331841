import React from "react";
import {
  INewAppointmentArgs,
  IPathState,
  IAppointmentRouteState,
  RESCHEDULE_LIST,
} from "./index";
import { Button, Divider, Space, Spin } from "antd";
interface IProps {
  routeState: IAppointmentRouteState;
  onRouteChange: (path: IPathState, state: any) => void;
  onCreate: (args: INewAppointmentArgs) => void;
}
const RescheduleMain = ({ routeState, onRouteChange, onCreate }: IProps) => {
  const [isSubmitting, setSubmitting] = React.useState(false);
  const { upcomingAppointments, phoneNumber, client, paymentMethodId } =
    routeState;
  const handleNewAppointment = () => {
    setSubmitting(true);
    onCreate({
      firstName: client ? client.firstName : "Unknown",
      lastName: client ? client.lastName : "Unknown",
      phoneNumber,
      paymentMethodId,
      errorCallback: () => {
        setSubmitting(false);
      },
    });
  };

  const handleReschedule = () => {
    onRouteChange(RESCHEDULE_LIST, routeState);
  };
  if (isSubmitting) {
    return (
      <div className="has-text-centered">
        <Spin />
        <br />
        <br />
        Your appointment is being submitted. Please hold on a moment...
      </div>
    );
  }
  return (
    <>
      Looks like you already have {upcomingAppointments.length} upcoming
      appointment{upcomingAppointments.length > 1 ? "s" : ""}! Would you like to
      schedule a new one, or perhaps reschedule an existing one?
      <Divider />
      <Space direction="vertical" size="middle" style={{ width: "100%" }}>
        <Button
          block
          type="primary"
          disabled={isSubmitting}
          onClick={handleNewAppointment}
        >
          Make a new appointment
        </Button>
        <Button
          block
          type="default"
          disabled={isSubmitting}
          onClick={handleReschedule}
        >
          Reschedule
        </Button>
      </Space>
    </>
  );
};

export default RescheduleMain;
