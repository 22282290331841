import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Alert, Avatar, Button, Checkbox, Space, Typography } from "antd";

import {
  formatDate,
  formatPhoneNumber,
  getDatesBetween,
} from "../../../helpers";
import { EditionJson } from "../../../api";

import { MetaTagWrapper } from "../../../components/MetaTags";
import StylistMenu from "./components/DisplayStylistServices";
import AppContentWrapper from "../../../components/AppContentWrapper";
import DisplayLocation from "./components/DisplayLocations";
import StylistCalendar from "../../components/DateSelector";
import PageLoading from "../../../components/PageLoading";
import NotFound from "../../../components/NotFound";
import DisplaySchedules from "./components/DisplaySchedules";
import DisplaySlider from "../../components/DisplaySlider";

import { IServiceOption, IUserJson } from "../../../api/types";
import Level from "../../../components/Level";
import { DATE_FORMAT } from "../../../consts";
import ModalCard from "../../../components/ModalCard";
import MessageForm from "./components/MessageForm";
import CombineServices from "./components/CombineServices";
import SelectService from "./components/SelectService";
import dayjs from "dayjs";
import { MessageOutlined } from "@ant-design/icons";
import { convertToCloudFareUrl } from "../../../api/cloudfare";
import ExtraOptions from "./components/ExtraOptions";
import { ApppointmentServiceType, useApp } from "../../../hooks/useAppointment";

interface IProps {
  data: IUserJson;
}

export interface IStylistService extends ApppointmentServiceType {
  options?: IServiceOption[];
}

const StylistView = ({ data }: IProps) => {
  const { date, setDate } = useApp();

  const [refreshCount, setRefreshCount] = React.useState(0);
  const [selectedServices, setSelectedServices] = useState<IStylistService[]>(
    data.services ? [data.services[0]] : []
  );
  const [messageFormActive, setMessageFormActive] = useState(false);
  const [showOptions, setShowOptions] = useState(false);
  const [isCustomServiceModalActive, setCustomServiceModalActive] =
    useState(false);
  const dateString = formatDate(date);
  const shareUrl = `https://www.eddiesedition.com/stylists/${data.profile.pathId}`;

  const workDays: number[] = [];
  data.schedules.forEach(({ hours }) => {
    hours.forEach((hour: string[], index: number) => {
      if (hour[2] === "on") {
        if (!workDays.includes(index)) {
          workDays.push(index);
        }
      }
    });
  });

  const onCombineServices = (services: IStylistService[]) => {
    setSelectedServices(services);
    setCustomServiceModalActive(false);
  };
  const onServiceOptionClick = () => {
    setShowOptions(showOptions ? false : true);
  };

  /*
  This handles only one option at a time
  */
  const handleExtraOptionSelect = (op?: {
    serviceCategory: number;
    id: string;
    title: string;
    price: number;
    duration: number;
  }) => {
    const val = selectedServices[0];
    if (op) {
      setSelectedServices([
        {
          ...val,
          title: op.title,
          price: op.price,
          duration: op.duration,
          up: false, // options doesnt have UP value so we set it to false
          option: { id: op.id, title: op.title },
        },
      ]);
      setShowOptions(false);
    } else {
      const { option, ...rest } = val; // Destructure to separate `option` from the rest of `val`
      setSelectedServices([{ ...rest }]); // Set the selected service without the `option` property
    }
  };

  const vacations = data.vacations
    ? data.vacations.flatMap((vacation) =>
        getDatesBetween(vacation.startDate, vacation.endDate)
      )
    : [];

  const isTodayOnVacation = vacations.some(
    (_date) => dayjs(_date).format(DATE_FORMAT) === dateString
  );

  return (
    <div>
      <MetaTagWrapper
        title={`${data.profile.name} | Eddie's Edition`}
        description={`${data.profile.name} is a passionate and skilled hair stylist at Eddie's Edition. Discover their unique styling techniques and portfolio.`}
        type="website"
        url={shareUrl}
        canonical={true}
      />
      {data.profile.pathId === "carla" && (
        <div className="pb-5"><Alert type="warning" message="Due to a health issue, she will be taking a break for a few months. We appreciate your understanding and support during this time" /></div>
      )}
      <div className="columns">
        <div className="column pt-0">
          <Space direction="vertical" style={{ width: "100%" }}>
            <Level
              isMobile
              left={
                <Space>
                  <Avatar
                    size="large"
                    src={convertToCloudFareUrl(data.profile.photoId)}
                  />
                  <Typography.Title level={4} className="is-marginless">
                    {data.profile.name}
                  </Typography.Title>
                </Space>
              }
              right={
                <>
                  <Button
                    size="small"
                    icon={<MessageOutlined />}
                    onClick={() => setMessageFormActive(true)}
                  />
                </>
              }
            />

            {data.profile.bio && (
              <Typography.Paragraph
                ellipsis={{ rows: 2, expandable: true, symbol: "more" }}
              >
                {data.profile.bio}
              </Typography.Paragraph>
            )}

            {data.photos && data.photos.length > 0 && (
              <DisplaySlider slides={3} images={data.photos} />
            )}
            <div>
              <Typography.Title level={5}>Location</Typography.Title>
              <DisplayLocation schedules={data.schedules} />
            </div>
            <div>
              <Typography.Title level={5}>Contact</Typography.Title>
              <Typography.Text>
                <a
                  className="has-text-grey-dark"
                  href={`tel:${
                    data.profile.businessPhone
                      ? data.profile.businessPhone
                      : data.profile.phone
                  }`}
                >
                  {formatPhoneNumber(
                    data.profile.businessPhone
                      ? data.profile.businessPhone
                      : data.profile.phone
                  )}
                </a>
              </Typography.Text>
            </div>
            <div>
              <Typography.Title level={5}>Services</Typography.Title>
              <StylistMenu services={data.services} />
            </div>
            {data.vacations.length > 0 && (
              <Typography>
                <Typography.Title level={5}>Vacations</Typography.Title>
                <ul>
                  {data.vacations.map((vacation, i) => {
                    return (
                      <li key={i}>
                        {vacation.startDate} - {vacation.endDate}
                      </li>
                    );
                  })}
                </ul>
              </Typography>
            )}
          </Space>
        </div>

        {workDays.length !== 0 && (
          <div className="column is-narrow pt-0">
            <div className="is-narrow-inner" style={{ width: "350px" }}>
              <Space
                direction="vertical"
                size="middle"
                style={{ width: "100%" }}
              >
                <div>
                  <Typography.Title level={5}>Appointment</Typography.Title>
                  <StylistCalendar
                    selectedDate={date}
                    setDate={setDate}
                    //TODO::Need to fix this
                    // filterDays={_.difference([0, 1, 2, 3, 4, 5, 6], workDays)}
                    filterDays={[]}
                    hasBorder={true}
                    excludedDates={vacations}
                  />
                </div>
                {data.services && data.services.length > 0 && (
                  <div>
                    <SelectService
                      selectedServices={selectedServices}
                      defaultServices={data.services}
                      setServices={setSelectedServices}
                      setCombineServicesModalActive={() => {
                        setCustomServiceModalActive(true);
                      }}
                    />
                  </div>
                )}

                {selectedServices[0] && selectedServices[0].option ? (
                  <Checkbox
                    checked={true}
                    onChange={() => handleExtraOptionSelect()}
                  >
                    <Typography.Text strong>Option:</Typography.Text>{" "}
                    <Typography.Text>
                      {selectedServices[0].option.title} - $
                      {selectedServices[0].price}
                    </Typography.Text>
                  </Checkbox>
                ) : (
                  <>
                    {selectedServices[0].options &&
                      selectedServices[0].options.length > 0 && (
                        <div>
                          <Checkbox
                            checked={showOptions}
                            onChange={onServiceOptionClick}
                          >
                            Do you need more specific options?
                          </Checkbox>
                        </div>
                      )}
                  </>
                )}

                <div>
                  <DisplaySchedules
                    isTodayOnVacation={isTodayOnVacation}
                    stylist={data}
                    date={dateString}
                    services={selectedServices}
                    refreshCount={refreshCount}
                    onRefresh={() => setRefreshCount(refreshCount + 1)}
                  />
                </div>
              </Space>
            </div>
          </div>
        )}
      </div>

      {messageFormActive && (
        <ModalCard title="" onClose={() => setMessageFormActive(false)}>
          <MessageForm
            userId={data._id}
            stylistName={data.profile.name}
            onClose={() => setMessageFormActive(false)}
          />
        </ModalCard>
      )}

      {isCustomServiceModalActive && (
        <ModalCard
          title="Build Services"
          onClose={() => setCustomServiceModalActive(false)}
        >
          <CombineServices
            services={data.services}
            selectedServices={selectedServices}
            onSubmit={onCombineServices}
          />
        </ModalCard>
      )}

      {
        // Show the extra options modal if `showOptions` is true
        showOptions &&
          selectedServices[0].options &&
          selectedServices[0].options.length > 0 && (
            <ExtraOptions
              serviceCategory={selectedServices[0].category}
              options={selectedServices[0].options}
              onSubmit={handleExtraOptionSelect}
              onClose={() => setShowOptions(false)}
            />
          )
      }
    </div>
  );
};

/* eslint import/no-anonymous-default-export: [2, {"allowArrowFunction": true}] */
export default () => {
  const { pathId } = useParams<{ pathId: string }>();
  const [data, setData] = useState<IUserJson | undefined>();
  const [isLoaded, setLoaded] = useState(false);
  useEffect(() => {
    const load = async (_stylistId: string) => {
      try {
        const _res = await EditionJson.getUser(pathId as string);
        setLoaded(true);
        setData(_res);
      } catch (error) {
        console.error(error);
        setLoaded(true);
      }
    };
    if (pathId) {
      load(pathId);
    }
  }, [pathId]);
  if (!isLoaded) return <PageLoading />;
  if (!data) return <NotFound />;

  return (
    <AppContentWrapper>
      <StylistView data={data} />
    </AppContentWrapper>
  );
};
