import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { IPhotosModel, IUserProfile } from "../../models";
import { EditionJson } from "../../api";
import { extractErrorMessage, stripHtml } from "../../helpers";
import { WENT_WRONG } from "../../consts";
import { helmetJsonLdProp } from "react-schemaorg";
import { Article, CreativeWork } from "schema-dts";
import { MetaTagWrapper } from "../../components/MetaTags";
import { Avatar, Button, Space, Typography } from "antd";
import { Image } from "antd";
import { convertToCloudFareUrl } from "../../api/cloudfare";
import {
  LeftCircleOutlined,
  LeftOutlined,
  RightCircleOutlined,
} from "@ant-design/icons";

const PhotoPage = () => {
  const { path } = useParams();
  const [data, setData] = useState<
    | (IPhotosModel & {
        user: IUserProfile;
        previousPhotoId: string;
        nextPhotoId: string;
      })
    | null
  >(null);
  const [isLoading, setLoading] = useState(true);
  const [error, setError] = useState("");

  useEffect(() => {
    (async () => {
      console.log(path);
      if (path) {
        try {
          const res = await EditionJson.getPhoto(path as string);
          console.log(res);
          setData(res);
        } catch (error) {
          setError(extractErrorMessage(error));
          console.error(error);
        } finally {
          setLoading(false);
        }
      }
    })();
  }, [path]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{WENT_WRONG}</div>;
  }

  if (!data) {
    return <div>Photo not found.</div>;
  }

  const imageUrl = convertToCloudFareUrl(data.id);

  const articleMetaData = helmetJsonLdProp<CreativeWork>({
    "@context": "https://schema.org",
    "@type": "CreativeWork",
    headline: `${data.user.name}'s Photo`,
    description: `Check out this photo by ${data.user.name}. Explore more at Eddie's Edition.`,
    image: imageUrl,
    genre: "Beauty",
    publisher: {
      "@type": "Organization",
      name: "Eddie's Edition",
      logo: {
        "@type": "ImageObject",
        url: "https://eddiesedition.s3-us-west-2.amazonaws.com/images/logos/logo.png",
      },
    },
    mainEntityOfPage: {
      "@type": "WebPage",
      "@id": `https://www.eddiesedition.com/photos`,
    },
  });

  return (
    <>
      <MetaTagWrapper
        type="image"
        image={imageUrl}
        title={`${data.user.name}'s Photo | Eddie's Edition`}
        description={`Check out this photo by ${data.user.name}. Explore more at Eddie's Edition.`}
        url={`https://www.eddiesedition.com/photos/${path}`}
        scripts={[articleMetaData]}
      />
      <div className="content-wrapper">
        <section className="section has-thin-top-padding">
          <div className="columns">
            <div className="column is-half is-offset-one-quarter is-paddingless">
              <Space direction="vertical" style={{ width: "100%" }}>
                <Link to={`/photos`}>
                  <Button type="link" icon={<LeftOutlined />}>
                    Back to Photos
                  </Button>
                </Link>

                <div style={{ display: "flex" }}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "50px",
                    }}
                  >
                    {data.previousPhotoId && (
                      <Link to={`/photos/${data.previousPhotoId}`}>
                        <Button
                          size="large"
                          type="text"
                          shape={"circle"}
                          icon={<LeftCircleOutlined />}
                        />
                      </Link>
                    )}
                  </div>
                  <div style={{ width: "100%" }}>
                    <Space direction="vertical" style={{ width: "100%" }}>
                      <div className="has-text-centered">
                        <Image
                          src={convertToCloudFareUrl(data.id)}
                          preview={false}
                          style={{ margin: "0 auto" }}
                        />
                      </div>

                      <div className="has-text-right">
                        <Link to={`/stylists/${data.user.pathId}`}>
                          <Space>
                            <Avatar
                              size="small"
                              src={convertToCloudFareUrl(data.user.photoId)}
                            />
                            <Typography.Title
                              level={5}
                              className="is-marginless"
                            >
                              {data.user.name}
                            </Typography.Title>
                          </Space>
                        </Link>
                      </div>
                    </Space>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "50px",
                    }}
                  >
                    {data.nextPhotoId && (
                      <Link to={`/photos/${data.nextPhotoId}`}>
                        <Button
                          size="large"
                          type="text"
                          shape={"circle"}
                          icon={<RightCircleOutlined />}
                        />
                      </Link>
                    )}
                  </div>
                </div>
              </Space>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default PhotoPage;
