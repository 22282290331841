import { Space, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { ILocationTimeSlotsJson } from "../../../../../api/types";
import { EditionJson } from "../../../../../api";
import StylistCard from "../DisplaySchedules/components/StylistCard";
import ModalCard from "../../../../../components/ModalCard";
import RequestForm from "./RequestForm";
import { convertToCloudFareUrl } from "../../../../../api/cloudfare";

interface ISpecialServiceMessageProps {
  locationId: string;
  serviceCategories: number[];
}
const SpecialServiceMessage = ({
  locationId,
  serviceCategories,
}: ISpecialServiceMessageProps) => {
  const [isLoading, setLoading] = useState(true);
  const [stylists, setStylists] = useState<ILocationTimeSlotsJson[]>([]);
  const [stylist, setStylist] = useState<ILocationTimeSlotsJson | null>(null);
  useEffect(() => {
    const load = async () => {
      setLoading(true);
      try {
        const res = await EditionJson.getStylistsByServiceCode(
          locationId,
          serviceCategories
        );
        setStylists(res);
      } catch (e) {
        console.error(e);
      }
      setLoading(false);
    };
    load();
  }, [locationId, serviceCategories]);
  return (
    <>
      <div className="block">
        <Typography.Text>
          Our hair coloring services necessitate a mini consultation in advance.
          Please refer to the list of stylists below and submit the request
          form, or feel free to{" "}
          <a
            href={"mailto:info@eddiesedition.com?subject=For coloring service"}
          >
            email us
          </a>
          .
        </Typography.Text>
      </div>
      {isLoading ? null : (
        <Space direction="vertical" style={{ width: "100%" }}>
          {stylists.map((item, i) => {
            const { profile } = item;
            const { name, pathId, photoId } = profile;
            return (
              <Space key={i} direction="vertical" style={{ width: "100%" }}>
                <StylistCard
                  profileImage={convertToCloudFareUrl(photoId)}
                  stylistId={pathId}
                  name={name}
                />
                <div>
                  <button
                    onClick={() => setStylist(item)}
                    className="button is-small is-info is-light"
                  >
                    Send a request
                  </button>
                </div>
              </Space>
            );
          })}
        </Space>
      )}
      {stylist && (
        <ModalCard
          title="Request"
          onClose={() => {
            setStylist(null);
          }}
        >
          <div>
            <RequestForm
              userId={stylist.userId}
              userName={stylist.profile.name}
              onClose={() => setStylist(null)}
            />
          </div>
        </ModalCard>
      )}
    </>
  );
};

export default SpecialServiceMessage;
