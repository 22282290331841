import React from "react";
import { formatTime } from "../../../helpers";
interface TimeButtonProps {
  onClick: () => void;
  time: string;
  isSelected: boolean;
}
const TimeButton = ({ onClick, isSelected, time }: TimeButtonProps) => {
  return (
    <button
      onClick={onClick}
      className={`button is-small is-fullwidth is-info is-light ${
        isSelected ? "is-active" : ""
      }`}
    >
      {formatTime(time)}
    </button>
  );
};

export default TimeButton;
