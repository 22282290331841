import React from "react";
import Helmet from "react-helmet";
import PageTitle from "../components/PageTitle";
import AppContentWrapper from "../../components/AppContentWrapper";

export interface ContactFormState {
  name: string;
  email: string;
  message: string;
}

export const ContactUs = () => {
  return (
    <>
      <AppContentWrapper>
        <Helmet>
          <title>Contact us | Eddie's Edition</title>
        </Helmet>
        <PageTitle title="Contact us" />
        <p className="subtitle is-6">
          We'd love to hear from you
          <br />
          Send us your comments, questions or feedback, and we will get back to
          you promptly.
        </p>
        <p>
          <a
            className="has-text-dark"
            href={"mailto:info@eddiesedition.com?subject=Question"}
            target="_blank"
            rel="noreferrer"
          >
            info@eddiesedition.com
          </a>
        </p>
      </AppContentWrapper>
    </>
  );
};

export default ContactUs;
