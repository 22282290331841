import React from "react";
import { EditionJson } from "../../api";
import { extractErrorMessage } from "../../helpers";
import { Button, Form, Input, message } from "antd";

interface ICancelAppointmentProps {
  appointmentId: string;
  onCanceled: () => void;
}
const CancelAppointment = ({
  appointmentId,
  onCanceled,
}: ICancelAppointmentProps) => {
  const [confirm, setConfirm] = React.useState(false);
  const [code, setCode] = React.useState<string | undefined>();
  const [isLoading, setLoading] = React.useState(false);

  const onConfirm = async () => {
    try {
      EditionJson.sendVerificationCodeForCancel(appointmentId);
      setConfirm(true);
    } catch (e: any) {
      message.error(extractErrorMessage(e));
    }
  };

  const onCancel = async () => {
    setLoading(true);
    try {
      if (code) {
        await EditionJson.cancelAppointmentWithCode({ appointmentId, code });
        message.success("Your appointment has been canceled");
        onCanceled();
      }
    } catch (e: any) {
      message.error(extractErrorMessage(e));
    }
    setLoading(false);
  };

  return (
    <>
      {confirm ? (
        <Form layout="vertical">
          <Form.Item label="Verification code sent! Please check your mobile device.">
            <Input
              id="code"
              placeholder="Code"
              value={code}
              onChange={(e) => {
                setCode(e.target.value);
              }}
            />
          </Form.Item>

          <Button
            disabled={!code}
            loading={isLoading}
            type="primary"
            danger
            onClick={onCancel}
          >
            Cancel the appointment
          </Button>
        </Form>
      ) : (
        <div>
          <p>
            As we're an appointment-based service, cancellations within 12 hours
            can affect our stylists' schedules. We appreciate your
            understanding. Do you still wish to cancel your appointment?
          </p>
          <br />
          <Button type="primary" onClick={onConfirm}>
            Yes, Cancel the appointment
          </Button>
        </div>
      )}
    </>
  );
};

export default CancelAppointment;
