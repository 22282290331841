import React from "react";
import Helmet from "react-helmet";
import { Link } from "react-router-dom";

import PageTitle from "../components/PageTitle";
import AppContentWrapper from "../../components/AppContentWrapper";
import {s3Path} from "../../consts";

const Promotions = () => {
  return (
    <>
      <AppContentWrapper>
        <Helmet>
          <title>Events | Eddie's Edition</title>
        </Helmet>
        <PageTitle title="Events" />
        <div>
          <div className="columns">
            <div className="column is-narrow" />
            <div className="column">
              <div className="content">
                <p>
                  <strong>Share your experience</strong>
                  <br />
                  Thanks for your visiting. Please share your experience on our
                  Google page or Yelp page and get a free styling product in
                  your next time visit.
                  <br />
                  {/*<span className="has-text-weight-light is-size-7">*/}
                  {/*  <span className="has-text-info">San Gabriel only</span>{" "}*/}
                  {/*  &middot; You cannot use with other offers &middot; Ends*/}
                  {/*  2020.02.29*/}
                  {/*</span>*/}
                </p>
                <Link
                  to={"/appointment/san-gabriel"}
                  className="button is-small is-dark"
                >
                  Go to make an appointment
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className="columns">
            <div className="column is-narrow">
              <div className="image is-128x128">
                <img
                  alt="Eddie's edition san gabriel grand opening"
                  src={`${
                    s3Path
                  }/images/events/grand-opening-san-gabriel.jpg`}
                />
              </div>
            </div>
            <div className="column">
              <div className="content">
                <p>
                  <strong>Grand opening &middot; San Gabriel</strong>
                  <br />
                  Say Hi to our new location and staffs. We offer $10 discount
                  for your haircut in San Gabriel location.
                  <br />
                  <span className="has-text-weight-light is-size-7">
                    <span className="has-text-info">San Gabriel only</span>{" "}
                    &middot; You cannot use with other offers &middot; Ends
                    2020.02.29
                  </span>
                </p>
                <Link
                  to={"/appointment/san-gabriel"}
                  className="button is-small is-dark"
                >
                  Go to make an appointment
                </Link>
              </div>
            </div>
          </div>
        </div>
      </AppContentWrapper>
    </>
  );
};

export default Promotions;
