import { Button, Checkbox, Divider, List, Modal, Typography } from "antd";
import React from "react";
import { IServiceOption } from "../../../../../models";
import { EXTRA_OPTIONS } from "../../../../../api/consts";
interface IExtraOptionsProps {
  options?: IServiceOption[];
  serviceCategory: number;
  onSubmit: (op: { serviceCategory: number } & IServiceOption) => void;
  onClose: () => void;
}
const regularOption = {
  id: "",
  title: "Regular",
  price: 0,
  duration: 0,
  description: "Regular",
};
const ExtraOptions = ({
  serviceCategory,
  options,
  onSubmit,
  onClose,
}: IExtraOptionsProps) => {
  const [selectedOption, setSelectedOption] = React.useState(regularOption);

  const onSelect = (op: IServiceOption & { description: string }) => {
    setSelectedOption(op);
  };

  const handleSubmit = () => {
    onSubmit({
      ...selectedOption,
      serviceCategory,
    });
    onClose();
  };

  const enhancedOptions = EXTRA_OPTIONS[serviceCategory]
    ? EXTRA_OPTIONS[serviceCategory].slice() // Use slice to create a copy if it's not meant to modify the original
    : [];

  enhancedOptions.unshift(regularOption);

  return (
    <Modal
      open={true}
      onCancel={onClose}
      footer={[
        <Button
          type="primary"
          key="submit"
          onClick={handleSubmit}
          disabled={!selectedOption?.id}
        >
          Find Openings
        </Button>,
        <Button key="close" onClick={onClose}>
          Close
        </Button>,
      ]}
    >
      <Typography.Title level={5}>Select an option</Typography.Title>
      <List
        itemLayout="horizontal"
        dataSource={enhancedOptions}
        renderItem={(item) => {
          const option = options?.find((option) => option.id === item.id);
          return (
            <List.Item>
              <List.Item.Meta
                avatar={
                  <Checkbox
                    disabled={item.id !== "" && !option}
                    checked={
                      selectedOption && selectedOption.id === item.id
                        ? true
                        : false
                    }
                    onClick={() => onSelect(item)}
                  />
                }
                title={option ? `${item.title} - $${option.price}` : item.title}
                // description={option ? item.description : "Not available"}
                description={item.description}
              />
            </List.Item>
          );
        }}
      />
      <Divider />
    </Modal>
  );
};

export default ExtraOptions;
