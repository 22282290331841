import React, { useState } from "react";
import MessageStylist from "./MessageStylist";
import CancelAppointment from "./CancelAppointment";
import { Modal, Typography } from "antd";

interface IManageAppointmentButtonsProps {
  appointmentId: string;
  stylistName: string;
  stylistPhone: string;
  onRefreshed: () => void;
}
const ManageAppointmentButtons = ({
  appointmentId,
  stylistName,
  stylistPhone,
  onRefreshed,
}: IManageAppointmentButtonsProps) => {
  const [modalRoute, setModalRoute] = useState<
    "cancel" | "reschedule" | "contact" | "message-stylist" | undefined
  >();
  return (
    <div>
      <div className="buttons">
        <button
          onClick={() => setModalRoute("reschedule")}
          className="button is-small is-warning is-light"
        >
          Reschedule
        </button>
        <button
          onClick={() => setModalRoute("cancel")}
          className="button is-small is-danger is-light"
        >
          Cancel
        </button>
        <button
          onClick={() => setModalRoute("message-stylist")}
          className="button is-small is-info is-light"
        >
          Message to Stylist
        </button>
        <a href={`tel:${stylistPhone}`} className="button is-small is-light">
          Call Stylist
        </a>
      </div>

      {modalRoute !== undefined && (
        <Modal
          open={true}
          onCancel={() => setModalRoute(undefined)}
          footer={null}
        >
          <>
            {modalRoute === "cancel" && (
              <CancelAppointment
                appointmentId={appointmentId as string}
                onCanceled={() => {
                  onRefreshed();
                  setModalRoute(undefined);
                }}
              />
            )}
            {modalRoute === "reschedule" && (
              <>
                <Typography.Paragraph>
                  To reschedule your appointment, please visit our website to
                  find a new time slot and book a new appointment. Our system
                  will guide you through canceling your previous appointment. As
                  an appointment-based service, last-minute changes can disrupt
                  our stylists' schedules, so we kindly ask that you avoid
                  rescheduling at the last minute whenever possible. Thank you
                  for your understanding and cooperation.
                </Typography.Paragraph>
                {/* <Button>Close</Button> */}
              </>
            )}
            {modalRoute === "message-stylist" && (
              <MessageStylist
                stylistName={stylistName}
                appointmentId={appointmentId}
                onClose={() => setModalRoute(undefined)}
              />
            )}
          </>
        </Modal>
      )}
    </div>
  );
};

export default ManageAppointmentButtons;
