import React from "react";
import Privacy from "./components/privacy";
import Cookies from "./components/cookies";
import Term from "./components/terms";
import Helmet from "react-helmet";
import AppContentWrapper from "../../components/AppContentWrapper";

const routes = ["privacy", "terms", "cookies"];
const Terms = () => {
  const [route, setRoute] = React.useState("privacy");
  return (
    <>
	    <Helmet>
		    <title>Terms & Privacy | Eddie's Edition</title>
		    <meta name="robots" content="noindex" />
	    </Helmet>
      <AppContentWrapper>

        <div className="tabs">
          <ul>
            {routes.map(item => (
              <li className={route === item ? "is-active" : ""}>
                <a className="is-capitalized" onClick={() => setRoute(item)}>
                  {item}
                </a>
              </li>
            ))}
          </ul>
        </div>
        <div className="content">
          {route === "privacy" ? <Privacy /> : false}
          {route === "terms" ? <Term /> : false}
          {route === "cookies" ? <Cookies /> : false}
        </div>
      </AppContentWrapper>
    </>
  );
};

export default Terms;
