import React from "react";
import PageTitle from "../../scenes/components/PageTitle";
import AppContentWrapper from "../AppContentWrapper";
import { s3Path } from "../../consts";

const PageLoadingError = ({ message }: { message?: string }) => {
  return (
    <AppContentWrapper>
      <PageTitle title="Something went wrong" />
      <figure className="image is-5b4">
        <img
          alt="Eddie's edition 404 page"
          src={`${s3Path}/images/notfound.jpg`}
        />
      </figure>
      {message ? <p>{message}</p> : false}
    </AppContentWrapper>
  );
};

export default PageLoadingError;
