import { Typography } from "antd";
import React from "react";
import ModalCard from "../../../../../components/ModalCard";
import RequestForm from "../../../../Salons/SalonView/components/SpecialServiceMessage/RequestForm";
import { ApppointmentServiceType } from "../../../../../hooks/useAppointment";

const SpecialServiceMessage = ({
  stylist,
}: {
  stylist: any;
  selectedServices: ApppointmentServiceType[];
}) => {
  const [isRequestFormActive, setRequestFormActive] = React.useState(false);
  return (
    <>
      <Typography.Paragraph>
        Our hair coloring services necessitate a mini consultation in advance.
        Please refer to the list of stylists below and submit the request form.
      </Typography.Paragraph>
      <div>
        <button
          onClick={() => setRequestFormActive(true)}
          className="button is-small is-info is-light"
        >
          Send a request
        </button>
      </div>

      {isRequestFormActive && (
        <ModalCard
          title=""
          onClose={() => {
            setRequestFormActive(false);
          }}
        >
          <RequestForm
            userName={stylist.profile.name}
            userId={stylist._id}
            onClose={() => setRequestFormActive(false)}
          />
        </ModalCard>
      )}
    </>
  );
};

export default SpecialServiceMessage;
