import React from "react";
import Helmet from "react-helmet";
import { s3Path } from "../../consts";

import PageTitle from "../../scenes/components/PageTitle";
import AppContentWrapper from "../AppContentWrapper";

const NotFound = () => {
  return (
    <>
      <Helmet>
        <meta name="prerender-status-code" content="404" />
      </Helmet>
      <AppContentWrapper>
        <PageTitle title="Page not found" />
        <figure className="image is-5b4">
          <img
            alt="Eddie's edition 404 page"
            src={`${s3Path}/images/notfound.jpg`}
          />
        </figure>
      </AppContentWrapper>
    </>
  );
};

export default NotFound;
