import React from "react";
import Helmet from "react-helmet";
import PageTitle from "../components/PageTitle";
import AppContentWrapper from "../../components/AppContentWrapper";

const HairModel = () => {
  return (
    <>
      <AppContentWrapper>
        <Helmet>
          <title>Hair Model Wanted | Eddie's Edition</title>
          <meta
            name="description"
            content="We are seeking hair models for our haircut training sessions. If you are interested in getting a free haircut, please fill out the form below. We will contact you when we have a training session that matches your hair type and style."
          />
        </Helmet>

        <PageTitle title="Hair Model Wanted" />
        <p className="subtitle is-6">
          We are seeking hair models for our haircut training sessions. Each
          session lasts between 45 minutes and 1 hour. If you are interested in
          getting a free haircut, please fill out the google form below. We will
          contact you when we have a training session that matches your hair
          type and style.
        </p>
        <a
          className="button is-black"
          href={
            "https://docs.google.com/forms/d/1MdASmeSnpzRg9aKG9d0h0BU4Ut14VIBMK_yxU8TQADM"
          }
          target="_blank"
          rel="noreferrer"
        >
          Apply
        </a>
      </AppContentWrapper>
    </>
  );
};

export default HairModel;
