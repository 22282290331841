import React from "react";

interface ITroubleShootingForCodeProps {
  onClose: () => void;
}
const TroubleShootingForCode = ({ onClose }: ITroubleShootingForCodeProps) => {
  return (
    <div className="content">
      <p>
        If you're not receiving verification codes from us on your provided
        phone number, it's possible you may have opted out from our services.
        Please text 'START' to 626-888-8052 and attempt the verification process
        again.
      </p>

      <button className="button is-light" onClick={onClose}>
        Go back
      </button>
    </div>
  );
};

export default TroubleShootingForCode;
