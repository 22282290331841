import React, { useEffect, useState } from "react";
import Title from "antd/es/typography/Title";
import { List } from "antd";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";
import { EditionJson } from "../../api";
import { IUpcomingAppointmentsJson } from "../../api/types";

import AppContentWrapper from "../../components/AppContentWrapper";
import ManageAppointmentButtons from "./ManageAppointmentButtons";
import { formatDatetime } from "../../helpers";

const ManageAppointments = () => {
  const { phoneNumber } = useParams<{ phoneNumber: string }>();
  const [data, setData] = useState<IUpcomingAppointmentsJson[]>();
  const [isLoading, setLoading] = useState(true);
  const [refreshed, setRefreshed] = useState(false);
  useEffect(() => {
    const load = async (_phoneNumber: string) => {
      setLoading(true);
      try {
        const _res = await EditionJson.getUpcomingAppointments(_phoneNumber);
        setData(_res);
      } catch (error) {
        console.error(error);
      }
      setLoading(false);
    };
    if (phoneNumber) {
      load(phoneNumber);
    }
  }, [phoneNumber, refreshed]);

  return (
    <AppContentWrapper>
      <Helmet>
        <meta name="prerender-status-code" content="404" />
        <meta name="robots" content="noindex" />
      </Helmet>
      <Title level={4}>Manage Appointments</Title>
      <List
        bordered
        loading={isLoading}
        dataSource={data}
        renderItem={(item) => (
          <List.Item>
            <List.Item.Meta
              title={
                <>
                  <strong>
                    {`${item.serviceName} with ${item.stylistName} at ${item.locationName}`}
                  </strong>
                  <br />
                  <p>{formatDatetime(item.date, item.time)}</p>
                  <br />
                  <>
                    <ManageAppointmentButtons
                      appointmentId={item._id}
                      stylistName={item.serviceName}
                      stylistPhone={item.stylistPhone}
                      onRefreshed={() => setRefreshed(true)}
                    />
                  </>
                </>
              }
            />
          </List.Item>
        )}
      />
    </AppContentWrapper>
  );
};

export default ManageAppointments;
