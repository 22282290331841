import { Button, Typography } from "antd";
import React from "react";
import { useMatch } from "react-router-dom";
import { getLocationIdByPathId } from "../../api/helpers";
import Openings from "./Openings";
import { Helmet } from "react-helmet";
import NotFound from "../../components/NotFound";

const Receptions = () => {
  const match = useMatch("/receptions/:locationPathId");
  const [showOpenings, setShowOpenings] = React.useState(false);
  const locationId = getLocationIdByPathId(
    match?.params.locationPathId as string
  );
  if (!locationId) {
    return <NotFound />;
  }

  return (
    <div
      className="has-background-white section"
      style={{
        paddingTop: "1.5rem",
        position: "fixed",
        height: "100vh",
        width: "100vw",
        zIndex: 999,
        overflowY: "scroll",
      }}
    >
      <Helmet>
        <meta name="prerender-status-code" content="404" />
        <meta name="robots" content="noindex" />
      </Helmet>
      {showOpenings ? (
        <Openings
          locationId={locationId}
          onReset={() => setShowOpenings(false)}
        />
      ) : (
        <>
          <Typography.Title>Welcome to Edition!</Typography.Title>
          <Typography.Paragraph className="is-size-4">
            We're delighted to see you. To best serve you, we work by
            appointments. If you haven't booked yet, feel free to explore our
            available time slots. If you've already secured a spot, kindly take
            a seat and make yourself comfortable. We'll be with you in a moment.
          </Typography.Paragraph>
          <Button
            type="primary"
            size="large"
            onClick={() => setShowOpenings(true)}
          >
            Make an appointment
          </Button>
        </>
      )}
    </div>
  );
};

export default Receptions;
