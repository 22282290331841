import React, { useState } from "react";
import { Link } from "react-router-dom";
import { MetaTagWrapper } from "../../../../components/MetaTags";
import { products } from "../../../../consts/products";
import { Space } from "antd";
import Title from "antd/es/typography/Title";
import Paragraph from "antd/es/typography/Paragraph";

export const Products = () => {
  return (
    <>
      <MetaTagWrapper
        title="Shop | Eddie's Edition"
        description="Explore Eddie's Edition Shop, where each item is handpicked for quality and style. Dive into our exclusive collection and find the perfect addition to your grooming essentials."
        type="website"
        url="https://www.eddiesedition.com/shop"
        canonical={true}
      />
      <Title level={3}>Products</Title>
      <Paragraph>
        Hair products that are handpicked for quality and style by Eddie's
        Edition.
      </Paragraph>
      <br />
      <div className="columns is-multiline is-mobile">
        {products.map((product: any, i: any) => {
          return (
            <div
              className="column has-text-centered is-6-mobile is-3-tablet"
              key={product.id}
            >
              <Space direction="vertical">
                <Link target="_blank" to={`${product.link}`}>
                  <figure className="image 128x128">
                    <img
                      src={product.image}
                      alt={`${product.title} product shot`}
                    />
                  </figure>
                </Link>
                <Link
                  className="has-text-dark"
                  target="_blank"
                  to={`${product.link}`}
                >
                  <span className="has-text-weight-semibold">
                    {product.title}
                  </span>
                  <br />${product.price}
                </Link>
              </Space>
            </div>
          );
        })}
      </div>
    </>
  );
};

export default Products;
